import { get, post, patch } from '../../services/';
import routes from './routes';

const OrderServices = {
  addLibrary: async (obj) => {
    const data = await post(routes.addLibrary, obj);
    return data;
  },
  completeOrder: async (obj) => {
    const data = await post(routes.completeOrder, obj);
    return data;
  },
  removeBg: async (obj) => {
    const data = await post(routes.removeBg, obj);
    return data;
  },
  removeBgV2: async (obj) => {
    const data = await post(routes.removeBgV2, obj);
    return data;
  },
  placeOrder: async (obj) => {
    const data = await post(routes.placeOrder, obj);
    return data;
  },
  userProfile: async (params) => {
    const data = await get(routes.userProfile, params);
    return data;
  },
  Collections: async (params) => {
    const data = await get(routes.Collections, params);
    return data;
  },
  getPricing: async (params) => {
    const data = await get(routes.getPricing, params);
    return data;
  },
  getOrderList: async (params) => {
    const data = await get(routes.getOrderList, params);
    return data;
  },
  getLibrary: async (params) => {
    const data = await get(routes.getLibrary, params);
    return data;
  },
}

export default OrderServices