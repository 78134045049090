import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Box, Chip, Divider, Grid } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Moveable from 'react-moveable';
import parse from 'html-react-parser';
import { TextField, Slider, Select, FormControl, InputLabel } from '@mui/material';
import Logo from '../../assests/images/Logo.png'
import Colors from '../../assests/images/colors.png'
import { BlockPicker, TwitterPicker } from 'react-color';
import FontPicker from 'font-picker-react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import { Padding } from '@mui/icons-material';
import { SecondaryButton } from '../Buttons';
import EastIcon from '@mui/icons-material/East';
import { useForm } from 'react-hook-form';
import { Images } from '../../assests';
import DeleteIcon from '@mui/icons-material/Delete';
import html2canvas from 'html2canvas';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { PDFExport } from '@progress/kendo-react-pdf';







function TshirtDesignCanvas(imageUrl) {


    const parentRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [selectedTshirt, setSelectedTshirt] = useState('white')
    const [selectedSize, setSelectedSize] = useState('S')
    const [quantity, setQuantity] = useState(1)
    const [fontWeight, setFontWeight] = useState(400)
    const [textArray, setTextArray] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState()
    const [imageSeleted, setImageSeleted] = useState(false)
    const [color, setColor] = useState('#000')
    const [zIndexes, setZIndexes] = useState(textArray.map(() => 0)); // Initialize zIndexes
    const [highestZIndex, setHighestZIndex] = useState(1);

    const contentRef = useRef(null);
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
        reset
    } = useForm();


    const toggleDrawer = (open) => (event) => {
        setOpen(open);
    };

    const handleClick = (item) => {
        setSelectedSize(item)

    };

    const [blockPickerColor, setBlockPickerColor] = useState("#37d67a");

    const [fontSize, setFontSize] = useState(18); // Initial font size
    const [fontFamily, setFontFamily] = useState(); // Initial font family
    const [selectedColor, setSelectedColor] = useState('')

    const [showButton, setShowButton] = useState(window.innerWidth < 1320);


    useEffect(() => {
        const handleResize = () => {
            setShowButton(window.innerWidth < 1320);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleIncreament = () => {
        setQuantity(quantity + 1)

    }
    const handleWeightChange = (event, newValue) => {
        setFontWeight(newValue)
        let element = document.getElementById(`text${selectedIndex}`)
        if (element) {

            element.style.fontWeight = `${newValue}`

        }

    }

    const handleDecreament = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1)
        }
    }
    const handleTextColor = (color) => {
        let element = document.getElementById(`text${selectedIndex}`)
        if (element) {

            element.style.color = color
        }
    }
    const handleDelete = (index) => {
        console.log('delete');
        let element = document.getElementById(index)
        element.remove()
    }
    const handleFontSizeChange = (event, newValue) => {
        setFontSize(newValue)
        let element = document.getElementById(`text${selectedIndex}`)
        if (element) {

            element.style.fontSize = `${newValue}px`
        }

    };

    const handleFontFamilyChange = (value) => {

        let element = document.getElementById(`text${selectedIndex}`)
        if (element) {

            element.style.fontFamily = value
        }

    };


    const colors = [{ name: "white", color: '#fff' }, { name: "yellow", color: '#EFBD4E' }, { name: "lightgreen", color: '#80C670' }, { name: "purple", color: '#726DE8' }, { name: "darkgreen", color: '#272e26' }, { name: "blue", color: '#194b86' }, { name: "black", color: '#000' }]


    const [selectedElement, setSelectedElement] = useState(null);

    useEffect(() => {
        function handleClickOutside(event) {

            if (parentRef.current && !parentRef.current.contains(event.target)) {
                setSelectedElement(null);

            }
            else {
                setSelectedElement('True');
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleInputChange = (event) => {
        if (event.target.value) {
            let newArray = [...textArray];
            newArray[selectedIndex] = event.target.value;

            // Update the state
            setTextArray(newArray);
            // let element = document.getElementById(`text${selectedIndex}`)
            // if (element) {

            //     let width
            //     let height
            //     width = element.offsetWidth
            //     height = element.offsetHeight
            //     console.log(element.offsetWidth, 'widthwidth');
            //     let Parentelement = document.getElementById(selectedIndex)
            //     Parentelement.style.width = (parseFloat(width) * 1.6) + 'px';
            //     Parentelement.style.height = (parseFloat(height) * 1.2) + 'px'
            // }
        }
    };

    const handleExportWithComponent = (pdfExportComponent) => {
        pdfExportComponent.current.save();
    };
    const Addtext = () => {
        const tshirtText = getValues('tshirtText');
        setValue(tshirtText)


        // Use the setter function to update the state
        setTextArray([...textArray, tshirtText]);
        setValue('tshirtText', '')


    };
    const handleElementClick = (e, index, action) => {
        console.log(action);
        if (action == 'delete') {
            document.getElementById(index).remove()
        }
        console.log('asdasdasdasdda');
        setSelectedIndex(index)
        let element = document.getElementById(`text${index}`)
        console.log(element);
        if (element) {

            var computedStyle = window.getComputedStyle(element);

            // Get the font-weight property
            var fontWeight = computedStyle.fontWeight;
            var textSize = computedStyle.fontSize;
            var color = computedStyle.color


            // Log the font-weight to the console
            console.log(fontWeight);
            console.log(color);
            console.log(textSize.replace("px", ""));
            setFontWeight(fontWeight)
            setBlockPickerColor(color)
            setFontSize(textSize.replace("px", ""))
        }

        if (parentRef.current.contains(e.target)) {

            setSelectedElement(e.target);
        } else {

            setSelectedElement(null);
            setImageSeleted(false)

        }
    }
    const handleContextMenuOption = (index, option) => {
        const newZIndexes = [...zIndexes];
        if (option === 'Forward') {
            newZIndexes[index] = highestZIndex + 1;
            setHighestZIndex(highestZIndex + 1);
        } else if (option === 'Backward') {
            newZIndexes[index] = Math.max(0, newZIndexes[index] - highestZIndex);
        }
        setZIndexes(newZIndexes);
    };
    const handleCaptureClick = async () => {
        if (parentRef.current) {
            const canvas = await html2canvas(parentRef.current, {
                scale: 10, // Increase scale for higher quality
                useCORS: true // Use CORS to handle cross-origin images
            });
            const dataUrl = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = 'capture.png';
            link.click();
        }
    };


    return (
        <>

            <Grid sx={{ backgroundColor: '#f2f2f2' }}>

                <Drawer
                    anchor="right"
                    open={open}
                    sx={{borderRadius:'20px'}}
                    onClose={toggleDrawer(false)}
                >
                    <Box sx={{ padding: '20px',borderRadius:'20px' }} component={'form'} onSubmit={handleSubmit(Addtext)}>
                        <Grid item xs={3.5} display={'flex'} justifyContent={'center'} alignItems={"center"} >
                            <Grid >
                                <div>
                                    <InputLabel sx={{ color: 'black', fontWeight: '500', textAlign: 'left', fontFamily: 'Poppins, sans-serif' }}>Add Text</InputLabel>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        size='small'

                                        {...register('tshirtText', { required: true })}
                                        onChange={handleInputChange}
                                        style={{ fontSize: `${fontSize}px`, color: "black", fontFamily: 'Poppins, sans-serif' }}
                                    />

                                    <div style={{ marginTop: '20px' }}>


                                        <InputLabel sx={{ color: 'black', fontWeight: '500', textAlign: 'left', fontFamily: 'Poppins, sans-serif' }}>Font Family</InputLabel>
                                        <FontPicker

                                            apiKey="AIzaSyDPtqHOkdnMVZb6wrHcyz1Mwyn0Au-HCbI"
                                            activeFontFamily={fontFamily}
                                            onChange={(nextFont) => handleFontFamilyChange(nextFont.family)}
                                        />

                                    </div>
                                    <InputLabel sx={{ color: 'black', fontWeight: '500', textAlign: 'left', fontFamily: 'Poppins, sans-serif', mt: 3.5 }}>Font Weight</InputLabel>
                                    <Slider
                                        aria-label="Temperature"

                                        value={fontWeight}
                                        onChange={handleWeightChange}
                                        color='black'
                                        valueLabelDisplay="auto"
                                        shiftStep={30}
                                        mt={'2%'}
                                        step={100}
                                        marks
                                        min={100}
                                        max={900}
                                    />
                                    {/* <FormControl variant="standard" sx={{ mt: 0.2, minWidth: "100%" }}>

                                        <Select
                                            fullWidth
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            sx={{ textAlign: 'left' }}
                                            value={fontWeight}
                                            onChange={handleWeightChange}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="100">100 (Thin)</MenuItem>
                                            <MenuItem value="200">200 (Extra Light)</MenuItem>
                                            <MenuItem value="300">300 (Light)</MenuItem>
                                            <MenuItem value="400">400 (Normal)</MenuItem>
                                            <MenuItem value="500">500 (Medium)</MenuItem>
                                            <MenuItem value="600">600 (Semi Bold)</MenuItem>
                                            <MenuItem value="700">700 (Bold)</MenuItem>
                                            <MenuItem value="800">800 (Extra Bold)</MenuItem>
                                            <MenuItem value="900">900 (Black)</MenuItem>
                                        </Select>
                                    </FormControl> */}
                                    <InputLabel id="font-size-label" style={{ color: 'black', fontWeight: '500', textAlign: 'left', marginTop: '5%', fontFamily: 'Poppins, sans-serif' }}>Font Size</InputLabel>
                                    <Grid container justifyContent={"center"} xs={12} mt={'2%'}>


                                        <Slider value={parseInt(fontSize)} defaultValue={fontSize} valueLabelDisplay="on" onChange={handleFontSizeChange} color='black' sx={{ fontFamily: 'Poppins, sans-serif' }} aria-label="Disabled slider" />
                                    </Grid>
                                    <InputLabel id="font-size-label" style={{ color: 'black', fontWeight: '500', textAlign: 'left', fontFamily: 'Poppins, sans-serif' }}>Text Color</InputLabel>
                                    <div className="blockpicker" style={{ textAlign: 'left', marginTop: '5%' }}>

                                        {/* Div to display the color  */}

                                        {/* Block Picker from react-color and handling color on onChange event */}
                                        <TwitterPicker
                                            color={blockPickerColor}
                                            onChange={(color) => {
                                                handleTextColor(color.hex)

                                                setBlockPickerColor(color.hex);
                                            }}
                                        />
                                    </div>
                                </div>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', margin: '0 auto', mt: '5%' }} >
                                    <Button type='submit' fullWidth sx={{
                                        backgroundColor: 'black', fontWeight: 'bold', color: 'white', padding: '10px 25px 10px 25px', '&:hover': {
                                            backgroundColor: 'white',
                                            color: 'black',
                                            border: '2px solid black'
                                        },
                                    }} >
                                        Add
                                    </Button>
                                </Box>
                            </Grid>

                        </Grid> </Box> : <Box onClick={toggleDrawer(true)} sx={{ position: 'fixed', top: '50%', right: 0, width: '40px', height: '100px', boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', backgroundColor: 'white' }}>

                        <Box sx={{ height: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                            <Box component={'img'} width={'40px'} src={Colors}>

                            </Box>
                            <Box>
                                <FormatColorTextIcon sx={{ fontSize: '35px' }} />
                            </Box>

                        </Box>
                    </Box>
                </Drawer>

                {/* <Grid sx={{ backgroundColor: '#f2f2f2', display: 'flex', justifyContent: 'flex-start', height: '120px' }}>
                    <Box component={'img'}

                        width={'200px'}

                        src={Logo}
                    >

                    </Box>
                </Grid> */}
                <Grid container justifyContent={'center'} alignItems={'center'}>
                    {!showButton && <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 5 }} pt={"2%"} >
                        <><Box display={"flex"} justifyContent={"center"} alignItems={'center'} gap={2} style={{ fontSize: '18px', fontFamily: 'Poppins, sans-serif', textAlign: 'left', Padding: '50px' }}>
                            <InputLabel sx={{ color: 'black', fontWeight: '500', textAlign: 'left', fontFamily: 'Poppins, sans-serif' }}>Sizes :</InputLabel>  {["S", "M", "L", "XL"].map((Size) => (
                                <Chip label={Size} sx={{ border: selectedSize == Size ? '2px solid black' : '', width: '43px', height: '43px', borderRadius: '50%', fontSize: '11px', fontWeight: 'bold', fontFamily: 'Poppins, sans-serif', transform: selectedSize == Size ? 'scale(1.1)' : '' }} onClick={() => handleClick(Size)} />
                            ))} </Box>
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                    <InputLabel sx={{ color: 'black', fontWeight: '500', textAlign: 'left', fontFamily: 'Poppins, sans-serif' }}>Quantity : &nbsp;</InputLabel>
                                    <Button variant="contained" onClick={() => handleDecreament()} sx={{ backgroundColor: 'black', fontWeight: 'bold', color: 'white' }} >
                                        -
                                    </Button>
                                    <input style={{ width: '50px', height: '30px', textAlign: 'center' }} type={"number"} disabled maxLength={5} minLength={0} value={quantity} className='inputcount' readOnly />
                                    <Button variant="contained" onClick={() => handleIncreament()} sx={{ backgroundColor: 'black', fontWeight: 'bold', color: 'white' }} >
                                        +
                                    </Button>
                                    <br />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%' }} >
                                <Button fullWidth sx={{
                                    backgroundColor: 'black', fontWeight: 'bold', color: 'white', padding: '10px 25px 10px 25px', '&:hover': {
                                        backgroundColor: 'white',
                                        color: 'black',
                                        border: '2px solid black'
                                    },
                                }} >
                                    Place Order
                                </Button>
                            </Box>
                        </>
                    </Grid>}
                    <Grid item className='edges-parent' xs={12} md={6} display={'flex'} justifyContent={'center'} pt={"12%"} height={'100vh'} sx={{ position: 'relative' }} >
                        <div className='edges' style={{ position: 'relative', width: '460px', height: '300px', backgroundColor: "#f5480a", display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px', }}>
                            <div style={{ position: 'relative', width: '600px' }}>
                                <img className='tshirt-img' width={'500px'} height={'570px'} id="tshirt-backgroundpicture" src={Images[selectedTshirt]} />
                                <div className='style-box' style={{ position: 'absolute', top: 90, left: 145 }}>
                                    <PDFExport ref={contentRef} paperSize="A4" scale={2.7} fileName="PrintContent">
                                        <div>
                                            <div className='Parent pdf-data' ref={parentRef} style={{ border: selectedElement ? '1px dotted black' : 'none', width: '220px', height: '313px', position: 'relative', overflow: 'hidden' }}>
                                                {textArray.map((name, index) => (
                                                    <React.Fragment key={index}>
                                                        <ContextMenuTrigger id={`context-menu-${index}`}>
                                                            <div onClick={() => { setSelectedIndex(index); setImageSeleted(false); setValue('tshirtText', textArray[index]); }}
                                                                onTouchStart={() => { setSelectedIndex(index); setImageSeleted(false); setValue('tshirtText', textArray[index]); }}
                                                                id={index} style={{ border: index === selectedIndex && selectedElement ? '1px dotted black' : 'none', position: 'absolute', width: '100px', zIndex: zIndexes[index] }}>
                                                                {index === selectedIndex && selectedElement && (
                                                                    <div onClick={(e) => { e.stopPropagation(); handleDelete(index); }}
                                                                        onTouchStart={(e) => { e.stopPropagation(); handleDelete(index); }}
                                                                        style={{ position: 'absolute', top: '-12px', left: '-10px', zIndex: '9999' }}>
                                                                        <DeleteIcon sx={{ color: '#C70000', cursor: 'pointer' }} />
                                                                    </div>
                                                                )}
                                                                <span className='full-size-span' style={{ fontSize: '18px', overflow: 'hidden', fontWeight: '400' }} id={`text${index}`}>{name}</span>
                                                            </div>
                                                        </ContextMenuTrigger>
                                                        <Moveable onClick={(e) => handleElementClick(e, index)}
                                                            onTouchStart={(e) => handleElementClick(e, index)}
                                                            target={document.getElementById(index)}
                                                            container={null}
                                                            draggable={index === selectedIndex && selectedElement}
                                                            resizable={index === selectedIndex && selectedElement}
                                                            scalable={index === selectedIndex && selectedElement}
                                                            rotatable={index === selectedIndex && selectedElement}
                                                            pinchable={true} keepRatio={true} origin={false} snappable={true}
                                                            edge={true} throttleDrag={0} throttleResize={0} throttleScale={0}
                                                            hideDefaultLines={true} throttleRotate={0} renderDirections={['se']}
                                                            onDrag={({ target, left, top }) => { target.style.left = `${left}px`; target.style.top = `${top}px`; }}
                                                            onResize={({ target, width, height }) => {
                                                                let parent = document.getElementById(selectedIndex);
                                                                let oldWidth = parseFloat(parent.style.width);
                                                                target.style.width = `${width}px`;
                                                                target.style.height = `${height}px`;
                                                                let differenceWidth = width - oldWidth;
                                                                let item = document.getElementById("text" + selectedIndex);
                                                                let fontSize = window.getComputedStyle(item).fontSize;
                                                                item.style.fontSize = parseFloat(fontSize) + (differenceWidth * 0.3) + "px";
                                                            }}
                                                            onScale={({ target, transform }) => { target.style.transform = transform; }}
                                                            onRotate={({ target, transform }) => { target.style.transform = transform; }}
                                                            onPinch={({ target, transform }) => { target.style.transform = transform; }}
                                                        />
                                                        <ContextMenu id={`context-menu-${index}`} style={{zIndex:'9999',backgroundColor:'white',borderRadius:'10px',padding:"10px",fontFamily: 'Poppins, sans-serif',fontSize:'12px' }}>
                                                            <Box sx={{cursor:'pointer'}}>
                                                            <MenuItem  onClick={(e, data, target) => handleContextMenuOption(index, 'Forward')}>Move Forward</MenuItem>
                                                            </Box>
                                                            <Divider sx={{backgroundColor:'gray'}}/>
                                                            <Box sx={{cursor:'pointer'}}>
                                                            <MenuItem onClick={(e, data, target) => handleContextMenuOption(index, 'Backward')}>Move Backward</MenuItem>
                                                            </Box>
                                                        </ContextMenu>
                                                    </React.Fragment>
                                                ))}
                                                <div onClick={() => setImageSeleted(true)}>
                                                    <Grid component={'div'} onClick={() => setImageSeleted(true)} className='Design'
                                                        style={{
                                                            width: '100px', height: '100px', position: 'absolute', backgroundImage: `url(${imageUrl.imageUrl})`,
                                                            backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', top: "20px", borderRadius: '20px'
                                                        }}
                                                    />
                                                </div>
                                                <Moveable onClick={() => { setImageSeleted(true); }} target={document.getElementsByClassName('Design')}
                                                    container={null} draggable={imageSeleted} resizable={imageSeleted} scalable={imageSeleted}
                                                    rotatable={imageSeleted} pinchable={true} keepRatio={true} origin={false}
                                                    snappable={true} edge={false} renderDirections={['se']} throttleDrag={0}
                                                    throttleResize={0} throttleScale={0} hideDefaultLines={true} throttleRotate={0}
                                                    onDrag={({ target, left, top }) => { target.style.left = `${left}px`; target.style.top = `${top}px`; }}
                                                    onResize={({ target, width, height }) => { target.style.width = `${width}px`; target.style.height = `${height}px`; }}
                                                    onScale={({ target, transform }) => { target.style.transform = transform; }}
                                                    onRotate={({ target, transform }) => { target.style.transform = transform; }}
                                                    onPinch={({ target, transform }) => { target.style.transform = transform; }}
                                                />
                                            </div>
                                        </div>
                                    </PDFExport>
                                </div>
                            </div>
                        </div>
                        <Grid sx={{ position: 'absolute', bottom: 0 }} className='color-options-div' container xs={12} justifyContent={"center"}>
                            <div className="color-options">
                                {colors.map((color) => (
                                    <div key={color} className={`circle`} style={{ background: color.color, transform: selectedColor == color.color ? 'scale(1.2)' : '' }}
                                        onClick={() => { setSelectedTshirt(color.name); setSelectedColor(color.color); }}>
                                    </div>
                                ))}
                            </div>
                        </Grid>
                    </Grid>
                    {!showButton ?
                        < Box sx={{ p: '20px' }}>
                            <Box component={'form'} sx={{ margin: '0 auto' }} onSubmit={handleSubmit(Addtext)}>
                                <Grid item xs={3.5} display={'flex'} justifyContent={'center'} alignItems={"center"} >
                                    <Grid >
                                        <div>
                                            <InputLabel sx={{ color: 'black', fontWeight: '500', textAlign: 'left', fontFamily: 'Poppins, sans-serif' }}>Add Text</InputLabel>
                                            <TextField
                                                fullWidth
                                                variant="standard"
                                                size='small'

                                                {...register('tshirtText', { required: true })}
                                                onChange={handleInputChange}
                                                style={{ fontSize: `${fontSize}px`, color: "black", fontFamily: 'Poppins, sans-serif' }}
                                            />

                                            <div style={{ marginTop: '20px' }}>


                                                <InputLabel sx={{ color: 'black', fontWeight: '500', textAlign: 'left', fontFamily: 'Poppins, sans-serif' }}>Font Family</InputLabel>
                                                <FontPicker

                                                    apiKey="AIzaSyDPtqHOkdnMVZb6wrHcyz1Mwyn0Au-HCbI"
                                                    activeFontFamily={fontFamily}
                                                    onChange={(nextFont) => handleFontFamilyChange(nextFont.family)}
                                                />

                                            </div>
                                            <InputLabel sx={{ color: 'black', fontWeight: '500', textAlign: 'left', fontFamily: 'Poppins, sans-serif', mt: 3.5 }}>Font Weight</InputLabel>
                                            <Slider
                                                aria-label="Temperature"

                                                value={fontWeight}
                                                onChange={handleWeightChange}
                                                color='black'
                                                valueLabelDisplay="auto"
                                                shiftStep={30}
                                                mt={'2%'}
                                                step={100}
                                                marks
                                                min={100}
                                                max={900}
                                            />
                                            {/* <FormControl variant="standard" sx={{ mt: 0.2, minWidth: "100%" }}>

                                        <Select
                                            fullWidth
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            sx={{ textAlign: 'left' }}
                                            value={fontWeight}
                                            onChange={handleWeightChange}

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="100">100 (Thin)</MenuItem>
                                            <MenuItem value="200">200 (Extra Light)</MenuItem>
                                            <MenuItem value="300">300 (Light)</MenuItem>
                                            <MenuItem value="400">400 (Normal)</MenuItem>
                                            <MenuItem value="500">500 (Medium)</MenuItem>
                                            <MenuItem value="600">600 (Semi Bold)</MenuItem>
                                            <MenuItem value="700">700 (Bold)</MenuItem>
                                            <MenuItem value="800">800 (Extra Bold)</MenuItem>
                                            <MenuItem value="900">900 (Black)</MenuItem>
                                        </Select>
                                    </FormControl> */}
                                            <InputLabel id="font-size-label" style={{ color: 'black', fontWeight: '500', textAlign: 'left', marginTop: '5%', fontFamily: 'Poppins, sans-serif' }}>Font Size</InputLabel>
                                            <Grid container justifyContent={"center"} xs={12} mt={'2%'}>


                                                <Slider value={parseInt(fontSize)} defaultValue={fontSize} valueLabelDisplay="on" onChange={handleFontSizeChange} color='black' sx={{ fontFamily: 'Poppins, sans-serif' }} aria-label="Disabled slider" />
                                            </Grid>
                                            <InputLabel id="font-size-label" style={{ color: 'black', fontWeight: '500', textAlign: 'left', fontFamily: 'Poppins, sans-serif' }}>Text Color</InputLabel>
                                            <div className="blockpicker" style={{ textAlign: 'left', marginTop: '5%' }}>

                                                {/* Div to display the color  */}

                                                {/* Block Picker from react-color and handling color on onChange event */}
                                                <TwitterPicker
                                                    color={blockPickerColor}
                                                    onChange={(color) => {
                                                        handleTextColor(color.hex)

                                                        setBlockPickerColor(color.hex);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', margin: '0 auto', mt: '5%' }} >
                                            <Button type='submit' fullWidth sx={{
                                                backgroundColor: 'black', fontWeight: 'bold', color: 'white', padding: '10px 25px 10px 25px', '&:hover': {
                                                    backgroundColor: 'white',
                                                    color: 'black',
                                                    border: '2px solid black'
                                                },
                                            }} >
                                                Add
                                            </Button>
                                        </Box>
                                    </Grid>

                                </Grid> </Box></Box> : <Box onClick={toggleDrawer(true)} sx={{ position: 'fixed', top: '50%', right: 0, width: '40px', height: '100px', boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', backgroundColor: 'white' }}>

                            <Box sx={{ height: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                                <Box component={'img'} width={'40px'} src={Colors}>

                                </Box>
                                <Box>
                                    <FormatColorTextIcon sx={{ fontSize: '35px' }} />
                                </Box>

                            </Box>
                        </Box>}
                    <button onClick={() => handleExportWithComponent(contentRef)}>Capture</button>
                </Grid>



            </Grid>
        </>
    );
}

export default TshirtDesignCanvas;
