const Colors = {
    primary: "#0EA5EA",
    secondary: "#0076BF",
    backgroundColor1: "#0F172A",
    backgroundColor2: "#0C1427",
    white: "#FFFFFF",
    black: "#000000",
    textColor1: "#B9E0F2",
    textColor2: "#7F92B0"
  }
  
  export default Colors;