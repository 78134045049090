import React from 'react'
import TshirtDesignCanvasFinal from '../../../components/DesignCanvasfinal'
import { useLocation } from 'react-router-dom'

const ProductFinal = () => {
  
  const { state } = useLocation();
  console.log(state,'statestatestate');
  return (
    <div style={{backgroundColor:'black'}}>
      
      <TshirtDesignCanvasFinal imageUrl={state} />
    </div>
  )
}

export default ProductFinal
