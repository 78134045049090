const routes = {
    placeOrder:'/order/place',
    userProfile:'/users/profile',
    getLibrary:'/users/library',
    addLibrary:'/users/addToLibrary',
    getOrderList:'order/listing',
    getPricing:'system/pricing',
    removeBg:'/system/removeBGV1',
    removeBgV2:"/system/removeBGV2",
    Collections:'system/collections',
    completeOrder:'/order/complete'
 };
 
 export default routes