import { Box, Button, Card, CardContent, Divider, Grid, Paper, Stack, TextField, Typography } from '@mui/material'
import Lottie from 'lottie-react'
import React, { useContext, useEffect, useState } from 'react'
import creditCard from '../../assests/Animation/creditCard.json'
import completeCard from '../../assests/Animation/complete.json'
import errorCard from '../../assests/Animation/error.json'
import OrderServices from '../../services/OrderServices'
import { CartContext } from '../../Context/CartContext'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const OrderPlaced = () => {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)
  const { tap_id } = useParams();
  const { token } = useParams()
  const location = useLocation()
  const [paymentStatus, setPaymentStatus] = useState('')
  const [completeData, setCompleteData] = useState(null)
  const { cartVisible, toggleCartVisibility } = useContext(CartContext);

  const completeOrder = async () => {
    console.log(window.location.pathname);
    console.log(location);
    console.log(token);
    const searchParams = new URLSearchParams(location.search);
    // Extract the tap_id parameter
    let id = searchParams.get('tap_id');


    try {
      let obj = {
        tap_id: id


      }
      console.log(obj, 'obj');
      const { data } = await OrderServices.completeOrder(obj)

      if (data) {
        setCompleteData(data)
        setPaymentStatus('complete')
        console.log(data);
        localStorage.setItem('designArray', [])
        toggleCartVisibility()
        localStorage.removeItem('orderData')
        setLoader(false)
      }

    } catch (error) {
      setLoader(false)
      setPaymentStatus('failed')
      console.log(error);
    } finally {

    }
  }
  useEffect(() => {
    completeOrder()


  }, [])

  return (
    <div style={{ backgroundColor: '#161f38', height: '100vh' }}>
      <Grid container justifyContent={'center'}>
        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ marginTop: '50px' }}>
          <Box
            sx={{
              backgroundColor: '#0B132B',
              color: '#FFF',

              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              p: 5,
              pt: 1,
              borderRadius: '12px'
            }}
          >
            {/* Order success message */}
            {loader ? <>

              <Box sx={{ width: "200px" }}> <Lottie width={'200px'} height={'200px'} animationData={creditCard} loop={true} />


              </Box>

              <Typography variant="h6" >
                Processing Your Payment

              </Typography>
              <Box component="span" sx={{ color: '#FFF', fontWeight: 'bold', fontSize: '35px' }}>
                . . .
              </Box>
            </> : paymentStatus == 'complete' ? <>
              <Box sx={{ width: "200px" }}>
                <Lottie width={'200px'} height={'200px'} animationData={completeCard} loop={true} />


              </Box>




              {/* Thank you message */}
              <Typography variant="h6" sx={{ mb: 1 }}>
                Thank you for your order, Your order has been confirmed.
                {/* <Box component="span" sx={{ color: '#FFF', fontWeight: 'bold' }}>
                  45 minutes
                </Box> */}
              </Typography>

              {/* Delivery details */}
              <Paper
                elevation={3}
                sx={{
                  backgroundColor: '#1C2541',
                  width: '100%',

                  p: 2,
                  mt: 2,
                }}
              >
                <Grid container spacing={2} sx={{ color: 'white' }}>
                  <Grid item xs={12}>
                    <Typography>Here we will deliver your order:</Typography>
                  </Grid>

                  {/* Address */}
                  <Grid item xs={12}>
                    <Typography>
                      📍 {completeData?.order?.address?.address_1 + ' ' + completeData?.order?.address?.emirate}
                    </Typography>
                  </Grid>

                  {/* Phone number */}
                  <Grid item xs={12}>
                    <Typography>
                      📞 {completeData?.order?.customer?.phone}
                    </Typography>
                  </Grid>


                </Grid>
              </Paper>

              {/* Payment info */}
              <Typography sx={{ mt: 2 }}>
                The payment of{' '}
                <Box component="span" sx={{ fontWeight: 'bold' }}>
                  {completeData?.order?.payment_amount} AED
                </Box>{' '}
                has been charged Successfully.
              </Typography>
              <Button
               onClick={()=> { 
                localStorage.removeItem('cartData')
                
                toggleCartVisibility();
               navigate('/')}}
                variant="contained"
                sx={{
                  backgroundColor: '#00bfa5',
                  color: '#fff',
                  textTransform: 'none',
                 
                  mt: 2,
                }}
              >
                Back to Home
              </Button>
            </> : <> <Box sx={{ width: "200px" }}>
              <Lottie width={'200px'} height={'200px'} animationData={errorCard} loop={true} />


            </Box>




              {/* Thank you message */}
              <Typography variant="h6" sx={{ mb: 1 }}>
                Transaction Failed, Please Try Again.
                {/* <Box component="span" sx={{ color: '#FFF', fontWeight: 'bold' }}>
                  45 minutes
                </Box> */}
                
              </Typography>
              <Button
                       onClick={()=> {
                        localStorage.removeItem('cartData')
                        toggleCartVisibility();
                        navigate('/')}}
                variant="contained"
                sx={{
                  backgroundColor: '#00bfa5',
                  color: '#fff',
                  textTransform: 'none',
             
                  mt: 2,
                }}
              >
                Back to Home
              </Button></>}
          </Box>
        </Grid>
      </Grid>

    </div>
  )
}

export default OrderPlaced
