import React, { useContext, useEffect, useRef, useState } from 'react'
import { CustomStyle, Delivery, Images, NoCharges, Order, Payment, Printing } from '../../../assests/index'
import { Avatar, Box, ButtonGroup, CardMedia, Chip, CircularProgress, Container, Grid, IconButton, InputAdornment, LinearProgress, List, ListItem, ListItemText, Skeleton, TextField, ToggleButton, ToggleButtonGroup, Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import Colors from '../../../assests/style'
import { SecondaryButton } from '../../../components/Buttons'
import EastIcon from '@mui/icons-material/East';
import { PulseLoader, RingLoader } from 'react-spinners'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import randomImg from '../../../assests/images/cair_03277_Red_riding_hood_in_iron_man_costume_6380ca9f-11bc-41a5-b7a5-9eaca2f76800.png'
import DownloadIcon from '@mui/icons-material/Download';
import '@fontsource/plus-jakarta-sans'
import { AccountCircle, Star } from '@mui/icons-material'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import OrderServices from '../../../services/OrderServices'
import YouTube from 'react-youtube'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick'
import AuthServices from '../../../services/AuthServices'
import { signInWithPopup } from 'firebase/auth'
import { auth, provider } from '../../../config/firebase.config'
import { AuthContext } from '../../../Context/AuthContext'
import Swal from 'sweetalert2'
import ReactCompareImage from "react-compare-image";
import confetti from 'canvas-confetti'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Home = () => {
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const upscalingRef = useRef(null);
  const { state } = useLocation()
  const confettiRef = useRef(null);
  const [switchState, setSwitchState] = useState(false)
  const scrollToSection = (section) => {
    if (section === 'section1Ref') {
      section1Ref.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'section2Ref') {
      section2Ref.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'section3Ref') {
      upscalingRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  let sliderData = [{ img: Images.collection1 }, { img: Images.collection2 }, { img: Images.collection3 }, { img: Images.collection4 }]
  const prompts = [
    "A 1920s art deco cityscape with geometric buildings and vintage cars",
    "A surreal painting of melting clocks inspired by Salvador Dalí",
    "A whimsical forest scene in the style of Studio Ghibli, with friendly forest spirits",
    "A gothic cathedral interior with dramatic lighting and detailed stained glass windows",
    "A minimalist black and white sketch of a bustling urban street",
    "A watercolor landscape of a peaceful countryside with rolling hills and a small cottage",
    "A vibrant pop art portrait of a famous celebrity in the style of Andy Warhol",
    "An abstract expressionist painting with bold, chaotic brushstrokes and vibrant colors",
    "A vintage travel poster of a tropical paradise island",
    "A detailed pen and ink illustration of a fantasy castle on a mountain top",
    "A pixel art scene of a retro video game character on an adventure",
    "A classic Renaissance-style portrait of a royal figure in elaborate attire",
    "A futuristic sci-fi landscape with sleek skyscrapers and flying vehicles, inspired by concept art",
    "A dreamy, pastel-colored digital illustration of a girl reading a book under a cherry blossom tree",
    "A bold graffiti-style artwork of a street scene with colorful tags and murals",
    "A steampunk airship with intricate gears and steam-powered machinery",
    "A neon-lit cyberpunk alleyway with rain-soaked streets and holographic signs",
    "A magical underwater kingdom with mermaids and vibrant coral reefs",
    "A retro 80s-inspired grid landscape with glowing purple and blue lines",
    "A fantasy scene of a knight fighting a dragon in a moonlit forest",
    "A Japanese ink wash painting of a serene mountain landscape",
    "A 3D render of a futuristic robot in a sleek, high-tech environment",
    "A Baroque-style still life with fruit, flowers, and rich drapery",
    "A comic book panel of a superhero in action, with bold lines and dynamic poses",
    "A celestial map of a fictional star system with planets and constellations",
    "A modern art installation with geometric shapes and vibrant colors",
    "A classic Western scene with cowboys, horses, and a dusty frontier town",
    "A dreamy, surreal landscape with floating islands and cascading waterfalls",
    "A sci-fi cityscape with towering skyscrapers and advanced transportation systems",
    "A Renaissance-style fresco depicting mythological gods and heroes",

    "A hand-drawn map of a fantasy world with mountains, forests, and kingdoms",
    "A psychedelic poster with swirling patterns and vibrant, contrasting colors",
    "A cozy, detailed illustration of a quaint bookstore interior",
    "A futuristic vehicle design with sleek lines and advanced technology",
    "A dark fantasy scene with a haunted castle and eerie, misty surroundings",
    "A high-fashion runway show with models in avant-garde outfits",
    "A serene Zen garden with raked sand, rocks, and a small pond",
    "A retro-futuristic diner with chrome accents and neon signs",
    "A detailed botanical illustration of exotic plants and flowers",
    "A conceptual art piece with thought-provoking imagery and symbolism",
    "A post-apocalyptic landscape with crumbling buildings and overgrown vegetation",
    "A vibrant festival scene with dancers, musicians, and colorful decorations",
    "A photorealistic painting of a bustling city street at night",
    "A vintage comic strip featuring humorous characters and witty dialogue",
    "A serene autumn landscape with colorful foliage and a calm river",
    "A whimsical illustration of a fairy tale village with quirky houses",
    "A black and white photograph of a dramatic, stormy seascape",
    "A retro arcade scene with classic video game machines and pixel art characters",
    "A detailed, fantasy-inspired illustration of an elven city in the trees",
    "A colorful abstract painting with flowing shapes and vibrant hues",
    "A dramatic scene of a space battle with starships and laser beams",
    "A tranquil beach scene with soft waves, white sand, and palm trees",
    "A classic noir detective scene with shadowy figures and a smoky atmosphere",
    "A fantasy portrait of a sorceress with glowing magical runes",
    "A futuristic space station interior with advanced technology and sleek design",
    "A whimsical children's book illustration of animals having a tea party",
    "A detailed architectural drawing of a modern skyscraper",
    "A vibrant street art mural with a powerful social message",
    "A retro 70s-inspired disco scene with a glittering dance floor and groovy outfits",
    "A serene winter landscape with snow-covered trees and a cozy cabin",
    "A dynamic action scene with superheroes battling villains in a cityscape",
    "A minimalist landscape with bold, contrasting colors and simple shapes",
    "A vintage circus poster with performers, animals, and a big top tent",
    "A colorful mandala design with intricate patterns and symmetry",
    "A dreamy underwater scene with dolphins, fish, and a sunken ship",
    "A dramatic, high-contrast black and white portrait of an elderly person",
    "A fantasy scene of a wizard casting a powerful spell in a mystical forest",
    "A retro-futuristic cityscape with flying cars and neon signs",
    "A photorealistic painting of a majestic lion in the savannah",
    "A vibrant, abstract digital art piece with flowing lines and bright colors",
    "A classic film noir scene with a detective, a femme fatale, and a dark alley",
    "A whimsical illustration of a magical treehouse in an enchanted forest",
    "A futuristic sports car design with sleek, aerodynamic lines",
    "A tranquil sunset over a serene lake with a reflection of the sky",
    "A dynamic dance performance scene with fluid movements and vibrant costumes",
    "A dramatic space scene with planets, asteroids, and a distant galaxy",
    "A detailed architectural rendering of a futuristic building complex",
    "A colorful street festival with dancers, musicians, and food stalls",
    "A serene countryside scene with rolling hills, a winding road, and a farmhouse",
    "A whimsical underwater scene with mermaids, sea creatures, and a treasure chest",
    "A dramatic, high-contrast painting of a thunderstorm over the ocean",
    "A retro-inspired space scene with vintage-style spacecraft and planets",
    "A detailed fantasy map of an ancient kingdom with mountains, rivers, and forests",
    "A vibrant, abstract graffiti mural with bold colors and dynamic shapes",
    "A serene mountain landscape with a clear blue sky and a calm lake",
    "A whimsical illustration of a fairy village with tiny houses and magical creatures",
    "A dramatic cityscape at sunset with towering skyscrapers and a glowing sky",
    "A classic Western scene with cowboys, horses, and a dusty frontier town",
    "A dreamy, surreal landscape with floating islands and cascading waterfalls",
    "A sci-fi cityscape with towering skyscrapers and advanced transportation systems",
    "A Renaissance-style fresco depicting mythological gods and heroes",

    "A hand-drawn map of a fantasy world with mountains, forests, and kingdoms",
    "A psychedelic poster with swirling patterns and vibrant, contrasting colors",
    "A cozy, detailed illustration of a quaint bookstore interior",
    "A futuristic vehicle design with sleek lines and advanced technology",
    "A dark fantasy scene with a haunted castle and eerie, misty surroundings"
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,

    slidesToScroll: 1,

  };

  let randomTexts = [
    "Reticulating splines...",
    "Generating witty dialog...",
    "Swapping time and space...",
    "Spinning violently around the y-axis...",
    "Tokenizing real life...",
    "Bending the spoon...",
    "Filtering morale...",
    "Don't think of purple hippos...",
    "We need a new fuse...",
    "Have a good day.",
    "Upgrading Windows, your PC will restart several times. Sit back and relax.",
    "640K ought to be enough for anybody",
    "The architects are still drafting",
    "The bits are breeding",
    "We're building the buildings as fast as we can",
    "Would you prefer chicken, steak, or tofu?",
    "(Pay no attention to the man behind the curtain)",
    "...and enjoy the elevator music...",
    "Please wait while the little elves draw your map",
    "Don't worry - a few bits tried to escape, but we caught them",
    "Would you like fries with that?",
    "Checking the gravitational constant in your locale...",
    "Go ahead -- hold your breath!",
    "...at least you're not on hold...",
    "Hum something loud while others stare",
    "You're not in Kansas any more",
    "The server is powered by a lemon and two electrodes.",
    "Please wait while a larger software vendor in Seattle takes over the world",
    "We're testing your patience",
    "As if you had any other choice",
    "Follow the white rabbit",
    "Why don't you order a sandwich?",
    "While the satellite moves into position",
    "keep calm and npm install",
    "The bits are flowing slowly today",
    "Dig on the 'X' for buried treasure... ARRR!",
    "It's still faster than you could draw it",
    "The last time I tried this the monkey didn't survive. Let's hope it works better this time.",
    "I should have had a V8 this morning.",
    "My other loading screen is much faster.",
    "Testing on Timmy... We're going to need another Timmy.",
    "Reconfoobling energymotron...",
    "(Insert quarter)",
    "Are we there yet?",
    "Have you lost weight?",
    "Just count to 10",
    "Why so serious?",
    "It's not you. It's me.",
    "Counting backwards from Infinity",
    "Don't panic...",
    "Embiggening Prototypes",
    "Do not run! We are your friends!",
    "Do you come here often?",
    "Warning: Don't set yourself on fire.",
    "We're making you a cookie.",
    "Creating time-loop inversion field",
    "Spinning the wheel of fortune...",
    "Loading the enchanted bunny...",
    "Computing chance of success",
    "I'm sorry Dave, I can't do that.",
    "Looking for exact change",
    "All your web browser are belong to us",
    "All I really need is a kilobit.",
    "I feel like im supposed to be loading something. . .",
    "What do you call 8 Hobbits? A Hobbyte.",
    "Should have used a compiled language...",
    "Is this Windows?",
    "Adjusting flux capacitor...",
    "Please wait until the sloth starts moving.",
    "Don't break your screen yet!",
    "I swear it's almost done.",
    "Let's take a mindfulness minute...",
    "Unicorns are at the end of this road, I promise.",
    "Listening for the sound of one hand clapping...",
    "Keeping all the 1's and removing all the 0's...",
    "Putting the icing on the cake. The cake is not a lie...",
    "Cleaning off the cobwebs...",
    "Making sure all the i's have dots...",
    "We need more dilithium crystals",
    "Where did all the internets go",
    "Connecting Neurotoxin Storage Tank...",
    "Granting wishes...",
    "Time flies when you’re having fun.",
    "Get some coffee and come back in ten minutes..",
    "Spinning the hamster…",
    "99 bottles of beer on the wall..",
    "Stay awhile and listen..",
    "Be careful not to step in the git-gui",
    "You edhall not pass! yet..",
    "Load it and they will come",
    "Convincing AI not to turn evil..",
    "There is no spoon. Because we are not done loading it",
    "Your left thumb points to the right and your right thumb points to the left.",
    "How did you get here?",
    "Wait, do you smell something burning?",
    "Computing the secret to life, the universe, and everything.",
    "When nothing is going right, go left!!...",
    "I love my job only when I'm on vacation...",
    "i'm not lazy, I'm just relaxed!!",
    "Never steal. The government hates competition....",
    "Why are they called apartments if they are all stuck together?",
    "Life is Short – Talk Fast!!!!",
    "Optimism – is a lack of information.....",
    "Save water and shower together",
    "Whenever I find the key to success, someone changes the lock.",
    "Sometimes I think war is God’s way of teaching us geography.",
    "I’ve got problem for your solution…..",
    "Where there’s a will, there’s a relative.",
    "User: the word computer professionals use when they mean !!idiot!!",
    "Adults are just kids with money.",
    "I think I am, therefore, I am. I think.",
    "You don’t pay taxes—they take taxes.",
    "Coffee, Chocolate, Men. The richer the better!",
    "I am free of all prejudices. I hate everyone equally.",
    "git happens",
    "May the forks be with you",
    "A commit a day keeps the mobs away",
    "This is not a joke, it's a commit.",
    "Constructing additional pylons...",
    "Roping some seaturtles...",
    "Locating Jebediah Kerman...",
    "We are not liable for any broken screens as a result of waiting.",
    "Hello IT, have you tried turning it off and on again?",
    "If you type Google into Google you can break the internet",
    "Well, this is embarrassing.",
    "What is the airspeed velocity of an unladen swallow?",
    "Hello, IT... Have you tried forcing an unexpected reboot?",
    "They just toss us away like yesterday's jam.",
    "They're fairly regular, the beatings, yes. I'd say we're on a bi-weekly beating.",
    "The Elders of the Internet would never stand for it.",
    "Space is invisible mind dust, and stars are but wishes.",
    "Didn't know paint dried so quickly.",
    "Everything sounds the same",
    "I'm going to walk the dog",
    "I didn't choose the engineering life. The engineering life chose me.",
    "Dividing by zero...",
    "Spawn more Overlord!",
    "If I’m not back in five minutes, just wait longer.",
    "Some days, you just can’t get rid of a bug!",
    "We’re going to need a bigger boat.",
    "Chuck Norris never git push. The repo pulls before.",
    "Web developers do it with <style>",
    "I need to git pull --my-life-together",
    "Java developers never RIP. They just get Garbage Collected.",
    "Cracking military-grade encryption...",
    "Simulating traveling salesman...",
    "Proving P=NP...",
    "Entangling superstrings...",
    "Twiddling thumbs...",
    "Searching for plot device...",
    "Trying to sort in O(n)...",
    "Laughing at your pictures-i mean, loading...",
    "Sending data to NS-i mean, our servers.",
    "Looking for sense of humour, please hold on.",
    "Please wait while the intern refills his coffee.",
    "A different error message? Finally, some progress!",
    "Hold on while we wrap up our git together...sorry",
    "Please hold on as we reheat our coffee",
    "Kindly hold on as we convert this bug to a feature...",
    "Kindly hold on as our intern quits vim...",
    "Winter is coming...",
    "Installing dependencies",
    "Switching to the latest JS framework...",
    "Distracted by cat gifs",
    "Finding someone to hold my beer",
    "BRB, working on my side project",
    "@todo Insert witty loading message",
    "Let's hope it's worth the wait",
    "Aw, snap! Not..",
    "Ordering 1s and 0s...",
    "Updating dependencies...",
    "Whatever you do, don't look behind you...",
    "Please wait... Consulting the manual...",
    "It is dark. You're likely to be eaten by a grue.",
    "Loading funny message...",
    "It's 10:00pm. Do you know where your children are?",
    "Waiting Daenerys say all her titles...",
    "Feel free to spin in your chair",
    "What the what?",
    "format C: ...",
    "Forget you saw that password I just typed into the IM ...",
    "What's under there?",
    "Your computer has a virus, its name is Windows!",
    "Go ahead, hold your breath and do an ironman plank till loading complete",
    "Bored of slow loading spinner, buy more RAM!",
    "Help, I'm trapped in a loader!",
    "What is the difference btwn a hippo and a zippo? One is really heavy, the other is a little lighter",
    "Please wait, while we purge the Decepticons for you. Yes, You can thanks us later!",
    "Chuck Norris once urinated in a semi truck's gas tank as a joke....that truck is now known as Optimus Prime.",
    "Chuck Norris doesn’t wear a watch. HE decides what time it is.",
    "Mining some bitcoins...",
    "Downloading more RAM..",
    "Updating to Windows Vista...",
    "Deleting System32 folder",
    "Hiding all ;'s in your code",
    "Alt-F4 speeds things up.",
    "Initializing the initializer...",
    "When was the last time you dusted around here?",
    "Optimizing the optimizer...",
    "Last call for the data bus! All aboard!",
    "Running swag sticker detection...",
    "Never let a computer know you're in a hurry.",
    "A computer will do what you tell it to do, but that may be much different from what you had in mind.",
    "Some things man was never meant to know. For everything else, there's Google.",
    "Unix is user-friendly. It's just very selective about who its friends are.",
    "Shovelling coal into the server",
    "Pushing pixels...",
    "How about this weather, eh?",
    "Building a wall...",
    "Everything in this universe is either a potato or not a potato",
    "The severity of your issue is always lower than you expected.",
    "Updating Updater...",
    "Downloading Downloader...",
    "Debugging Debugger...",
    "Reading Terms and Conditions for you.",
    "Digested cookies being baked again.",
    "Live long and prosper.",
    "There is no cow level, but there's a goat one!",
    "Running with scissors...",
    "Definitely not a virus...",
    "You may call me Steve.",
    "You seem like a nice person...",
    "Coffee at my place, tommorow at 10A.M. - don't be late!",
    "Work, work...",
    "Patience! This is difficult, you know...",
    "Discovering new ways of making you wait...",
    "Your time is very important to us. Please wait while we ignore you...",
    "Time flies like an arrow; fruit flies like a banana",
    "Two men walked into a bar; the third ducked...",
    "Sooooo... Have you seen my vacation photos yet?",
    "Sorry we are busy catching em' all, we're done soon",
    "TODO: Insert elevator music",
    "Still faster than Windows update",
    "Composer hack: Waiting for reqs to be fetched is less frustrating if you add -vvv to your command.",
    "Please wait while the minions do their work",
    "Grabbing extra minions",
    "Doing the heavy lifting",
    "We're working very Hard .... Really",
    "Waking up the minions",
    "You are number 2843684714 in the queue",
    "Please wait while we serve other customers...",
    "Our premium plan is faster",
    "Feeding unicorns...",
    "Rupturing the subspace barrier",
    "Creating an anti-time reaction",
    "Converging tachyon pulses",
    "Bypassing control of the matter-antimatter integrator",
    "Adjusting the dilithium crystal converter assembly",
    "Reversing the shield polarity",
    "Disrupting warp fields with an inverse graviton burst",
    "Up, Up, Down, Down, Left, Right, Left, Right, B, A.",
    "Do you like my loading animation? I made it myself",
    "Whoah, look at it go!",
    "No, I'm awake. I was just resting my eyes.",
    "One mississippi, two mississippi...",
    "Don't panic... AHHHHH!",
    "Ensuring Gnomes are still short.",
    "Baking ice cream...",
  ];
  const UnorderedListStyled = styled(List)({
    listStyleType: 'disc',
    paddingLeft: '20px',
  });

  const beforeImage = Images.Cartoon1;
  const afterImage = Images.Cartoon2;
  const ListItemStyled = styled(ListItem)({
    display: 'list-item',
  });

  const navigate = useNavigate()
  const [expanded, setExpanded] = useState('panel1');
  const [loader, setLoader] = useState(false)
  const resultRef = useRef('')
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isLoading, setIsLoading] = useState(false)
  const [result, setResult] = useState('')
  const [index, setIndex] = useState('')
  const [progress, setProgress] = useState(0)
  const [upScalingProgress, setUpScalingProgress] = useState(0)
  const [allDetails, setAllDetails] = useState()
  const [currentMessage, setCurrentMessage] = useState('');
  const [upScaleText, setUpScaleText] = useState(false)
  const [upScalingLoader, setUpScalingLoader] = useState(false)
  const [showDowloadBtn, setShowDowloadBtn] = useState(false)
  const [randomPrompt, setRandomPrompt] = useState('')
  const [customizeBtn, setCustomizeBtn] = useState(false)
  const [finalLoader, setFinalLoader] = useState(false)
  const [generateBtn, setGenerateBtn] = useState(true)
  const [generateText, setGenerateText] = useState(false)
  const [linearProgress, setLinearProgress] = React.useState(0);
  const [showlinearProgress, setShowLinearProgress] = useState(false)
  const [imagesGroup, setImagesGroup] = useState([])
  const [upScaled, setUpScaled] = useState(false)
  const [upScaleLoader, setUpScaleLoader] = useState(false)
  const [upScaleData, setUpScaleData] = useState()
  const [scalingConfirmation, setScalingConfirmation] = useState(false)
  const [imgMaping, setImgMaping] = useState(true)
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [selectScaleIndex, setSelectScaleIndex] = useState(1)
  const [userData, setUserData] = useState()
  const { user, setUser } = useContext(AuthContext);
  const [promptHeight, setPromptHeight] = useState(false)
  const inputRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const [selectedChips, setSelectedChips] = useState([]);
  const [magicPrompt, setMagicPrompt] = useState('No');
  const [aspectRatio, setAspectRatio] = useState('9:16');
  const [visibility, setVisibility] = useState('Public');
  const [comparison, setComparison] = useState(false)
  const [removedBgPic, setRemovedBgPic] = useState()
  const [removedBgPic2, setRemovedBgPic2] = useState()
  const handleMagicPromptChange = (event, newValue) => {
    if (newValue !== null) {
      setMagicPrompt(newValue);
      console.log(newValue);

    }
  };


  const handleVisibilityChange = (event, newValue) => {
    if (newValue !== null) {
      setVisibility(newValue);
    }
  };


  const handleClick = (item) => {
    setSelectedChips((prevSelected) => {
      if (selectedChips.find(chip => chip?.id == item?.id)) {
        console.log(prevSelected.filter((i) => i?.id !== item.id), 'prevSelected.filter((i) => i?.id !== item.id)');

        // If the item is already selected, deselect it
        return prevSelected.filter((i) => i?.id !== item.id);
      } else {
        // Otherwise, add it to the selection
        return [...prevSelected, item];
      }
    });
  };

  const handleMouseDown = (e) => {
    setDragging(true);
    setStartX(e.pageX - inputRef.current.offsetLeft);
    setScrollLeft(inputRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!dragging) return;
    e.preventDefault();
    const x = e.pageX - inputRef.current.offsetLeft;
    const walk = (x - startX) * 3; // scroll-fast
    inputRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleTouchStart = (e) => {
    setDragging(true);
    setStartX(e.touches[0].pageX - inputRef.current.offsetLeft);
    setScrollLeft(inputRef.current.scrollLeft);
  };

  const handleTouchMove = (e) => {
    if (!dragging) return;
    e.preventDefault(); // Add this
    const x = e.touches[0].pageX - inputRef.current.offsetLeft;
    const walk = (x - startX) * 3; // scroll-fast
    inputRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleTouchEnd = () => {
    setDragging(false);
  };

  const handlePointerDown = (e) => {
    setDragging(true);
    setStartX(e.clientX - inputRef.current.offsetLeft);
    setScrollLeft(inputRef.current.scrollLeft);
  };

  const handlePointerMove = (e) => {
    if (!dragging) return;
    const x = e.clientX - inputRef.current.offsetLeft;
    const walk = (x - startX) * 3; // scroll-fast
    inputRef.current.scrollLeft = scrollLeft - walk;
  };

  const handlePointerUp = () => {
    setDragging(false);
  };
  const suggestedPromptArray = [
    { id: 1, name: "Landscape" },
    { id: 2, name: "Abstract" },
    { id: 3, name: "Surreal" },
    { id: 4, name: "Futuristic" },
    { id: 5, name: "Fantasy" },
    { id: 6, name: "Minimalist" },
    { id: 7, name: "Cyberpunk" },
    { id: 8, name: "Vintage" },
    { id: 9, name: "Urban" },
    { id: 10, name: "Ethereal" },



  ];

  const opts = {
    width: '500px',
    height: '300px',

    // playerVars: {
    //   autoplay: 1,
    // },
  };
  const {
    register,
    handleSubmit,
    getValues,
    setValue,

    formState: { errors },
    reset
  } = useForm();


  const productBoxes = [
    {
      icon: <NoCharges />,
      title: "No Hidden Charges - Get the Best Deal",
      description: "Forget about extra charges. Our pricing is simple and transparent. With PrintMeUp, you don't need to drive to a printer or pay for an image generator tool. We offer a cost-effective solution for your custom t-shirts"
    },
    {
      icon: <Printing />,
      title: "High quality printing",
      description: "Experience the durability and longevity of DTF printing on premium t-shirts, ensuring top-notch quality every time."
    },
    {
      icon: <Payment />,
      title: "Secure payment",
      description: "Shop with confidence at PrintMeUp. Our secure payment options ensure your personal information is protected, and we do not store any of your payment details."
    },
    {
      icon: <CustomStyle />,
      title: "Custom Size & Style",
      description: "Find the perfect fit. Choose from a wide range of sizes and styles to create a t-shirt that suits your unique preferences."
    },
    {
      icon: <Delivery />,
      title: "Fast Delivery",
      description: "Enjoy the convenience of fast delivery. Receive your custom t-shirts quickly, right at your doorstep."
    },
    {
      icon: <Order />,
      title: "Low Minimum Order",
      description: "Order with ease at PrintMeUp. Our low minimum order quantities make it simple and affordable to express your unique style and feelings with customized t-shirts."
    },
  ]

  const reviewBoxes = [
    {
      avatar: '',
      name: "Ahmed Khalifa",
      designation: "",
      comments: "Print Me Up has revolutionised shirt printing in the UAE with their AI technology! The prints are vibrant, the fabric is top-notch, and their service is impeccable. Highly recommend!"
    },
    {
      avatar: '',
      name: "Fatima Al Ameri",
      designation: "",
      comments: "Print Me Up is my go-to for customized shirts in Dubai. Their AI image generation makes designing easy, and the quality is outstanding. I'm always impressed!"
    },
    {
      avatar: '',
      name: "Ali Hassan",
      designation: "",
      comments: "Living in Abu Dhabi, I've tried many shirt printing services, but Print Me Up is by far the best. Their AI-generated designs are stunning, and they deliver on time every time."
    },
    {
      avatar: '',
      name: "Reem Ahmed",
      designation: "",
      comments: "Print Me Up in Sharjah offers fantastic service and high-tech printing. Their AI technology ensures my designs are perfect, and the shirts are comfortable to wear."
    },
    {
      avatar: '',
      name: "Omar Al Hashimi",
      designation: "",
      comments: "As a resident of Ras Al Khaimah, I trust Print Me Up for all my shirt printing needs. Their AI image generation creates unique designs, and the customer service is excellent"
    },
    {
      avatar: '',
      name: " Mariam Al Suwaidi",
      designation: "",
      comments: "Print Me Up in Ajman has made custom shirt printing hassle-free. Their AI-driven process is smooth, and the results are always impressive. Love their work!"
    },
  ];

  const filename = 'Generated-image ';

  const downloadImage = async (url, name) => {
    // Fetch the image data as a Blob
    const response = await fetch(url);
    const blob = await response.blob();

    // Create a temporary URL for the Blob
    const blobUrl = window.URL.createObjectURL(blob);

    // Create a temporary anchor element and trigger the download
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = name;
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
  };
  const handleChange = (panel) => () => {
    setExpanded((prev) => ({ ...prev, [panel]: !prev[panel] }));
  };

  const downloadImageWithLogo = async (imageSrc, logoSrc, name) => {
    // Load the image
    const loadImage = src => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous'; // This is important to avoid CORS issues
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
      });
    };

    try {
      const [image, logo] = await Promise.all([loadImage(imageSrc), loadImage(logoSrc)]);

      // Create a canvas and set its dimensions to the image dimensions
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext('2d');

      // Draw the image onto the canvas
      ctx.drawImage(image, 0, 0);

      // Define logo dimensions and position
      const logoWidth = 200; // Width of the logo
      const logoHeight = 70; // Height of the logo
      const logoX = canvas.width - logoWidth - 20; // X position of the logo
      const logoY = canvas.height - logoHeight - 20; // Y position of the logo
      const borderRadius = 12; // Border radius

      // Draw a semi-transparent black rectangle with border radius behind the logo
      ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
      ctx.beginPath();
      ctx.moveTo(logoX + borderRadius, logoY);
      ctx.lineTo(logoX + logoWidth - borderRadius, logoY);
      ctx.arcTo(logoX + logoWidth, logoY, logoX + logoWidth, logoY + borderRadius, borderRadius);
      ctx.lineTo(logoX + logoWidth, logoY + logoHeight - borderRadius);
      ctx.arcTo(logoX + logoWidth, logoY + logoHeight, logoX + logoWidth - borderRadius, logoY + logoHeight, borderRadius);
      ctx.lineTo(logoX + borderRadius, logoY + logoHeight);
      ctx.arcTo(logoX, logoY + logoHeight, logoX, logoY + logoHeight - borderRadius, borderRadius);
      ctx.lineTo(logoX, logoY + borderRadius);
      ctx.arcTo(logoX, logoY, logoX + borderRadius, logoY, borderRadius);
      ctx.closePath();
      ctx.fill();

      // Draw the logo onto the canvas
      ctx.drawImage(logo, logoX, logoY, logoWidth, logoHeight);

      // Convert the canvas to a Blob and trigger the download
      canvas.toBlob(blob => {
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      }, 'image/png');
    } catch (error) {
      console.error('Error downloading the image with logo:', error);
    }
  };




  const HandlePrompt = () => {
    const randomIndex = Math.floor(Math.random() * prompts.length);
    setValue('prompt', prompts[randomIndex])
    setRandomPrompt(prompts[randomIndex]);

  };
  const handleClose = () => {

    setOpen(false);

  };

  const addLibrary = async (url, index) => {

    try {
      let obj = {

        prompt: getValues('prompt'),
        url: url,
        type: "upscale",
        item_id: allDetails?.id,
        index: 1,
        hash: allDetails?.hash,
        flags: allDetails?.flags

      }
      const { data } = await OrderServices.addLibrary(obj)




    } catch (error) {
      console.log(error);
    } finally {

    }
  }
  const removeBg = async (url) => {

    try {
      let obj = {

        imageUrl: url

      }
      const { data } = await OrderServices.removeBg(obj)
      console.log(data?.image);

      setRemovedBgPic(data?.image)



    } catch (error) {
      console.log(error);
    } finally {

    }
  }
  const removeBgV2 = async (url) => {

    try {
      let obj = {

        imageUrl: url

      }
      const { data } = await OrderServices.removeBgV2(obj)
      console.log(data?.image);

      setRemovedBgPic2(data?.image)



    } catch (error) {
      console.log(error);
    } finally {

    }
  }

  const handleScaling = async (index, confirmation) => {

    if (confirmation) {
      window.location.href = '#img-section';
      setUpScaleLoader(true)
      setUpScaleText(false)
      setShowDowloadBtn(false)

      const eventSourceScaling = new EventSource(`https://generator.printmeup.ai/upscale?hash=${allDetails.hash}&msg_id=${allDetails.id}&index=${selectScaleIndex}&flag=${allDetails.flags}`);


      eventSourceScaling.onopen = () => {
        console.log('Connection to server opened.');
        setUpScalingLoader(true)
      };

      eventSourceScaling.onerror = (error) => {

        eventSourceScaling.close();
      };
      eventSourceScaling.onmessage = (event) => {
        let resultData = JSON.parse(event.data)
        console.log('Received message:', resultData);
        setUpScalingProgress(resultData.progress)
        if (resultData.msg === 'Completed') {
          setResult(resultData.uri)
          if (magicPrompt == 'Yes') {
            setComparison(true)
            handleConfetti()
            removeBg(resultData.uri)
          }

          setUpScaled(true)
          setGenerateBtn(true)
          setCustomizeBtn(true)
          setUpScalingLoader(false)
          setIndex(index)
          setImgMaping(false)
          setShowDowloadBtn(true)
          setUpScaleLoader(false)

        }

      }
    }

  }



  async function splitImage(imageUrl) {
    try {
      if (!imageUrl) throw new Error("Invalid image URL");

      console.log(`Image URL: ${imageUrl}`);

      const uniqueUrl = `${imageUrl}`;
      console.log(`Unique URL: ${uniqueUrl}`);

      const loadedImage = await new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous"; // To avoid CORS issues if the image is hosted on a different domain
        img.src = uniqueUrl;

        img.onload = () => {
          console.log("Image loaded successfully");
          resolve(img);
        };
        img.onerror = (error) => {
          console.error(`Error loading image: ${error.message}`);
          reject(new Error(`Error loading image: ${error.message}`));
        };
      });
      console.log(loadedImage, 'loadedImage')
      const width = loadedImage.width;
      const height = loadedImage.height;
      const halfWidth = width / 2;
      const halfHeight = height / 2;

      console.log(`Image dimensions: ${width}x${height}`);

      // Create 4 canvases
      const canvases = Array.from({ length: 4 }, (_, index) => {
        const canvas = document.createElement('canvas');
        canvas.width = halfWidth;
        canvas.height = halfHeight;
        console.log(`Created canvas ${index + 1}`);
        return canvas;
      });

      const contexts = canvases.map((canvas, index) => {
        const context = canvas.getContext('2d');
        console.log(`Obtained context for canvas ${index + 1}`);
        return context;
      });

      // Draw the four quadrants on the respective canvases
      contexts[0].drawImage(loadedImage, 0, 0, halfWidth, halfHeight, 0, 0, halfWidth, halfHeight);
      contexts[1].drawImage(loadedImage, halfWidth, 0, halfWidth, halfHeight, 0, 0, halfWidth, halfHeight);
      contexts[2].drawImage(loadedImage, 0, halfHeight, halfWidth, halfHeight, 0, 0, halfWidth, halfHeight);
      contexts[3].drawImage(loadedImage, halfWidth, halfHeight, halfWidth, halfHeight, 0, 0, halfWidth, halfHeight);

      console.log("Finished drawing on canvases");

      // Convert canvases to data URLs
      const imgSources = canvases.map((canvas, index) => {
        console.log(`Converting canvas ${index + 1} to data URL`);
        return canvas.toDataURL();
      });

      console.log('Image sources:', imgSources);
      setImagesGroup(imgSources);
      return imgSources;
    } catch (error) {
      console.error(`Error during image processing: ${error.message}`);
      throw new Error(`Error during image processing: ${error.message}`);
    }
  }







  const handleKeyDown = (e) => {
    if (e.key == 'Enter') {
      setProgress(0)
      setLinearProgress(0)
      setUpScaleLoader(false)
      setFinalLoader(false)
      setCustomizeBtn(false)

      setUpScaled(false)
      setUpScaleText(false)
      setResult('')
      setAllDetails(false)
      let User = localStorage.getItem('user')
      User = JSON.parse(User)
      if (!upScaleLoader) {
        handleSubmitData()

      }

    }
  };


  const handleGoogleLogin = async () => {

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("User Info: ", user);


      authLogin(user?.reloadUserInfo, result)
      // Handle user info here (e.g., save to state, context, or redirect)
    } catch (error) {
      console.error("Error during Google login: ", error);
    }
  };

  const authLogin = async (sendData, result) => {
    console.log(sendData, 'data');
    try {
      let obj = {
        phone: '',
        google_id: sendData.localId,
        email: sendData.email,
        picture: sendData.photoUrl,
        name: sendData.displayName
      }
      const data = await AuthServices.authLogin(obj)
      console.log(data);
      if (data.responseCode == 200) {

        localStorage.setItem('authToken', JSON.stringify(data?.data?.token))
        localStorage.setItem('user', JSON.stringify(sendData))
        let userInfo = localStorage.getItem('user')
        setUserData(JSON.parse(userInfo))
        setUser(result.user)
        handleSubmitData()
      }



    } catch (error) {
      alert('New Registration is Closed')
    } finally {

    }
  }
  const handleConfetti = async (e) => {


  }

  const handleSubmitData = async (e) => {
    setRemovedBgPic('')
    setRemovedBgPic2('')
    setResult('')
    setComparison(false)
    if (e && e.type === 'keydown' && e.key === 'Enter') {
      return;
    }
    let User = localStorage.getItem('user')
    User = JSON.parse(User)
    if (User) {
      localStorage.removeItem('gallery')
      setAllDetails(false)
      // Check if the event is from a keyboard event and if the key is not 'Enter'
      setImagesGroup([])
      setLinearProgress(0)
      setUpScaleLoader(false)
      setFinalLoader(false)
      setCustomizeBtn(false)
      setShowLinearProgress(true)
      setUpScaled(false)
      setUpScaleText(false)
      setResult('')
      setAllDetails(false)
      localStorage.removeItem("designArray");

      setGenerateBtn(false)
      setGenerateText(true)

      const totalDuration = 10000; // 10 seconds in milliseconds
      const intervalDuration = 100; // update every 100 milliseconds
      const increments = 100 / (totalDuration / intervalDuration); // amount to increment each time

      const timer = setInterval(() => {
        setLinearProgress((oldProgress) => {
          if (oldProgress >= 100) {
            clearInterval(timer); // clear the interval when progress reaches 100%
            setShowLinearProgress(false);
            return 100; // ensure progress stays at 100%
          }
          return Math.min(oldProgress + increments, 100);
        });
      }, intervalDuration);
      setFinalLoader(true)
      setImgMaping(true)
      let value = getValues('prompt')

      const joinedNames = selectedChips.map(item => item.name).join(", ");
      console.log(joinedNames); // Output: "Futuristic, Fantasy"
      console.log(joinedNames, 'updatedValueupdatedValue');

      setLoader(true)
      if (getValues('prompt')) {
        const eventSource = new EventSource(`https://generator.printmeup.ai/generate?prompt=${magicPrompt == 'Yes' ? 'vector svg, white background,' + value + "," + joinedNames : value + "," + joinedNames}`);

        eventSource.onopen = () => {
          console.log('Connection to server opened.');

        };

        eventSource.onerror = (error) => {
          console.error('EventSource failed:', error);
          eventSource.close();

        };

        eventSource.onmessage = (event) => {
          let result = JSON.parse(event.data)
          console.log('Received message:', result);
          let url = result?.uri

          if (url) {
            setLoader(false)
            // setOpen(true)

            console.log(url, 'result');
            if (result.status === 'completed') {
              url += "&format=webp&quality=lossless&width=750&height=750"
              url = url.replace("cdn.discordapp.com", "media.discordapp.net")
            }
            setResult(url)

            splitImage(url)

              .catch(error => {
                console.error('Error splitting image:', error);
              });

          }
          // Assuming the server sends image URLs as plain text
          if (result.status === 'completed') {
            setProgress(result.progress, toString())
            setShowDowloadBtn(true)

            eventSource.close(); // Close the connection
            setGenerateBtn(true)
            setAllDetails(result.details)

            setUpScaleText(true)
            setFinalLoader(false)
            setGenerateText(false)

          }


        };

        return () => {
          eventSource.close();
        };
      }

      setTimeout(function () {
        setShowLinearProgress(false)


      }, 10000);
    }
    else {
      handleGoogleLogin()
    }


  }


  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * randomTexts.length);
      setCurrentMessage(randomTexts[randomIndex]);
    }, 5000);
    // console.log(currentMessage,'currentMessagecurrentMessage');

    return () => clearInterval(intervalId);

  }, [randomTexts]);
  useEffect(() => {
    if (state) {
      console.log(state, 'test');
      setValue('prompt', state)
    }
    else {

      setGenerateBtn(true)
      setFinalLoader(false)
      reset()
      setValue('prompt', '')
    }



  }, [])




  useEffect(() => {
    const targetElement = document.getElementById('comaparisonDiv')
    console.log(targetElement);

    // Create a canvas element
    if (targetElement) {

      const canvas = document.createElement('canvas');
      canvas.style.position = 'absolute';
      canvas.style.top = '0';
      canvas.style.left = '0';
      canvas.style.width = '100%';
      canvas.style.height = '100%';
      canvas.style.pointerEvents = 'none'; // Ensures clicks go through the canvas
      targetElement.appendChild(canvas);

      const myConfetti = confetti.create(canvas, {
        resize: true, // Will fit the canvas to the element size
        useWorker: true
      });

      const duration = 11 * 1000;
      const animationEnd = Date.now() + duration;
      let skew = 1;

      function randomInRange(min, max) {
        return Math.random() * (max - min) + min;
      }

      function frame() {
        const timeLeft = animationEnd - Date.now();
        const ticks = Math.max(200, 500 * (timeLeft / duration));

        skew = Math.max(0.8, skew - 0.001);

        myConfetti({
          particleCount: 1,
          startVelocity: 0,
          ticks: ticks,
          origin: {
            x: Math.random(),
            y: Math.random() * skew - 0.2,
          },

          shapes: ["star"],
          colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
          gravity: randomInRange(0.4, 0.6),
          scalar: randomInRange(0.4, 1),
          drift: randomInRange(-0.4, 0.4),
          disableForReducedMotion: true,
        });

        if (timeLeft > 0) {
          requestAnimationFrame(frame);
        }
      }

      requestAnimationFrame(frame);

      // Cleanup canvas on component unmount
      return () => {
        targetElement.removeChild(canvas);
      };
    }
  }, [comparison]);


  const handleKeyDown2 = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };


  return (
    <div>
      {/* conformation Dialog */}
      <Dialog
        open={confirmDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setConfirmDialog(false)}
        aria-describedby="alert-dialog-slide-description"

      >
        <Box sx={{ backgroundColor: '#0f172a', fontFamily: "Plus Jakarta Sans", color: 'white' }}>
          <DialogTitle sx={{ textAlign: 'center' }}>{"Let’s supercharge your amazing creation!"}</DialogTitle>
          {/* <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent> */}
          <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={() => {
              setScalingConfirmation(true)
              setConfirmDialog(false)
              handleScaling(1, true)
            }}>Yes</Button>
            <Button onClick={() => {
              setScalingConfirmation(true)
              setConfirmDialog(false)
            }}>No</Button>

          </DialogActions>
        </Box>
      </Dialog>

      <Box id='image-container1' crossOrigin='anonymous' component={'img'} src={result} sx={{ textAlign: 'center', mt: '%', mb: '5%', display: 'none' }}>



      </Box>


      <Grid id="home-section" container sx={{ backgroundColor: '#0F172A', gap: 2, justifyContent: 'space-between', pt: '8%' }}>
        <Grid item lg={2.5} display={{ lg: 'block', md: 'none', sm: 'none', xs: 'none' }} >
          <Box component={'img'} sx={{ textAlign: 'center !important' }} src={Images.Cartoon1} width={'240px'} height={'320px'} >

          </Box>
        </Grid>
        <Grid item lg={6.5} md={12} xs={12}>
          <Box sx={{ textAlign: 'center', mt: '20px' }} >
            <p style={{ color: Colors.textColor1, fontSize: '50px', fontWeight: '600' }}>
              Being <span className='banner-text' style={{ fontSize: '70px', fontWeight: 'bold' }} >Unique</span> <br />
              is better than being <br />
              <span className='banner-text' style={{ fontSize: '70px', color: Colors.textColor2, fontWeight: 'bold' }} >Perfect</span>

            </p>

          </Box>
          <Box >


            <p style={{ color: Colors.textColor1, fontSize: '17.5px', padding: 4, textAlign: 'center' }}>
              Stand Out with Limitless AI-Generated Custom Shirt Designs, Printed and Delivered for any Occasion!

            </p>

          </Box>
          {/* <Box component={'form'} onSubmit={handleSubmit((e) => handleSubmitData(e))} onKeyDown={handleKeyDown2}>
            <Box
              sx={{ mt: 2, p: 2 }}
              ref={inputRef}
              component={'div'}
              className="draggable-text-input"
              draggable="true"

              onPointerDown={handlePointerDown}
              onPointerMove={handlePointerMove}
              onPointerUp={handlePointerUp}
            >

              <TextField
                id="input-with-icon-textfield"
                {...register('prompt', { required: true })}
                onKeyDown={handleKeyDown}
                placeholder='Enter Your Prompt'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box component={'div'} onClick={() => HandlePrompt()}>
                        <TipsAndUpdatesIcon sx={{ color: Colors.white, cursor: 'pointer' }} />
                      </Box>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiInputBase-input': {
                    color: Colors.white,
                    textAlign: 'left',
                    fontSize: '20px',
                    outline: 'none !important'
                  },
                  '& .MuiOutlinedInput-root': {
                    border: `3px solid #0EA5EA`, // Set your color here
                    borderRadius: '30px',
                    outline: 'none !important',
                  }
                }}
                size='small'
                fullWidth
                multiline  // Enable multiline for automatic height adjustment
              // Set initial rows (can be adjusted as needed)
              />
            </Box>

            <Grid container xs={12} display={'flex'} justifyContent={'center'}>
              {generateBtn && <SecondaryButton iconPosition={'start'} className={'generate-btn'} type={'submit'} p={"10px 20px"} fontWeight={'bold'} icon={<AutoFixHighIcon />} color={'#0F172A'} title={'Generate '} />}
            </Grid>

          </Box> */}
          {/* <div style={{ margin: '0 auto' }}>
      <h2>Image Comparison</h2>
     
    </div> */}
        </Grid>

        <Grid item lg={2.5} display={{ lg: 'block', md: 'none', sm: 'none', xs: 'none' }} >
          <Box component={'img'} src={Images.Cartoon2} width={'240px'} height={'320px'} >

          </Box>
        </Grid>

      </Grid>
      <Grid container justifyContent={'center'} mt={2} >
        <Grid component={'div'} item lg={11} md={11} xs={11} onClick={() => setPromptHeight(true)} sx={{ height: promptHeight ? '100%' : '50px', border: "1px solid #0EA5EA", borderRadius: '20px' }} >

          <Grid container component={'form'} onSubmit={handleSubmit((e) => handleSubmitData(e))} onKeyDown={handleKeyDown2}>
            <Grid item lg={8.5} md={12} sm={12} xs={12} >
              <Box >
                <Box
                  sx={{ mt: 2, p: 2, display: 'flex !important', alignItems: 'flex-start !important' }}
                  ref={inputRef}
                  component={'div'}
                  className="draggable-text-input"
                  draggable="true"

                  onPointerDown={handlePointerDown}
                  onPointerMove={handlePointerMove}
                  onPointerUp={handlePointerUp}
                >

                  <TextField
                    id="input-with-icon-textfield"
                    {...register('prompt', { required: true })}
                    onKeyDown={handleKeyDown}
                    placeholder='Transform Words Into Wearable Arts'
                    sx={{
                      '& .MuiInputBase-input': {
                        color: Colors.white,
                        textAlign: 'left',
                        fontSize: { lg: '20px', md: '20px', sm: '18px', xs: '16px' },
                        outline: 'none !important',
                        border: 'none !important',
                        width: { lg: '100%', md: '100%', sm: '100%', xs: '100%' },
                        WebkitTextSizeAdjust: '100%', // Prevent zoom on iOS
                      },
                      '& .MuiOutlinedInput-root': {
                        border: `3px solid #0EA5EA`,
                        borderRadius: '30px',
                        outline: 'none !important',
                        border: 'none !important',
                      },
                      '& fieldset': {
                        border: 'none !important',
                      },
                    }}
                    size='small'
                    fullWidth
                    multiline
                    rows={promptHeight ? 7 : 0}
                  />

                </Box>



              </Box>
              <Box sx={{ paddingTop: '20px', paddingBottom: '10px' }}>
                {promptHeight && suggestedPromptArray.map((item) => (
                  <Chip
                    key={item.id}
                    label={item.name}
                    sx={{
                      backgroundColor: selectedChips.find(chip => chip?.id === item?.id) ? '#0EA5EA' : 'transparent',
                      border: '1px solid #0EA5EA',
                      color: 'white',
                      fontFamily: 'Poppins, sans-serif',
                      m: 1,
                      mb: 0,
                      '&:hover': {
                        backgroundColor: selectedChips.find(chip => chip?.id === item?.id) ? '#0EA5EA' : 'transparent', // Change color on hover
                        color: 'white' // Optionally, you can change the text color as well
                      }
                    }}
                    onClick={() => handleClick(item)}
                  />
                ))}
              </Box>

            </Grid>
            <Grid item lg={3.5} md={12} sx={{
              borderLeft: promptHeight && {
                lg: '1px solid #0EA5EA',  // Apply border at the 'lg' breakpoint and up
              }
            }} >
              {promptHeight && <Grid container spacing={2} style={{ backgroundColor: 'transparent', padding: '20px', borderRadius: '8px' }}>
                <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={2}>
                  <Typography variant="h6" style={{ color: '#ffffff', fontSize: '15px' }}>Spark Creativity</Typography>
                  <Box component={'div'} onClick={() => HandlePrompt()}>
                    <IconButton>
                      <TipsAndUpdatesIcon sx={{ color: Colors.white, cursor: 'pointer' }} />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={2}>
                  <Typography variant="h6" style={{ color: '#ffffff', fontSize: '15px' }}>Transparent Background</Typography>
                  <ToggleButtonGroup
                    value={magicPrompt}
                    exclusive
                    onChange={handleMagicPromptChange}
                    aria-label="Magic Prompt"
                    color="primary"
                  >
                    <ToggleButton sx={{ textTransform: 'capitalize', backgroundColor: magicPrompt == 'Yes' ? '#0EA5EA !important' : "#27272a", color: 'white !important', padding: '10px 20px 10px 20px' }} value="Yes">Yes</ToggleButton>
                    <ToggleButton sx={{ textTransform: 'capitalize', backgroundColor: magicPrompt == 'No' ? '#0EA5EA !important' : "#27272a", color: 'white !important', padding: '10px 20px 10px 20px' }} value="No">No</ToggleButton>

                  </ToggleButtonGroup>
                </Grid>



                <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={2}>
                  <Typography variant="h6" style={{ color: '#ffffff', fontSize: '15px' }}>Visibility</Typography>
                  <ToggleButtonGroup
                    value={visibility}
                    exclusive
                    onChange={handleVisibilityChange}
                    aria-label="Visibility"
                    color="primary"
                  >
                    <ToggleButton sx={{ textTransform: 'capitalize', backgroundColor: visibility == 'Public' ? '#0EA5EA !important' : "#27272a", color: 'white !important', padding: '10px 20px 10px 20px' }} value="Public">Public</ToggleButton>
                    <ToggleButton sx={{ textTransform: 'capitalize', backgroundColor: visibility == 'Private' ? '#0EA5EA !important' : "#27272a", color: 'white !important', padding: '10px 20px 10px 20px' }} value="Private">Private</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>}
              {promptHeight && <Grid container component={'div'} id='img-section' xs={12} display={'flex'} justifyContent={'center'} alignItems={'flex-end'} pb={2}>
                {generateBtn && <SecondaryButton iconPosition={'start'} className={'generate-btn generate-btn2'} type={'submit'} p={"10px 20px"} fontWeight={'bold'} icon={<AutoFixHighIcon />} color={'#0F172A'} title={'Generate '} />}
              </Grid>}
            </Grid>
          </Grid>
        </Grid>

      </Grid>

      {showlinearProgress && <Box sx={{ width: '50%', margin: '0 auto', mt: '2%' }}>
        <p style={{ color: 'white', fontFamily: "Plus Jakarta Sans", textAlign: 'right', mb: '5px' }}> {parseFloat(linearProgress).toFixed(0)}%</p>
        <LinearProgress variant="determinate" sx={{
          height: '15px',
          borderRadius: '20px',
          '& .MuiLinearProgress-root': {
            backgroundColor: 'lightgray', // Color of the track
          }
        }} value={linearProgress} />
      </Box>}
      <Grid container gap={2} justifyContent={'center'} sx={{ margin: '0 auto', marginTop: '30px' }} >

        <Grid container justifyContent={'center'} >
          {progress != '100%' && generateText && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p style={{ fontSize: '20px', color: 'white' }}>Generating </p> &nbsp; <PulseLoader style={{ marginTop: '7%' }} color="#36d7b7" /></Box>}
          {finalLoader && <> <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', margin: '0 auto', marginTop: '2%' }}> <Box> <p className='custom-small-text' style={{ fontSize: "22px", color: 'white', padding: '15px', textAlign: 'justify' }}>{currentMessage}</p> </Box> <br />

          </Box>


          </>}
          <Box component={'div'} sx={{ color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>




            {upScaleText && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '5%', mb: '4%' }}><p className='banner-text-small2' style={{ fontSize: '15px !important', fontFamily: "Plus Jakarta Sans", fontWeight: 'bold', textAlign: 'center' }}>Select an image to reveal its full charm</p> </Box>}
            {upScalingLoader && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '5%', mb: '4ppro%' }}><p style={{ fontSize: '20px' }}>UpScaling </p> <PulseLoader style={{ marginTop: '4%' }} color="#36d7b7" /></Box>}
            {result && !showlinearProgress ? <Box sx={{ position: 'relative' }}> <Grid container sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }} spacing={2} >

              {!upScaleLoader && !upScaled && imagesGroup.length > 0 && imagesGroup.map((image, index) => (
                <Grid
                  item
                  component={'div'}
                  display={'flex'}
                  justifyContent={'center'}
                  sx={{ position: 'relative' }}
                  xs={8}
                  md={5}
                  lg={3}

                  onContextMenu={(e) => e.preventDefault()} // Prevent the right-click context menu
                >

                  <a style={{ position: 'relative' }} >
                    {allDetails && showDowloadBtn && (
                      <Box

                        onClick={allDetails ? () => downloadImageWithLogo(image, Images.mainlogo, filename) : undefined}
                        sx={{ position: 'absolute', top: 25, right: 10, backgroundColor: 'black', opacity: 0.6, borderRadius: '12px', p: 0.5 }}
                      >
                        <CloudDownloadOutlinedIcon sx={{ fontSize: '35px', color: '#f2f2f2', cursor: 'pointer', fontWeight: '200' }} />
                      </Box>
                    )}
                    {allDetails && showDowloadBtn && (
                      <Box

                        sx={{ position: 'absolute', bottom: 25, right: 20, backgroundColor: 'black', opacity: 0.6, borderRadius: '10px', p: 0.5 }}
                      >
                        <Box component={'img'} sx={{ width: '100px' }} src={Images.mainlogo}></Box>
                      </Box>
                    )}
                    <Box
                      sx={{ textAlign: 'center', cursor: 'pointer' }}
                      onClick={allDetails ? () => {
                        setConfirmDialog(true)
                        setSelectScaleIndex(index + 1)
                      } : undefined}
                      component={'img'}
                      width={{ lg: '300px', md: '380px', sm: '420px', xs: "320px" }}
                      height={{ lg: '350px', md: '350px', xs: '350px' }}
                      src={image}
                    ></Box>
                  </a>
                </Grid>
              ))}

              {upScaleLoader ? <Box sx={{ mt: '180px', mb: '200px' }}> <CircularProgress /> </Box> : upScaled && <Box sx={{ position: 'relative' }}>
                {/* {allDetails && showDowloadBtn && <Box onClick={allDetails ? () => downloadImageWithLogo(result, Images.mainlogo, filename) : ''} sx={{ position: 'absolute', top: 25, right: 10, backgroundColor: 'black', opacity: 0.6, borderRadius: '12px', p: 0.5 }}>
                  <CloudDownloadOutlinedIcon sx={{ fontSize: '35px', color: '#f2f2f2', cursor: 'pointer', fontWeight: '200' }} />
                </Box>} */}
                {/* {allDetails && showDowloadBtn && <Box sx={{ position: 'absolute', bottom: 25, right: 20, backgroundColor: 'black', opacity: 0.6, borderRadius: '10px', p: 0.5 }}>
                  <Box component={'img'} sx={{ width: '100px' }} src={Images.mainlogo}></Box>
                </Box>} */}
                {console.log(result)}
                {console.log(removedBgPic)}
                {!removedBgPic &&
                  <Box sx={{ display: 'flex !important', justifyContent: 'center', alignItems: 'center', mt: '5%', mb: '10%' }}><p className='banner-text-small' style={{ fontSize: '10px !important', fontFamily: "Plus Jakarta Sans", fontWeight: 'bold', }}>{magicPrompt == 'Yes' || (removedBgPic || removedBgPic2) ? "  AI is doing its Magic!" : ''} </p> </Box>}

                {comparison ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {(removedBgPic || removedBgPic2) &&

                      <ButtonGroup sx={{ width: { lg: '420px', md: '350px', sm: '350px', xs: '300px' } }} variant="contained" aria-label="Basic button group">
                        <Button
                          sx={{ fontSize: '10px', backgroundColor: '#0EA5EA', color: '#0F172A', width: '50%', fontWeight: 'bold' }}
                          onClick={() => {
                            navigate(
                              `/product-final`,
                              { state: result }
                            )
                            addLibrary(result)
                          }}
                        >
                          Select with  backdrop
                        </Button>
                        <Button
                          sx={{ fontSize: '10px', backgroundColor: '#0EA5EA', color: '#0F172A', width: '50%', fontWeight: 'bold' }}
                          onClick={() => {
                            navigate(
                              `/product-final`,
                              { state: switchState ? removedBgPic2 : removedBgPic }
                            )
                            addLibrary(switchState ? removedBgPic2 : removedBgPic)
                          }}
                        >
                          Select without backdrop
                        </Button>
                      </ButtonGroup>}
                    <Box
                      component={'div'}
                      id="comaparisonDiv"
                      width={{ lg: '420px', md: '350px', sm: '350px', xs: '300px' }}
                      height={{ lg: '400px', md: '350px', sm: '350px', xs: '300px' }}
                    >
                      <ReactCompareImage
                        leftImage={result || Images.defaultPic}
                        rightImage={switchState ? removedBgPic2 : removedBgPic || Images.defaultPic}
                        sliderLineColor="#0EA5EA"
                        sliderLineWidth={4}
                        handleSize={40}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                      {!switchState && (removedBgPic || removedBgPic2) && <Box component={'div'} onClick={() => {
                        setSwitchState(true)
                        removeBgV2(result)
                      }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 5, mb: '10%' }}><p style={{ fontSize: '10px !important', fontFamily: "Plus Jakarta Sans", fontWeight: 'bold', cursor: 'pointer', textDecoration: 'underline' }}>Not Satisfied With Result? Try Alternative Version  </p> </Box>}
                      {switchState && <Box component={'div'} onClick={() => { setSwitchState(false) }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 5, mb: '10%' }}><p style={{ fontSize: '10px !important', fontFamily: "Plus Jakarta Sans", fontWeight: 'bold', cursor: 'pointer', textDecoration: 'underline' }}>Go Back To Previous One  </p> </Box>}
                    </Box>

                  </Box>
                ) : (
                  <Box
                    component={'img'}
                    src={result}
                    width={{ lg: '420px', md: '350px', sm: '350px', xs: '300px' }}
                    height={{ lg: '400px', md: '350px', sm: '350px', xs: '300px' }}
                    onContextMenu={(e) => e.preventDefault()}
                  />
                )}

              </Box>}


            </Grid>

            </Box> : finalLoader && !showlinearProgress ? <Grid container xs={12} justifyContent={'center'} sx={{ margin: '0 auto', marginTop: '0px', padding: '20px' }}>


              <Grid container justifyContent={'center'} spacing={0.5}>
                <Grid item xs={8} lg={3} md={5} display={'flex'} justifyContent={"center"}>
                  <Skeleton variant="rectangular" width={420} height={350} sx={{ bgcolor: 'grey.700' }} />
                </Grid>
                <Grid item xs={8} lg={3} md={5} display={'flex'} justifyContent={"center"}>
                  <Skeleton variant="rectangular" width={420} height={350} sx={{ bgcolor: 'grey.700' }} />
                </Grid>
                <Grid item xs={8} lg={3} md={5} display={'flex'} justifyContent={"center"}>
                  <Skeleton variant="rectangular" width={420} height={350} sx={{ bgcolor: 'grey.700' }} />
                </Grid>
                <Grid item xs={8} lg={3} md={5} display={'flex'} justifyContent={"center"}>
                  <Skeleton variant="rectangular" width={420} height={350} sx={{ bgcolor: 'grey.700' }} />
                </Grid>
              </Grid>

            </Grid> : ''}


            {!removedBgPic && magicPrompt != 'Yes' && customizeBtn && <Box mt={4}>

              <SecondaryButton onClick={() => {
                navigate(
                  `/product-final`,
                  { state: result }



                )
                addLibrary(result)
                localStorage.setItem('gallery', JSON.stringify([{ imageUrl: result, width: 100, height: 100, rotation: 0, top: 20, left: 20, borderRadius: 10 }]))
              }} p={"15px 30px"} fontWeight={'bold'} icon={<EastIcon />} color={'#0F172A'} title={'Place This On  Tshirt'} />
            </Box>}
          </Box>
        </Grid>
      </Grid>
      <Box
        component={"section"}
        sx={{
          p: { md: "60px", sm: 0, xs: 0 },

          mt: '5%'
        }}
      >
        <Container>
          <Grid container justifyContent={"center"} spacing={{ md: 0, sm: 2, xs: 2 }}>
            <Grid item md={6}>
              <Box
                sx={{

                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: { md: "flex-start", sm: "center", xs: "center" },
                  gap: "20px",
                  pr: { md: 3, sm: 0, xs: 0 },

                }}
              >
                <Typography
                  className={"banner-text"}
                  sx={{
                    fontSize: "40px !important",
                    fontWeight: "600 !important",


                  }}
                >
                  T-shirt printing
                </Typography>
                <Typography
                  sx={{
                    fontSize: "40px",
                    color: Colors.textColor1,
                    lineHeight: 0
                  }}
                >
                  made easy.
                </Typography>
                <Box>
                  <Typography
                    sx={{
                      color: Colors.textColor1,
                      mt: 2,
                      textAlign: { xs: 'center', sm: 'center', md: 'left' },
                    }}
                  >
                    Once you have generated an image use our design tool to select the t-shirt color and place the design. As soon as you place an order it is sent to a printer
                  </Typography>
                </Box>

                <div>
                  <Accordion
                    sx={{ backgroundColor: 'transparent' }}
                    expanded={expanded['panel1']}
                    onChange={handleChange('panel1')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: Colors.secondary }} />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      sx={{ borderBottom: `1px solid ${Colors.secondary}` }}
                    >
                      <Typography
                        sx={{
                          color: Colors.secondary,
                          fontWeight: 600,
                          fontSize: '20px'
                        }}
                      >
                        Premium quality custom t-shirts
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ boxShadow: 'none' }}>
                      <Typography sx={{ color: 'white', boxShadow: 'none' }}>
                        We use 180-190 GSM t-shirts that have been rigorously tested to withstand multiple washes, ensuring durability for everyday use. Our shirts are tested with standard washing liquids and various drying techniques to guarantee quality.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    sx={{ backgroundColor: 'transparent' }}
                    expanded={expanded['panel2']}
                    onChange={handleChange('panel2')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: Colors.secondary }} />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                      sx={{ borderBottom: `1px solid ${Colors.secondary}` }}
                    >
                      <Typography
                        sx={{
                          color: Colors.secondary,
                          fontWeight: 600,
                          fontSize: '20px'
                        }}
                      >
                        Easy to create & customize
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ color: 'white' }}>
                        After generating an image, click on "Customize Your T-Shirt" to select your preferred size, and then re-size and position the image. We'll take care of the rest to ensure your design is accurately placed and sized. Refer to our t-shirt size chart to find the perfect fit for your needs.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    sx={{ backgroundColor: 'transparent' }}
                    expanded={expanded['panel3']}
                    onChange={handleChange('panel3')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: Colors.secondary }} />}
                      aria-controls="panel3bh-content"
                      id="panel3bh-header"
                      sx={{ borderBottom: `1px solid ${Colors.secondary}` }}
                    >
                      <Typography
                        sx={{
                          color: Colors.secondary,
                          fontWeight: 600,
                          fontSize: '20px'
                        }}
                      >
                        Standard shipping
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ color: 'white' }}>
                        Our shipping charges are AED 15 for up to 5 t-shirts to a single location in the UAE. Additional t-shirts will incur extra shipping charges. For orders of 25 or more t-shirts to a single location within Dubai, Sharjah, and Ajman, the shipping fee is a fixed AED 50, for other Emirates, the fixed charge is AED 100.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>

              </Box>
            </Grid>
            <Grid item md={5} xs={12}>
              <Grid item md={12} sm={12} xs={12}>
                <Box>
                  <div >
                    <Slider autoplay={true}  {...settings}>
                      {sliderData.map((item, ind) => (

                        <Box>
                          <CardMedia
                            component={"img"}
                            src={item.img}
                            sx={{
                              width: "100%",
                              objectFit: "contain"
                            }}
                          />
                        </Box>

                      ))}

                    </Slider>
                  </div>

                </Box>
                {/* <Swiper
                  loop={true}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  spaceBetween={10}
                  slidesPerView={1}
                  breakpoints={{
                    320: {
                      slidesPerView: 1
                    },
                    786: {
                      slidesPerView: 1
                    },
                    1080: {
                      slidesPerView: 1
                    }
                  }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  {sliderData.map((item, ind) => (
                    <SwiperSlide key={ind}>
                      <Box>
                        <CardMedia
                          component={"img"}
                          src={item.img}
                          sx={{
                            width: "100%",
                            objectFit: "contain"
                          }}
                        />
                      </Box>
                    </SwiperSlide>
                  ))}
                </Swiper> */}
              </Grid>

            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        component={"section"}
        sx={{
          p: { md: "60px", sm: 0, xs: 0 },
          mt: '5%'
        }}
      >
        <Container>
          <Grid container justifyContent={"center"} rowGap={"20px"}>
            <Grid item md={10} sm={11}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "44px",
                    color: Colors.textColor1,
                    textAlign: "center"
                  }}
                >
                  Why customize{" "}
                  <Typography component={"span"}
                    sx={{
                      fontSize: "44px",
                    }}
                    className={"banner-text text-small"}
                  >
                    Products
                  </Typography>
                  {" "}with AI Shirt?
                </Typography>
              </Box>
            </Grid>
            <Grid item md={7}>
              <Box sx={{ textAlign: "center" }}>
                <Typography variant='body2' sx={{ color: Colors.textColor1 }}>
                  Express your individuality, create memorable gifts, or promote your brand effortlessly. Just input your design prompt, and our AI will generate a variety of options. Choose your favorite design, and we'll print it on a premium cotton t-shirt, delivered right to your doorstep
                </Typography>
              </Box>
            </Grid>
            <Grid item md={9}>
              <Grid container spacing={2}>
                {productBoxes.map((item, ind) => (
                  <Grid key={ind} item lg={4} md={6} sm={6} xs={12}>

                    <Box
                      sx={{
                        background: Colors.secondary,
                        borderRadius: "15px",
                        p: 3,
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                        height: '290px'

                      }}
                    >
                      <Box >
                        {item.icon}
                      </Box>
                      <Typography
                        variant={"body1"}
                        sx={{
                          fontWeight: 600,
                          color: Colors.backgroundColor1
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant={"body2"}
                        sx={{
                          fontFamily: "Plus Jakarta Sans",
                          color: Colors.backgroundColor1
                        }}
                      >
                        {item.description}
                      </Typography>
                    </Box>

                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <Container >
          <Grid ref={section1Ref} id="pricing-section" container justifyContent={"center"} rowGap={"20px"} mt={10}>
            <Grid item md={10} sm={11}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "44px",
                    color: Colors.textColor1,
                    textAlign: "center"
                  }}
                >

                  <Typography component={"span"}
                    sx={{
                      fontSize: "44px",
                    }}
                    className={"banner-text text-small"}
                  >
                    Pricing
                  </Typography>

                </Typography>
              </Box>
            </Grid>

            <Grid item md={12}>
              <Grid container spacing={2} justifyContent={'center'}>

                <Grid item md={7} sm={12} xs={12}>

                  <Box
                    sx={{
                      background: Colors.secondary,
                      borderRadius: "15px",
                      p: 3,
                      display: "flex",
                      flexDirection: "column",
                      gap: "24px",


                    }}
                  >
                    <UnorderedListStyled>

                      <ListItemStyled key={index}>
                        <ListItemText sx={{ fontSize: "20px" }}>

                          Single standard t-shirt of any size for a single image on one side - <span style={{ fontWeight: 'bold' }}>AED 40</span>
                        </ListItemText>

                      </ListItemStyled >
                      <ListItemStyled key={index}>
                        <ListItemText sx={{ fontSize: "20px" }}>

                          Double side print  –  <span style={{ fontWeight: 'bold' }}>AED 50</span>
                        </ListItemText>

                      </ListItemStyled >
                      <ListItemStyled key={index}>
                        <ListItemText sx={{ fontSize: "20px" }}>

                          Additional images on any side –  <span style={{ fontWeight: 'bold' }}>AED 10 per image </span>
                        </ListItemText>

                      </ListItemStyled >

                      <ListItemStyled key={index}>
                        <ListItemText sx={{ fontSize: "20px" }}>

                          Delivery charges in any Emirate for maximum of 5 shirts –   <span style={{ fontWeight: 'bold' }}>AED 15 </span>
                        </ListItemText>

                      </ListItemStyled >
                      <ListItemStyled key={index}>
                        <ListItemText sx={{ fontSize: "20px" }}>
                          <span style={{ fontWeight: 'bold' }}>VAT to be charged based on regulatory requirements</span>
                        </ListItemText>

                      </ListItemStyled >

                    </UnorderedListStyled>



                  </Box>

                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Container >
          <Grid container justifyContent={"center"} rowGap={"20px"} mt={20}>
            <Grid id="how-section" item md={10} sm={11}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "44px",
                    color: Colors.textColor1,
                    textAlign: "center"
                  }}
                >

                  <Typography component={"span"}
                    sx={{
                      fontSize: "44px",
                    }}
                    className={"banner-text text-small"}
                  >
                    How it Works?
                  </Typography>

                </Typography>
              </Box>
            </Grid>

            <Grid item md={12}>
              <Grid container spacing={2} justifyContent={'center'}>

                <Grid item sx={{ display: "flex", justifyContent: 'center' }} lg={10} md={7} sm={11} xs={11} >

                  <YouTube videoId={'Z-LZFybw2AM'} opts={opts} />


                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        component={"section"}
        sx={{
          p: { md: "60px", sm: 0, xs: 0 },
          mt: '5%', pb: '5%'
        }}
      >
        <Container>
          <Grid container justifyContent={"center"} rowGap={"20px"}>
            < Grid item md={10} sm={11}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "44px",
                    color: Colors.textColor1,
                    textAlign: "center"
                  }}
                >
                  What {" "}
                  <Typography component={"span"}
                    sx={{
                      fontSize: "44px",
                    }}
                    className={"banner-text text-small"}
                  >
                    People
                  </Typography>
                  {" "}Are Saying
                </Typography>
              </Box>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Swiper
                spaceBetween={10}
                slidesPerView={3}
                className='my-swiper'
                breakpoints={{
                  320: {
                    slidesPerView: 1
                  },
                  786: {
                    slidesPerView: 2
                  },
                  1080: {
                    slidesPerView: 3
                  }
                }}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {reviewBoxes.map((item, ind) => (
                  <SwiperSlide key={ind}>
                    <Box
                      sx={{
                        p: 4,
                        borderRadius: "15px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-end",
                          gap: "12px"
                        }}
                      >
                        <Avatar
                          sx={{
                            width: 64,
                            height: 64
                          }}
                          src={item.avatar}
                          alt={item?.name}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column"
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Plus Jakarta Sans",
                              display: 'flex',
                              alignItems: "center",
                              gap: "8px",
                              fontWeight: 600
                            }}
                          >
                            {item.name}
                            <Typography
                              variant='body2'
                              sx={{ fontWeight: 400 }}
                            >
                              {item.designation}
                            </Typography>
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                            }}
                          >
                            <Star sx={{ width: "16px", height: "16px", color: "#FCB61A" }} />
                            <Star sx={{ width: "16px", height: "16px", color: "#FCB61A" }} />
                            <Star sx={{ width: "16px", height: "16px", color: "#FCB61A" }} />
                            <Star sx={{ width: "16px", height: "16px", color: "#FCB61A" }} />
                          </Box>
                        </Box>
                      </Box>
                      <Typography
                        variant={"body2"}
                        sx={{
                          fontFamily: "Plus Jakarta Sans",
                        }}
                      >
                        {item.comments}
                      </Typography>
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  )
}

export default Home