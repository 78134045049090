

// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB67Fl2H5mOR0P0K06687a7XeJNEeAzH0g",
    authDomain: "printmeup-289db.firebaseapp.com",
    projectId: "printmeup-289db",
    storageBucket: "printmeup-289db.appspot.com",
    messagingSenderId: "971283622795",
    appId: "1:971283622795:web:0c5bb299f9d438cba8dd8e"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider, signInWithPopup };
