import { Avatar, Box, Chip, Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import background from '../../../assests/images/mayaccountbg.png'
import EditIcon from '@mui/icons-material/Edit';
import { Images } from '../../../assests';
import OrderServices from '../../../services/OrderServices';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FaTshirt } from "react-icons/fa";
import { FaClipboardList } from "react-icons/fa";
export default function MyAccount() {
    const { state } = useLocation()
    const [selectedTab, setSelectedTab] = useState(0)
    const [userData, setUserData] = useState()
    const [orders, setOrders] = useState([])
    const [totalQuantity, setTotalQuantity] = useState(0)
    const navigate = useNavigate();

    const [libraryImages, setLibraryImages] = useState([])
    const Tabs = [{ id: 0, name: 'My Account', icon: <PersonIcon /> }, { id: 1, name: 'My Library', icon: <SaveAltIcon /> }, { id: 2, name: 'Addresses', icon: <LocationOnOutlinedIcon /> }, { id: 3, name: 'My Orders', icon: <FaClipboardList style={{ fontSize: '20px' }} /> }, { id: 4, name: 'Log out', icon: <LogoutIcon /> }]


    const [isLessThan1200, setIsLessThan1200] = useState(window.innerWidth < 1000);

    const handleLogout = async () => {
        try {
            localStorage.clear()
            window.location.href = '/'


            console.log("User signed out");
        } catch (error) {
            console.error("Error during sign out: ", error);
        }
    };

    const userProfile = async (sendData) => {
        console.log(sendData, 'data');
        try {
            let params = {

            }
            const { data } = await OrderServices.userProfile(params)

            setUserData(data?.details)

        } catch (error) {
            console.log(error);
        } finally {

        }
    }
    const getLibrary = async (sendData) => {
        console.log(sendData, 'data');
        try {
            let params = {

            }
            const { data } = await OrderServices.getLibrary(params)
            console.log(data);
            setLibraryImages(data?.library)

        } catch (error) {
            console.log(error);
        } finally {

        }
    }
    const getOrderList = async () => {
        try {
            let params = {

            }
            const { data } = await OrderServices.getOrderList(params)
            console.log(data);
            setOrders(data?.orders)

        } catch (error) {
            console.log(error);
        } finally {

        }
    }
    useEffect(() => {
        const handleResize = () => {
            setIsLessThan1200(window.innerWidth < 1000);
            console.log(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);
    useEffect(() => {
        userProfile()
        getLibrary()
        getOrderList()
        if (state) {
            setSelectedTab(3)
        }
    }, [])

    return (
        <Fragment  >
            <Box
                sx={{
                    backgroundImage: `url("${background}")`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: '300px', // Adjust height as needed
                    display: 'flex',
                    alignItems: 'center', justifyContent: 'center'
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography
                        className="banner-text2"
                        sx={{
                            fontSize: { lg: '35px', md: '25px', sm: '20px' },
                            textAlign: 'center',
                            fontFamily: 'Noto Sans, sans-serif',
                        }}
                    >
                        My Account
                    </Typography>
                    <p style={{ fontSize: '25px', textAlign: 'center', color: 'white', fontFamily: "Noto Sans, sans-serif", }}>Homepage || My Account</p>
                </Box>
            </Box>
            {isLessThan1200 && <Box sx={{ display: 'flex', width: "80%", margin: '0 auto' }} >
                {Tabs.map((item, index) => (
                    <Box
                        component={'div'}
                        onClick={() => {
                            if (item.id === 4) {
                                console.log('han');
                                handleLogout();
                                setSelectedTab(item.id);
                            } else {
                                console.log('na');
                                setSelectedTab(item.id);
                            }
                        }}
                        key={index}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',

                            width: '100%',
                            color: selectedTab == item.id ? 'white' : '#a1acb1',
                            marginTop: '10%',
                            cursor: 'pointer',
                            paddingBottom: '14px',
                            fontFamily: "Noto Sans, sans-serif",
                            fontSize: '13px'

                        }}

                    >

                        <Box sx={{
                            border: selectedTab == item.id ? '1px solid #a1acb1' : '',
                            borderRadius: '50%',
                            width: '50px',
                            height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                        }}>{item.icon}</Box>
                    </Box>
                ))}
            </Box>}
            <Grid container sx={{ paddingTop: '2%', paddingBottom: '5%' }} justifyContent={'center'} gap={5}>
                {!isLessThan1200 && <Grid item md={3} lg={3}   >
                    {Tabs.map((item, index) => (
                        <Box
                            component={'div'}
                            onClick={() => {
                                if (item.id === 4) {
                                    console.log('han');
                                    handleLogout();
                                    setSelectedTab(item.id);
                                } else {
                                    console.log('na');
                                    setSelectedTab(item.id);
                                }
                            }}
                            key={index}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                borderBottom: "1px solid #a1acb1",
                                width: '100%',
                                color: selectedTab == item.id ? 'white' : '#a1acb1',
                                marginTop: '10%',
                                cursor: 'pointer',
                                paddingBottom: '14px',
                                fontFamily: "Noto Sans, sans-serif",
                                fontSize: '13px'

                            }}

                        >
                            <Box>{item.name}</Box>
                            <Box>{item.icon}</Box>
                        </Box>
                    ))}
                </Grid>}
                <Grid item sm={12} md={5} lg={5} sx={{ display: 'flex', justifyContent: 'center', mt: '5%', width: '100%', p: '10px' }} >
                    {selectedTab == 0 && <Box sx={{ width: '100%', margin: '0 auto' }}>

                        <Box sx={{ mt: '5%', display: 'flex', justifyContent: 'space-around', textAlign: 'left' }}>
                            <Box sx={{ width: '50%' }}>
                                <p style={{ fontFamily: "Noto Sans, sans-serif", color: '#a1acb1' }}>Profile Picture:</p></Box>
                            <Box sx={{ width: '50%' }}>
                                <Avatar sx={{ mt: '2%' }} src={userData?.picture} />
                            </Box>
                        </Box>
                        <Box sx={{ mt: '5%', display: 'flex', justifyContent: 'space-around', textAlign: 'left' }}>
                            <Box sx={{ width: '50%' }}>
                                <p style={{ fontFamily: "Noto Sans, sans-serif", color: '#a1acb1' }}>Name:</p></Box>
                            <Box sx={{ width: '50%' }}>
                                <p style={{ fontFamily: "Noto Sans, sans-serif", color: 'white', fontSize: '0.8rem' }}>{userData?.name}</p>
                            </Box>
                        </Box>
                        <Box sx={{ mt: '5%', display: 'flex', justifyContent: 'space-around', textAlign: 'left' }}>
                            <Box sx={{ width: '50%' }}>
                                <p style={{ fontFamily: "Noto Sans, sans-serif", color: '#a1acb1' }}>Email Address:</p></Box>
                            <Box sx={{ width: '50%' }}>
                                <Typography style={{ fontFamily: "Noto Sans, sans-serif", color: 'white', fontSize: '0.8rem' }}>{userData?.email}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ mt: '5%', display: 'flex', justifyContent: 'space-around', textAlign: 'left' }}>
                            <Box sx={{ width: '50%' }}>
                                <p style={{ fontFamily: "Noto Sans, sans-serif", color: '#a1acb1' }}>Phone Number:</p></Box>
                            <Box sx={{ width: '50%' }}>
                                <p style={{ fontFamily: "Noto Sans, sans-serif", color: 'white', fontSize: '0.8rem' }}>{userData?.phone}</p>
                            </Box>
                        </Box>



                    </Box>}
                    {selectedTab == 1 && <Box sx={{ width: '90%', margin: '0 auto' }}>
                        <Grid container justifyContent={'space-between'}>
                            {libraryImages.length > 0 && libraryImages?.map((item, index) => (
                                <Grid key={index} onClick={() => navigate(
                                    `/gallery`,
                                    { state: item }
                                )} item xs={5} sx={{ mt: '5%', cursor: 'pointer' }}>
                                    <Box sx={{ position: 'relative' }}>
                                        <Box component={'img'}
                                            width={'100%'}
                                            src={item.url}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = '';
                                                if (e.target.parentNode) {
                                                    e.target.parentNode.style.backgroundColor = 'grey';
                                                    e.target.parentNode.style.display = 'flex';
                                                    e.target.parentNode.style.justifyContent = 'center';
                                                    e.target.parentNode.style.alignItems = 'center';
                                                    e.target.parentNode.style.height = '220px';
                                                    e.target.parentNode.innerHTML = 'No preview available';
                                                }
                                            }}
                                        />
                                        <Box sx={{ position: 'absolute', bottom: 10, right: 10, backgroundColor: 'black', opacity: 0.6, borderRadius: '10px', p: 0.5 }}>
                                            <Box component={'img'} sx={{ width: '50px' }} src={Images.mainlogo}></Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>

                    </Box>}
                    {selectedTab == 2 && <Box sx={{ width: '80%', margin: '0 auto' }}>

                        <Box sx={{ mt: '5%', display: 'flex', justifyContent: 'space-around', textAlign: 'left' }}>
                            <Box sx={{ width: '50%' }}>
                                <p style={{ fontFamily: "Noto Sans, sans-serif", color: '#a1acb1' }}>Address Line 1:</p></Box>
                            <Box sx={{ width: '50%' }}>
                                <p style={{ fontFamily: "Noto Sans, sans-serif", color: 'white' }}>{userData?.address_1}</p>
                            </Box>
                        </Box>
                        <Box sx={{ mt: '5%', display: 'flex', justifyContent: 'space-around', textAlign: 'left' }}>
                            <Box sx={{ width: '50%' }}>
                                <p style={{ fontFamily: "Noto Sans, sans-serif", color: '#a1acb1' }}>Address Line 2:</p></Box>
                            <Box sx={{ width: '50%' }}>
                                <p style={{ fontFamily: "Noto Sans, sans-serif", color: 'white' }}>{userData?.address_2}</p>
                            </Box>
                        </Box>
                        <Box sx={{ mt: '5%', display: 'flex', justifyContent: 'space-around', textAlign: 'left' }}>
                            <Box sx={{ width: '50%' }}>
                                <p style={{ fontFamily: "Noto Sans, sans-serif", color: '#a1acb1' }}>Emirates:</p></Box>
                            <Box sx={{ width: '50%' }}>
                                <p style={{ fontFamily: "Noto Sans, sans-serif", color: 'white' }}>{userData?.emirate}</p>
                            </Box>
                        </Box>

                        <Box sx={{ mt: '5%', display: 'flex', justifyContent: 'space-around', textAlign: 'left' }}>
                            <Box sx={{ width: '50%' }}>
                                <p style={{ fontFamily: "Noto Sans, sans-serif", color: '#a1acb1' }}>ZIP Code:</p></Box>
                            <Box sx={{ width: '50%' }}>
                                <p style={{ fontFamily: "Noto Sans, sans-serif", color: 'white' }}>{userData?.zip_code}</p>
                            </Box>
                        </Box>


                    </Box>}
                    {selectedTab == 3 && <Box sx={{ width: '95%', margin: '0 auto' }}>

                        {/* <Box sx={{ mt: '5%', display: 'flex', justifyContent: 'space-around', textAlign: 'left' }}>
                            <Box sx={{ width: '50%' }}>
                                <p style={{ fontFamily: "Noto Sans, sans-serif", color: '#a1acb1' }}>Profile Picture:</p></Box>
                            <Box sx={{ width: '50%' }}>
                                <Avatar sx={{ mt: '2%' }} alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                            </Box>
                            <EditIcon sx={{ color: 'white', cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ mt: '5%', display: 'flex', justifyContent: 'space-around', textAlign: 'left' }}>
                            <Box sx={{ width: '50%' }}>
                                <p style={{ fontFamily: "Noto Sans, sans-serif", color: '#a1acb1' }}>Name:</p></Box>
                            <Box sx={{ width: '50%' }}>
                                <p style={{ fontFamily: "Noto Sans, sans-serif", color: 'white' }}>John Doe</p>
                            </Box>
                            <EditIcon sx={{ color: 'white', cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ mt: '5%', display: 'flex', justifyContent: 'space-around', textAlign: 'left' }}>
                            <Box sx={{ width: '50%' }}>
                                <p style={{ fontFamily: "Noto Sans, sans-serif", color: '#a1acb1' }}>Email Address:</p></Box>
                            <Box sx={{ width: '50%' }}>
                                <p style={{ fontFamily: "Noto Sans, sans-serif", color: 'white' }}>johndoe321@gmail.com</p>
                            </Box>
                            <EditIcon sx={{ color: 'white', cursor: 'pointer' }} />
                        </Box>

                        <Box sx={{ mt: '5%', display: 'flex', justifyContent: 'space-around', textAlign: 'left' }}>
                            <Box sx={{ width: '50%' }}>
                                <p style={{ fontFamily: "Noto Sans, sans-serif", color: '#a1acb1' }}>Phone Number:</p></Box>
                            <Box sx={{ width: '50%' }}>
                                <p style={{ fontFamily: "Noto Sans, sans-serif", color: 'white' }}>+1 234 5678 9782</p>
                            </Box>
                            <EditIcon sx={{ color: 'white', cursor: 'pointer' }} />
                        </Box> */}
                        <List disablePadding>
                            {orders.length > 0 && orders.map((product) => {



                                return (
                                    <Box onClick={() => navigate(
                                        `/order-detail`,
                                        { state: product }
                                    )} sx={{ mt: 1, borderRadius: '15px', cursor: 'pointer', p: 2, boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography sx={{ color: 'white', mb: 2 }}>
                                                <FaTshirt style={{ color: 'white' }} /> &nbsp; {`Order Id : ${product?.order_no} `}
                                            </Typography>
                                            <Box sx={{display:'flex',alignItems:'center', justifyContent:'center',width: '100px', height: '25px', textAlign: 'center', color: 'White', backgroundColor: '#005fc4', borderRadius: '15px',fontWeight:'bold' ,verticalAlign:'middle'}}>
                                                <Box >
                                                {product?.status == 'pending' ? 'Pending' : product?.status == 'confirmed' ? 'Confirmed' : product?.status == 'delivered' ? 'Delivered' : product?.status == 'processing' ? 'Processing' : "Rejected"    }
                                                </Box>
                                            </Box>
                                        </Box>
                                        <hr style={{ width: '100%', borderStyle: 'dashed', borderColor: 'gray' }} />
                                        <Typography variant="body2" sx={{ color: 'white', mb: 2, mt: 2 }}>Total Amount: AED {product?.payment_amount}</Typography>

                                        <Typography sx={{ color: '#a1acb1', mb: 2, mt: 2 }}>
                                            {moment(product?.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                                        </Typography>

                                    </Box>
                                );
                            })}

                        </List>



                    </Box>}

                </Grid>
            </Grid>


        </Fragment>
    )
}
