import React from 'react'

const Signup = () => {
  return (
    <div>
      Signup
    </div>
  )
}

export default Signup
