import { Box, Chip, Drawer, Grid } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Images } from '../../../assests'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DoneIcon from '@mui/icons-material/Done';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ContentCopy as ContentCopyIcon, CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DeleteIcon from '@mui/icons-material/Delete';
import { SecondaryButton } from '../../../components/Buttons';
import EastIcon from '@mui/icons-material/East';
export default function Gallery() {
    const { state } = useLocation()
    const navigate = useNavigate()
    const [open, setOpen] = useState(true);
    const drawerRef = useRef(null);
    let user = localStorage.getItem('user')
    user = JSON.parse(user)
    console.log(user);

    const [copied, setCopied] = useState(false);
    const handleDelete = () => {
        console.info('You clicked the delete icon.');
      };
    const handleCopyClick = () => {
        navigator.clipboard.writeText(state.prompt)
            .then(() => {
                console.log('Prompt copied to clipboard');
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 2000); // Reset copied state after 2 seconds
            })
            .catch(err => {
                console.error('Failed to copy prompt: ', err);
            });
    };
    const handleClickOutside = (event) => {
        if (drawerRef.current && !drawerRef.current.contains(event.target)) {
            setOpen(false);
        }
        else {
            setOpen(true);
        }
    };

    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);


    const toggleDrawer = (isOpen) => () => {
        setOpen(isOpen);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div>
            <Drawer
                anchor="right"
                variant="temporary"
                open={open}
                ModalProps={{
                    hideBackdrop: true, // Disable backdrop
                }}

                sx={{
                    display: 'flex !important',
                    justifyContent: 'center !important',


                    opacity: '1',
                    '& .MuiPaper-root': {
                        backgroundColor: '#18171c', // Change this to your desired color
                        color: '#fff', // Change this to the desired text color
                        borderTopLeftRadius: '12px',
                        borderBottomLeftRadius: '12px',

                    },
                }}

            >
                <div
                    role="presentation"

                    ref={drawerRef}
                    style={{ width: '320px', marginTop: '25%', padding: '10px' }}
                >
                    <Box sx={{ display: 'flex', padding: '10px', justifyContent: 'space-between', fontFamily: "Noto Sans, sans-serif", fontSize: '15px' }}>
                        <p>Magic Prompt</p>
                        <Box component={'div'} onClick={handleCopyClick} sx={{ border: '1px solid gray', p: '3px', borderRadius: '4px', cursor: "pointer" }}>
                            {copied ? (
                                <DoneIcon sx={{ fontSize: '15px', color: 'white' }} />
                            ) : (
                                <ContentCopyIcon sx={{ fontSize: '15px' }} />
                            )}</Box>
                    </Box>
                    <Box sx={{ display: 'flex', padding: '10px', justifyContent: 'space-between', fontFamily: "Noto Sans, sans-serif", fontSize: '15px', color: '#dbc8c8', wordBreak: 'break-all', mt: '2%' }}>
                        {state?.prompt}
                    </Box>
                    <hr style={{ backgroundColor: 'grey', height: '1px', border: 'none', marginTop: '4%' }} />

                    <Grid container sx={{ mt: '10%', padding: '10px' }}>
                        <Grid item xs={6} sx={{ mt: '5%' }}>
                            <p style={{ fontFamily: "Noto Sans, sans-serif" }}>Created At</p>
                            <p style={{ fontFamily: "Noto Sans, sans-serif", fontSize: '15px', color: '#dbc8c8' }}>{moment(state?.created_at).format('MMMM Do YYYY')}</p>
                        </Grid>
                        <Grid item xs={6} sx={{ mt: '5%' }}>
                            <p style={{ fontFamily: "Noto Sans, sans-serif" }}>Created By</p>
                            <p style={{ fontFamily: "Noto Sans, sans-serif", fontSize: '15px', color: '#dbc8c8' }}>{user?.displayName}</p>
                        </Grid>
                        <Grid item xs={6} sx={{ mt: '5%' }}>
                            <p style={{ fontFamily: "Noto Sans, sans-serif" }}>Dimension</p>
                            <p style={{ fontFamily: "Noto Sans, sans-serif", fontSize: '15px', color: '#dbc8c8' }}>1024x1024</p>
                        </Grid>
                        <Grid item xs={6} sx={{ mt: '5%' }}>
                            <p style={{ fontFamily: "Noto Sans, sans-serif" }}>Visibility</p>
                            <p style={{ fontFamily: "Noto Sans, sans-serif", fontSize: '15px', color: '#dbc8c8' }}>Public</p>
                        </Grid>
                    </Grid>
                    <hr style={{ backgroundColor: 'grey', height: '1px', border: 'none', marginTop: '4%' }} />
                  <Box sx={{display:'flex',justifyContent:'center',mt:'40px'}}>
                    <Chip
                    sx={{width:'70%',margin:'0 auto',color:'white',fontFamily: "Noto Sans, sans-serif"}}
                        label="Regenerate This Prompt"
                        onClick={() => navigate(
                            `/`,
                            { state: state.prompt }
                          )}
                        onDelete={()=>  navigate(
                            `/`,
                            { state: state.prompt }
                          )}
                        deleteIcon={<AutoFixHighIcon sx={{color:'white !important'}} />}
                        variant="outlined"
                        
                    />
                    </Box>
                    
                    <Box sx={{display:'flex',justifyContent:'center',mt:'40px'}}>
                    <SecondaryButton onClick={() => {navigate(
                `/product-final`,
                { state: state?.url }

              )
              localStorage.setItem('gallery', JSON.stringify([{ imageUrl: state?.url, width: 100, height: 100, rotation: 0, top: 20, left: 20, borderRadius: 10 }]))
              localStorage.setItem('designArray', JSON.stringify({ frontText: [], backText:[], frontPic: [{ imageUrl: state?.url, width: 100, height: 100, rotation: 0, top: 20, left: 20, borderRadius: 10, clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' }], backPic: [{ imageUrl: state?.url, width: 100, height: 100, rotation: 0, top: 20, left: 20, borderRadius: 10, clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' }],color:'white' }))
              }} p={"15px 30px"} fontWeight={'bold'} icon={<EastIcon />} color={'#0F172A'} title={'Place This On  Tshirt'} />
              </Box>
                </div>
            </Drawer>
            <Grid container sx={{ backgroundColor: 'black', pt: '50px', pb: '100px' }} display={'flex'} justifyContent={'center'}>
                <Grid item xs={11} display={'flex'} justifyContent={"space-between"}>
                    <Box component={'div'} onClick={() => navigate(-1)} sx={{ backgroundColor: '#515153', width: "30px", height: '30px', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}><ArrowBackIosNewIcon sx={{ color: 'black', fontSize: '15px' }} /></Box>
                    <Box component={'div'} onClick={() => setOpen(!open)} sx={{ backgroundColor: '#515153', width: "30px", height: '30px', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}><MoreVertIcon sx={{ color: 'black', fontSize: '15px' }} /></Box>
                </Grid>
                <Grid item xs={5} display={'flex'} justifyContent={'center'}  sx={{ position: 'relative' }}>
                    <Box sx={{ position: 'relative' }}>

                        <Box component={'img'} sx={{ mt: '2%', borderRadius: '12px' }} width={'85%'} src={state.url} onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '';
                            if (e.target.parentNode) {
                                e.target.parentNode.style.color = 'white';
                                e.target.parentNode.style.display = 'flex';
                                e.target.parentNode.style.justifyContent = 'center';
                                e.target.parentNode.style.alignItems = 'center';
                                e.target.parentNode.style.height = '500px';
                                e.target.parentNode.style.width = '500px';
                                e.target.parentNode.innerHTML = 'No preview available';
                            }
                        }} >

                        </Box>
                        {<Box sx={{ position: 'absolute', bottom: 25, right: 100, backgroundColor: 'black', opacity: 0.6, borderRadius: '10px', p: 0.5 }}>
                            <Box component={'img'} sx={{ width: '100px' }} src={Images.mainlogo}></Box>
                        </Box>}
                    </Box>
                </Grid>

            </Grid>
        </div>
    )
}
