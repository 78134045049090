import { Box, Button, Typography } from "@mui/material"
import Colors from "../../assests/style"


export const PrimaryButton = ({ title, icon,onClick }) => {
    return (
        <Button
            variant="outlined"
            onClick={onClick}
            sx={{
                textTransform: "capitalize",
                p: "10px 20px",
                borderWidth: "2px",
                ":hover": {
                    borderWidth: "2px"
                },

            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px"
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 500
                    }}
                >
                    {title}
                </Typography>
                {icon && icon}
            </Box>
        </Button>
    )
}

export const SecondaryButton = ({ title, icon, color, sx, fontWeight, p, onClick, bgColor, type,className,iconPosition,m ,fullWidth}) => {
    return (
        <Button
            type={type}
            onClick={onClick}
            className={className}
            variant="contained"
            fullWidth={fullWidth}
            sx={{
                backgroundColor: bgColor ? bgColor : "",
                textTransform: "capitalize",
                p: p ? p : "10px 20px",
                m: m ? m : "",
                border: `1px solid ${color}`,
                ":hover": {
                    border: `1px solid ${color}`,
                }
                ,



            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px"
                }}
            >
                     {iconPosition == 'start' && icon && icon }
                <Typography
                    sx={{
                        color: color ? color : ' white',
                        fontWeight: fontWeight
                    }}
                >
                    {title}
                </Typography>
                {iconPosition == 'end' && icon && icon }
            </Box>
        </Button>
    )
}