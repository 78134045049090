import { Avatar, Backdrop, Box, Card, CardActionArea, CardActions, CardContent, CardMedia, CircularProgress, Dialog, DialogContent, DialogTitle, Fade, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Modal, Radio, RadioGroup, Select, ThemeProvider, colors, createTheme, useMediaQuery } from '@mui/material'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import background from '../../../assests/images/mayaccountbg.png'
import EditIcon from '@mui/icons-material/Edit';
import { Images } from '../../../assests';
import { CssBaseline, Container, Paper, Typography, Stepper, Step, StepLabel, Button, TextField, List, ListItem, ListItemText } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Moveable from 'react-moveable';
import { ContextMenuTrigger } from 'react-contextmenu';
import { PDFExport } from '@progress/kendo-react-pdf';
import domtoimage from 'dom-to-image';
import { FaTshirt } from "react-icons/fa";
import CloseIcon from '@mui/icons-material/Close';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,

    IconButton,
} from '@mui/material';
import Input from '@mui/material/Input';
import { Add, Remove } from '@mui/icons-material';
import { SecondaryButton } from '../../../components/Buttons';
import PropTypes from 'prop-types';
import InputPhone from '../../../components/PhoneInput';
import { useForm } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import axios from 'axios';
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import moment from 'moment/moment';
import OrderServices from '../../../services/OrderServices';
import Swal from 'sweetalert2';
import AuthServices from '../../../services/AuthServices';
import { auth, provider, signInWithPopup } from '../../../config/firebase.config'; // Adjust the import path according to your file structure
import { getAuth, GoogleAuthProvider, signOut } from 'firebase/auth';
import html2canvas from 'html2canvas';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { AuthContext } from '../../../Context/AuthContext';
import Colors from '../../../assests/style';
import { jsx } from 'react/jsx-runtime';
import { CartContext } from '../../../Context/CartContext';
import { toPng } from 'html-to-image';
import { domToPng } from 'modern-screenshot';

const steps = ['Shipping address', 'Payment details', 'Review your order'];


function useBase64Image(url) {
    const [base64Image, setBase64Image] = useState('');

    useEffect(() => {
        if (url) {
            fetch(url)
                .then(response => response.blob())
                .then(blob => {
                    const reader = new FileReader();
                    reader.onloadend = () => setBase64Image(reader.result);
                    reader.onerror = (error) => console.error('Error converting image:', error);
                    reader.readAsDataURL(blob);
                })
                .catch(error => {
                    console.error('Error fetching image:', error);
                });
        }
    }, [url]);

    return base64Image;
}
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="0000-0000-0000-0000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

TextMaskCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};




export default function Order() {

   
      
    const theme = createTheme({
        typography: {
            fontFamily: 'Poppins, sans-serif'// You can change this to your preferred font
        },
        components: {
            MuiStepLabel: {
                styleOverrides: {
                    label: {
                        color: 'rgba(255, 255, 255, 0.7)',
                        fontFamily: 'Poppins, sans-serif',
                        '&.Mui-active': {
                            color: '#ffffff',
                        },
                        '&.Mui-completed': {
                            color: '#ffffff',
                        },
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& .MuiInputBase-root': {
                            color: 'white', // Text color,
                            fontFamily: 'Poppins, sans-serif'
                        },
                        '& .MuiInputLabel-root': {
                            color: 'white', // Label color
                        },
                        '& .MuiInput-underline:before': {
                            borderBottomColor: 'white', // Underline color when unfocused
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: 'white', // Underline color when focused
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'white', // Border color when unfocused
                            },
                            '&:hover fieldset': {
                                borderColor: 'white', // Border color on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'white', // Border color when focused
                            },
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        color: 'white', // Text color for input
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: 'white', // Border color for input
                    },
                    root: {
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: 'white', // Text color for label
                        borderColor: 'white',
                        '&.Mui-focused': {
                            color: 'white',
                        },
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        color: 'black', // Text color for menu items
                        backgroundColor: 'white', // Background color for menu items
                        '&.Mui-selected': {
                            backgroundColor: 'rgba(255, 255, 255, 0.2)', // Background color for selected item
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)', // Background color for hover
                        },
                    },
                },
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        color: 'white', // Color for dropdown arrow
                    },
                },
            },
        },
        palette: {
            primary: {
                main: '#1976d2',
            },
            background: {
                default: '#333333',
            },
        },

    });
    const isXs = useMediaQuery(theme.breakpoints.down('xs')); // Check for extra small screens
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm')); // Switch to fullscreen on small devices
    const { register: register1, handleSubmit: handleSubmit1, formState: { errors: errors1 }, control: control1, watch, reset } = useForm();
    const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, control: control2 } = useForm();
    const auth = getAuth();
    const { cartVisible, toggleCartVisibility } = useContext(CartContext);
    let User = localStorage.getItem('user')
    User = JSON.parse(User)
    const [selectedTab, setSelectedTab] = useState(0)
    const Tabs = [{ id: 0, name: 'My Account', icon: <PersonIcon /> }, { id: 1, name: 'My Library', icon: <SaveAltIcon /> }, { id: 2, name: 'Addresses', icon: <LocationOnOutlinedIcon /> }, { id: 3, name: 'Account Settings', icon: <SettingsIcon /> }, { id: 4, name: 'Log out', icon: <LogoutIcon /> }]
    const [isLessThan1000px, setIsLessThan1000px] = useState(window.innerWidth < 1000);
    const { state } = useLocation();
    const navigate = useNavigate();
    const contentRef = useRef(null);
    const parentRef = useRef(null);
    const [allData, setAllData] = useState(JSON.parse(localStorage.getItem("designArray")))
    const { user, setUser } = useContext(AuthContext);
    const [screenshotUrl, setScreenshotUrl] = useState()
    const pdfExportComponentFront = useRef(null);
    const pdfExportComponentBack = useRef(null);
    const elementFront = useRef(null)
    const elementBack = useRef(null)
    const [frontSSUrl, setFrontSSUrl] = useState()
    const [backSSUrl, setbackSSUrl] = useState()
    console.log(state);
    const [isLessThan1200, setIsLessThan1200] = useState(window.innerWidth < 1000);

    const [activeStep, setActiveStep] = useState(0);
    const [rotateDirection, setRotateDirection] = useState(false)
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [checkOut, setCheckOut] = useState(false)
    const [frontUrl, setFrontUrl] = useState()
    const [backUrl, setBackUrl] = useState()
    const [tshirtPrice, setTshirtPrice] = useState(0)
    const [maxOrder, setMaxOrder] = useState(0)
    const [deliveryCharges, setDeliveryCharges] = useState(0)
    // *For Stepper Forms Data
    const [step1FormData, setStep1FormData] = useState();
    const [step2FormData, setStep2FormData] = useState();
    const [orderSummary, setOrderSummary] = useState([])
    const [userData, setUserData] = useState()
    const [emirates, setEmirates] = useState([])
    const [screenShotLoader, setScreenShotLoader] = useState(false)
    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)

    const link = document.getElementsByTagName("link");
    link.crossOrigin = "anonymous";

    const ExpiryDate = React.forwardRef(function ExpiryDate(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="00/00"
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    });
    const CVV = React.forwardRef(function CVV(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="0000"
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    });

    const [selectedOption, setSelectedOption] = useState('Everyday Standard');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    }



    const divRef = useRef(null);
    const divRefBack = useRef(null);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '20px'


    };


    const ImageScreenShot = async () => {
        const div = elementFront.current;

        if (div) {
            const rect = div.getBoundingClientRect();
            const width = rect.width;
            const height = rect.height;
            const scale = 4;

            // Set the div's styles to match its actual size
            div.style.width = `${500}px`;
            div.style.height = `${500}px`;
            div.style.padding = '0';
            div.style.border = 'none';
            div.style.overflow = 'hidden';
            div.style.backgroundColor = null; // or undefined



            try {
                // Capture the screenshot
                const dataUrl = await domToPng(div, {
                    bgcolor: 'transparent', // Set background to transparent
                    width: 500,
                    height: 600,
                    x: 0, // Set x and y to 0 because we're already translating with transform
                    y: 0,
                });

                console.log(dataUrl); // Log the data URL to the console

                const link = document.createElement('a');
                link.download = 'yImage.png';
                link.href = dataUrl;
                // link.click()

                sendBlobPreview(dataUrl.split(";base64,")[1], moment().unix() + '_PreviewFront.png');
            } catch (error) {
                console.error('Error capturing screenshot:', error);
            } finally {
                // Reset the div's styles
                div.style.position = '';
                div.style.top = '';
                div.style.left = '';
                div.style.transform = '';
            }
        }
    };

    const ImageScreenShotBack = async () => {
        const div = elementBack.current;

        if (div) {
            const rect = div.getBoundingClientRect();
            const width = rect.width;
            const height = rect.height;
            const scale = 4;

            // Set the div's styles to match its actual size
            div.style.width = `${500}px`;
            div.style.height = `${500}px`;
            div.style.padding = '0';
            div.style.border = 'none';
            div.style.overflow = 'hidden';
            div.style.backgroundColor = null; // or undefined



            try {
                // Capture the screenshot
                const dataUrl = await domToPng(div, {
                    bgcolor: 'red', // Set background to transparent
                    width: 500,
                    height: 600,
                    x: 0, // Set x and y to 0 because we're already translating with transform
                    y: 0,
                });

                console.log(dataUrl); // Log the data URL to the console

                const link = document.createElement('a');
                link.download = 'yImageback.png';
                link.href = dataUrl;
                // link.click()

                sendBlobPreviewBack(dataUrl.split(";base64,")[1], moment().unix() + '_PreviewBack.png');
            } catch (error) {
                console.error('Error capturing screenshot:', error);
            } finally {
                // Reset the div's styles
                div.style.position = '';
                div.style.top = '';
                div.style.left = '';
                div.style.transform = '';
            }
        }
    };


    const capture = async () => {
        const div = divRef.current;

        if (divRef.current) {
            const a4Width = 220; // mm
            const a4Height = 317; // mm
            const scale = 10; // adjust this value to change the image resolution

            // Set the div's styles to match the A4 size
            div.style.width = `${a4Width}mm`;
            div.style.height = `${a4Height}mm`;
            div.style.padding = '0';
            div.style.border = 'none';
            div.style.overflow = 'hidden';

            // Capture the screenshot
            domToPng(div, {
                bgcolor: 'transparent',
                width: a4Width * scale,
                height: a4Height * scale,
                style: {
                    transform: `scale(${scale})`,
                    transformOrigin: 'top left',
                },
            }).then(dataUrl => {
                const link = document.createElement('a');
                link.download = 'yImage.png';
                link.href = dataUrl;
                // link.click()
                setFrontSSUrl({ document: dataUrl.split(";base64,")[1], name: moment().unix() + '_Front.png' })



            });
        }
    };

    const captureBack = async () => {
        const div = divRefBack.current;

        if (divRefBack.current) {

            const a4Width = 220; // mm
            const a4Height = 317; // mm
            const scale = 10; // adjust this value to change the image resolution

            // Set the div's styles to match the A4 size
            div.style.width = `${a4Width}mm`;
            div.style.height = `${a4Height}mm`;
            div.style.padding = '0';
            div.style.border = 'none';
            div.style.overflow = 'hidden';

            // Capture the screenshot
            domToPng(div, {
                bgcolor: 'transparent',
                width: a4Width * scale,
                height: a4Height * scale,
                style: {
                    transform: `scale(${scale})`,
                    transformOrigin: 'top left',
                },
            }).then(dataUrl => {
                const link = document.createElement('a');
                link.download = 'back.png';
                link.href = dataUrl;
                setbackSSUrl({ document: dataUrl.split(";base64,")[1], name: moment().unix() + '_Back.png' })

            });
        }
    };


    const waitForImagesToLoad = () => {
        return new Promise((resolve) => {
            const imgElements = document.querySelectorAll('img'); // Update this selector as per your needs

            let loadedCount = 0;

            imgElements.forEach((img) => {
                if (img.complete) {
                    loadedCount++;
                } else {
                    img.onload = () => {
                        loadedCount++;
                        if (loadedCount === imgElements.length) {
                            resolve();
                        }
                    };
                }
            });

            if (loadedCount === imgElements.length) {
                resolve();
            }
        });
    };

    const captureScreenshot = async () => {

        toggleCartVisibility()

        await waitForImagesToLoad();
        await ImageScreenShot()
        await ImageScreenShotBack()
        await capture();
        await captureBack();
    };


    const handleGoogleLogin = async () => {

        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            console.log("User Info: ", user);


            authLogin(user?.reloadUserInfo, result)
            // Handle user info here (e.g., save to state, context, or redirect)
        } catch (error) {
            console.error("Error during Google login: ", error);
        }
    };

    const authLogin = async (sendData, result) => {
        console.log(sendData, 'data');
        try {
            let obj = {
                phone: '',
                google_id: sendData.localId,
                email: sendData.email,
                picture: sendData.photoUrl,
                name: sendData.displayName
            }
            const data = await AuthServices.authLogin(obj)
            console.log(data);
            if (data.responseCode == 200) {

                localStorage.setItem('authToken', JSON.stringify(data?.data?.token))
                localStorage.setItem('user', JSON.stringify(sendData))
                let userInfo = localStorage.getItem('user')
                setUserData(JSON.parse(userInfo))
                setUser(result.user)
            }



        } catch (error) {
            alert('New Registration is Closed')
        } finally {

        }
    }
    const handleChange = (event) => {

        setSelectedOption(event.target.value);
        console.log(totalQuantity, 'totalQuantity');
        console.log(maxOrder, 'maxOrder');
        const deliveryChargesresult = calculateDeliveryCharges(totalQuantity, event.target.value);
        setDeliveryCharges(deliveryChargesresult)
        console.log(`The delivery charges for a quantity of ${totalQuantity} is ${deliveryCharges}`);
    };

    const names = [
        'Abu Dhabi',
        'Dubai',
        'Sharjah',
        'Ajman',
        'Umm Al Quwain',
        'Ras Al Khaimah',
        'Fujairah',

    ];
    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };
    const sizes = ['S', 'M', 'L', 'XL'];
    const [quantities, setQuantities] = useState({
        S: 0,
        M: 0,
        L: 0,
        XL: 0,
    });

    const placeOrder = async () => {
        console.log(step1FormData, 'data');
        console.log(quantities, 'data');
        const designs = {
            S: 'dotted',
            M: 'striped',
            L: 'plain',
            XL: 'checked'
        };

        const details = Object.entries(quantities).map(([size, qty]) => ({
            size,
            qty,
            design_front: frontUrl,
            design_back: backUrl
        }));
        console.log(details);
        try {
            let obj = {
                ...step1FormData,
                details: details,
                color: state.color ? state.color : 'white',
                payment_amount: totalQuantity * tshirtPrice,
                screenshot_front: screenshotUrl,
                screenshot_back: screenshotUrl,
            }

            const { message } = await OrderServices.placeOrder(obj)
            console.log(message);
            if (message) {

                Swal.fire({
                    title: message,

                    icon: "success",
                    background: '#0f172a', // Change to your desired background color
                    color: '#fff',// Change to your desired text color

                }).then((result) => {
                    if (result.isConfirmed) {
                        // window.location.href = '/my-account'; // Navigate to the home page
                    }
                });
            }

        } catch (error) {
            console.log(error);
        } finally {

        }
    }


    const getPricing = async () => {
        try {
            let params = {

            }
            const { data } = await OrderServices.getPricing(params)
            console.log(data);
            setEmirates(data?.pricing?.delivery_charges)
            setTshirtPrice(data?.pricing?.standard_price)
            setMaxOrder(data?.pricing?.max_per_order)



        } catch (error) {
            console.log(error);
        } finally {

        }
    }
    const handleQuantityChange = (size, event) => {
        const value = event.target.value;
        console.log(value, 'asdasd');
        if (!isNaN(value) && value >= 0) {
            // Create a new variable to hold the updated quantities
            const updatedQuantities = {
                ...quantities,
                [size]: parseInt(value, 10),
            };

            // Set the state with the updated quantities
            setQuantities(updatedQuantities);

            // Prepare the order summary based on the updated quantities
            const details = Object.entries(updatedQuantities).map(([size, qty]) => ({
                size,
                qty,
                design: 'abc',
            }));

            console.log(details, 'detailsdetailsdetails');
            // Example usage:

            setOrderSummary(details);
        }
    };







    // const exportPDFWithMethodFront = () => {
    //     let gridElement = document.querySelector("#pdfFront");
    //     drawDOM(gridElement, {
    //         paperSize: "A4",
    //         scale: 2.7,
    //         fileName: "frontPrint",
    //     })
    //         .then((group) => {
    //             return exportPDF(group);
    //         })
    //         .then((dataUri) => {
    //             console.log(dataUri.split(";base64,")[1]);
    //             sendBlobFront(dataUri.split(";base64,")[1], moment().unix() + '_Front.pdf')
    //         });
    // };
    // const exportPDFWithMethodBack = () => {
    //     let gridElement = document.querySelector("#pdfBack");
    //     drawDOM(gridElement, {
    //         paperSize: "A4",
    //         scale: 2.7,
    //         fileName: "Backprint",
    //     })
    //         .then((group) => {
    //             return exportPDF(group);
    //         })
    //         .then((dataUri) => {
    //             console.log(dataUri.split(";base64,")[1]);
    //             sendBlobBack(dataUri.split(";base64,")[1], moment().unix() + '_Back.pdf')
    //         });
    // };
    // const sendScreenshotFront = async (blob, name) => {
    //     // Create FormData and append the blob and other fields
    //     const formData = new FormData();
    //     formData.append('document', blob, name);
    //     formData.append('filename', name);

    //     try {
    //         const response = await axios.post(
    //             process.env.REACT_APP_BASE_URL + '/api/system/upload',
    //             formData,
    //             {
    //                 headers: {
    //                     'Content-Type': 'multipart/form-data'
    //                 }
    //             }
    //         );
    //         setScreenshotUrl(response?.data?.data?.path)
    //         console.log('Upload successful222:', response.data.data.path);

    //     } catch (error) {
    //         console.error('Error uploading the file:', error);
    //     }
    // };


    const sendBlobBack = async (base64, name) => {
        let obj = {
            document: base64,
            filename: name

        }

        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + '/api/system/uploadDesign', obj);

            console.log('Upload successful:', response.data.data.path);
            setbackSSUrl(response.data.data.path)
        } catch (error) {
            console.error('Error uploading the file:', error);
        }
    };
    const sendBlobFront = async (base64, name) => {
        let obj = {
            document: base64,
            filename: name

        }

        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + '/api/system/uploadDesign', obj);

            console.log('Upload successful:', response.data.data.path);
            setFrontSSUrl(response.data.data.path)

        } catch (error) {
            console.error('Error uploading the file:', error);
        }
    };
    const sendBlobPreview = async (base64, name) => {
        let obj = {
            document: base64,
            filename: name

        }

        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + '/api/system/uploadDesign', obj);

            console.log('Upload successful:', response.data.data.path);
            setFrontUrl(response.data.data.path)

        } catch (error) {
            console.error('Error uploading the file:', error);
        }
    };
    const sendBlobPreviewBack = async (base64, name) => {
        console.log(base64, 'base64');
        let obj = {
            document: base64,
            filename: name

        }

        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + '/api/system/uploadDesign', obj);

            console.log('Upload successful:', response.data.data.path);
            setBackUrl(response.data.data.path)

        } catch (error) {
            console.error('Error uploading the file:', error);
        }
    };
    function calculateDeliveryCharges(quantity, selected) {


        // Calculate the number of full maxOrder (5 items) and the remaining items
        const fullOrders = Math.floor(quantity / maxOrder);
        const remainingItems = quantity % maxOrder;
        console.log(fullOrders);
        console.log(remainingItems);
        console.log(selected, 'selected');
        let value = selected ? parseInt(selected?.charge) : parseInt(selectedOption?.charge)
        // Calculate the total delivery charges
        let deliveryChargesresult = fullOrders * value;
        console.log(deliveryChargesresult, 'deliveryChargesresult');
        // If there are any remaining items, add another delivery charge
        if (remainingItems > 0) {
            deliveryChargesresult += value;
        }
        console.log(deliveryChargesresult, 'deliveryChargesresult2');

        return deliveryChargesresult;
    }



    useEffect(() => {
        const handleResize = () => {
            setIsLessThan1200(window.innerWidth < 1000);
            console.log(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        const total = Object.values(quantities).reduce((acc, quantity) => acc + (quantity || 0), 0);
        console.log(total, 'total');
        setTotalQuantity(total);

        const deliveryChargesresult = calculateDeliveryCharges(total, null);
        setDeliveryCharges(deliveryChargesresult)

    }, [quantities]);
    useEffect(() => {
        getPricing()
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        const details = Object.entries(quantities).map(([size, qty]) => ({
            size,
            qty,

        }));
        let obj = {
            quantities: details,
            frontScreenshot: frontUrl,
            backScreenshot: backUrl,
            totalQuantity: totalQuantity,
            totalAmount: totalQuantity * tshirtPrice,
            color: allData?.color ? allData?.color : 'white'
        }


        console.log(frontSSUrl, 'frontSSUrl');
        console.log(backSSUrl, 'frontSSUrl');
        if (frontUrl && backUrl) {

            console.log('asdasdasdasdasd');
            obj = JSON.stringify(obj)
            localStorage.setItem('orderData', obj)

        }

    }, [frontUrl, backUrl, quantities])
    useEffect(() => {
        let cartdata = localStorage.getItem('cartData')
        console.log(cartdata);
        if (cartdata) {
            cartdata = JSON.parse(cartdata)
            console.log(cartdata);
            setQuantities(cartdata?.quantities)
            setTotalQuantity(cartdata?.totalQuantity)

        }
        console.log(state, 'state');
        if (state) {

            setAllData(state)
        }
        else {
            let data = localStorage.getItem('designArray')
            data = JSON.parse(data)
            console.log(data, 'data');
            setAllData(data)
        }
        // Run captureScreenshot after the entire DOM content is fully loaded
        // Create a MutationObserver to detect when the element with the class 'pdf-data' is fully loaded
        const observer = new MutationObserver((mutationsList, observer) => {
            const pdfElement = document.querySelector('.pdf-data');
            console.log(pdfElement.childNodes, 'childs');

            if (pdfElement) {
                console.log('loaded');



                captureScreenshot();


                observer.disconnect(); // Stop observing once the element is fully loaded
            }
        });

        // Start observing the document for changes
        observer.observe(document.body, { childList: true, subtree: true });

        // Clean up the observer when the component unmounts
        return () => {
            observer.disconnect();
        };

    }, [])

    const handleClose = () => setOpen(false);
    const handleClose1 = () => setOpen1(false);

    const loadFont = (fontName) => {
        const link = document.createElement('link');
        link.href = `https://fonts.googleapis.com/css2?family=${fontName.replace(/ /g, '+')}&display=swap`;
        link.rel = 'stylesheet';
        link.crossOrigin = "anonymous"
        document.head.appendChild(link);
    };
    const [base64Images, setBase64Images] = useState([]);


    useEffect(() => {
        const url = 'https://corsproxy.io/?' + encodeURIComponent('https://api.domain.com/...');
    }, []);

    useEffect(() => {
        for (let index = 0; index < allData?.frontText?.length; index++) {
            const fontName = allData?.frontText[index]?.fontFamily;
            loadFont(fontName);
        }
        for (let index = 0; index < allData?.backText?.length; index++) {
            const fontName = allData?.backText[index]?.fontFamily;
            loadFont(fontName);
        }

    }, []);


    return (
        <Fragment  >

            <Dialog
                open={open}
                onClose={handleClose}

                maxWidth="md"
                fullWidth
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    <Typography sx={{ textAlign: 'center', fontSize: '2rem', fontFamily: "Plus Jakarta Sans", }}>Measurements</Typography>
                </DialogTitle>
                <DialogContent>
                    {/* Responsive Box with scroll handling */}
                    <Box sx={{ overflowX: 'auto', maxWidth: '100%' }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 500 }} aria-label="measurements table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: "bold" }} align="center">SIZE</TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }} align="center">Chest (Inches)</TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }} align="center">Length (Inches)</TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }} align="center">Sleeve Length (Inches)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center">S</TableCell>
                                        <TableCell align="center">20.5</TableCell>
                                        <TableCell align="center">26</TableCell>
                                        <TableCell align="center">7</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">M</TableCell>
                                        <TableCell align="center">21.5</TableCell>
                                        <TableCell align="center">27</TableCell>
                                        <TableCell align="center">7.5</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">L</TableCell>
                                        <TableCell align="center">22</TableCell>
                                        <TableCell align="center">28</TableCell>
                                        <TableCell align="center">8</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">XL</TableCell>
                                        <TableCell align="center">24</TableCell>
                                        <TableCell align="center">29</TableCell>
                                        <TableCell align="center">8.5</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog
                open={open1}
                onClose={handleClose}
                fullWidth
                maxWidth="md" // Sets the max width of the dialog
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '28px', fontFamily: "Plus Jakarta Sans" }}>Pick Your Fit</Typography>
                    <IconButton onClick={()=> setOpen1(false)} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent >
                    <Grid container spacing={2}>
                        {/* Standard T-shirt Card */}
                        <Grid item xs={12} md={6} display="flex" justifyContent="center">
                            <Card sx={{ maxWidth: 345 }}>
                                <CardActionArea onClick={() => {
                                    if (frontSSUrl && backSSUrl) {
                                        navigate(
                                            `/checkout`,
                                            { state: { frontSSUrl: frontSSUrl, backSSUrl: backSSUrl, tshirtType: 'Standard' } }
                                        );
                                    }
                                }}>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={'https://printmeup.s3.ap-southeast-1.amazonaws.com/Fits/Standard+Fit.png'}
                                        alt="standard"
                                    />
                                    <CardContent sx={{ p: '24px !important' }}>
                                        <Typography gutterBottom variant="h5" component="div">
                                            Standard
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            <ul>
                                                <li>High-quality cotton for everyday wear</li>
                                                <li>Classic fit with breathable fabric</li>
                                                <li>Offered in a range of sizes for a perfect fit</li>
                                            </ul>
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>

                        {/* Premium T-shirt Card */}
                        <Grid item xs={12} md={6} display="flex" justifyContent="center">
                            <Card sx={{ maxWidth: 345 }}>
                                <CardActionArea onClick={() => {
                                    if (frontSSUrl && backSSUrl) {
                                        navigate(
                                            `/checkout`,
                                            { state: { frontSSUrl: frontSSUrl, backSSUrl: backSSUrl, tshirtType: 'Premium' } }
                                        );
                                    }
                                }}>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={'https://printmeup.s3.ap-southeast-1.amazonaws.com/Fits/Premium+Fit.png'}
                                        alt="premium"
                                    />
                                    <CardContent sx={{ p: '24px !important' }}>
                                        <Typography gutterBottom variant="h5" component="div">
                                            Premium
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            <ul>
                                                <li>Superior cotton-polyester blend</li>
                                                <li>Tailored fit with moisture-wicking tech</li>
                                                <li>Offered in a range of sizes for a perfect fit</li>
                                            </ul>
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Box
                sx={{
                    backgroundImage: `url("${background}")`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: '300px', // Adjust height as needed
                    display: 'flex',
                    alignItems: 'center', justifyContent: 'center'
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <p className='banner-text' style={{ fontSize: '35px', textAlign: 'center', fontFamily: "Noto Sans, sans-serif", color: 'white !important' }}> Order Details  </p>
                    <p style={{ fontSize: '25px', textAlign: 'center', color: 'white', fontFamily: "Noto Sans, sans-serif", }}>Homepage || <span style={{ color: '#0bcfd2' }}>Order Details</span></p>
                </Box>
            </Box>

            <Grid container sx={{ paddingTop: '2%', paddingBottom: '5%' }} justifyContent={'center'} >
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', p: '20px' }}>
                    <SecondaryButton onClick={() => navigate('/product-final')} p={"15px 30px"} fontWeight={'bold'} icon={<ArrowBackIcon />} color={'#0F172A'} title={'Back to Customization'} />
                </Box>
                <Grid component={'div'} item md={12} lg={12}>
                    <Box sx={{ color: 'white', fontSize: { lg: '45px', md: '35px', sm: '35px', xs: '30px' }, textAlign: 'center', fontFamily: "Plus Jakarta Sans" }}>Preview Your Order</Box>
                    <Grid container justifyContent={'center'}>
                        {allData && <Grid component={'div'} className={`  tshirt-img-scale`} ref={elementFront} item lg={12} md={12} sx={{ display: 'flex', justifyContent: 'center', height: '570px !important' }}>  <div style={{ position: 'relative' }}>
                            <img width={'500px'} height={'570px'} id="tshirt-backgroundpicture" src={Images[allData?.color ? allData?.color : 'white']} />
                            <div style={{ position: 'absolute', top: 90, left: 145, backgroundColor: 'rgba(0,0,0,0)' }}>
                                {!rotateDirection && <PDFExport ref={pdfExportComponentFront} paperSize="A4" scale={2.7} fileName="Frontprint">
                                    <div ref={divRef} style={{ backgroundColor: 'transparent' }} id='pdfFront'>
                                        <div className=' pdf-data' ref={parentRef} style={{ width: '220px', height: '313px', position: 'relative', overflow: 'hidden' }}>

                                            {allData?.frontText && allData.frontText.length > 0 && allData.frontText.map((item, index) => {
                                                console.log(item?.fontSize, 'dedede');
                                                const textStyle = {
                                                    fontSize: item?.fontSize?.toString().includes('px') ? item.fontSize : `${item.fontSize}px`,
                                                    fontWeight: item.fontWeight,
                                                    fill: item.color,
                                                    fontFamily: item.fontFamily,

                                                };

                                                const circlePath = `M ${item.width / 2}, ${item.width / 2} m -${item.width / 3}, 0 a ${item.width / 3},${item.width / 3} 0 1,1 ${2 * (item.width / 3)},0 a ${item.width / 3},${item.width / 3} 0 1,1 -${2 * (item.width / 3)},0`;
                                                const ellipsePath = `M ${item.width / 2}, ${item.width / 2} m -${item.width / 3}, 0 a ${item.width / 3},${item.width / 6} 0 1,1 ${2 * (item.width / 3)},0 a ${item.width / 3},${item.width / 6} 0 1,1 -${2 * (item.width / 3)},0`;
                                                const wavePath = `M 10, ${item.width / 2} Q ${item.width / 5}, ${item.width / 5} ${2 * (item.width / 5)}, ${item.width / 2} T ${4 * (item.width / 5)}, ${item.width / 2} T ${item.width - 10}, ${item.width / 2}`;
                                                const straightPath = `M 0,${item.width / 2} L ${item.width},${item.width / 2}`;

                                                const shapePaths = {
                                                    circlePath,
                                                    ellipsePath,
                                                    wavePath,
                                                    straightPath,
                                                };

                                                const selectedPath = shapePaths[item.shape] || '';
                                                const uniqueId = `front-circlePath${index}`;

                                                return (
                                                    <React.Fragment key={index}>
                                                        <div
                                                            id={index}
                                                            style={{
                                                                position: 'absolute',
                                                                width: `${item.width}px`,
                                                                height: `${item.height}px`,
                                                                top: item?.top?.toString().includes('px') ? item.top : `${item.top}px`,
                                                                left: item?.left?.toString().includes('px') ? item.left : `${item.left}px`,
                                                                transform: `rotate(${item.rotation}deg)`,
                                                                zIndex: item.zIndexes
                                                            }}
                                                        >

                                                            <svg style={{ overflow: 'visible' }} width={item.width + 3} height={item.height} xmlns="http://www.w3.org/2000/svg">

                                                                <text
                                                                    y={item?.fontSize < 38 ? item?.fontSize - 10 : item?.fontSize}
                                                                    dominantBaseline={item?.fontSize < 38 ? 'middle' : 'auto'}
                                                                    fontSize={item?.fontSize}
                                                                    style={{
                                                                        ...textStyle,
                                                                    }}
                                                                >

                                                                    {item.text}
                                                                </text>


                                                            </svg>
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            })}


                                            {allData?.frontPic.length > 0 && allData?.frontPic.map((imageData, index) => {

                                                // Convert image URL to base64

                                                return (
                                                    <div key={index}>
                                                        <Grid
                                                            component={'div'}
                                                            className={`Design-${index}`}
                                                            style={{
                                                                clipPath: imageData.clipPath,
                                                                width: `${imageData.width}px`,
                                                                height: `${imageData.height}px`,
                                                                position: 'absolute',
                                                                backgroundImage: `url(${imageData?.imageUrl?.includes('amazonaws') ? 'https://corsproxy.io/?' + encodeURIComponent(imageData?.imageUrl) : imageData?.imageUrl})`, // Use the base64 image
                                                                backgroundSize: 'contain',
                                                                backgroundPosition: 'center',
                                                                backgroundRepeat: 'no-repeat',
                                                                top: `${imageData.top}px`,
                                                                left: `${imageData.left}px`,
                                                                borderRadius: `${imageData.borderRadius}px`,
                                                                transform: `${imageData.rotation}`,
                                                                zIndex: imageData?.zIndexes
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </PDFExport>}

                            </div>
                        </div></Grid>}

                        {allData && <Grid item lg={12} md={12} className={`  tshirt-img-scale`} ref={elementBack} sx={{ display: 'flex', justifyContent: 'center', height: '570px !important' }}> <div style={{ position: 'relative' }}>
                            <img width={'500px'} height={'570px'} id="tshirt-backgroundpicture" src={Images[allData?.color ? allData?.color + "Back" : 'white' + "Back"]} />
                            <div style={{ position: 'absolute', top: 90, left: 145 }}>

                                <PDFExport ref={pdfExportComponentBack} paperSize="A4" scale={2.7} fileName="Backprint">
                                    <div ref={divRefBack} style={{ backgroundColor: 'transparent' }} id='pdfBack'>
                                        <div className='pdf-data' ref={parentRef} style={{ width: '220px', height: '313px', position: 'relative', overflow: 'hidden' }}>

                                            {allData?.backText && allData?.backText.length > 0 && allData.backText.map((item, index) => {
                                                const textStyle = {

                                                    fontSize: item?.fontSize?.toString().includes('px') ? item.fontSize : `${item.fontSize}px`,
                                                    fontWeight: item.fontWeight,
                                                    fill: item.color,
                                                    fontFamily: item.fontFamily,

                                                };
                                                const circlePath = `M ${item.width / 2}, ${item.width / 2} m -${item.width / 3}, 0 a ${item.width / 3},${item.width / 3} 0 1,1 ${2 * (item.width / 3)},0 a ${item.width / 3},${item.width / 3} 0 1,1 -${2 * (item.width / 3)},0`;
                                                const ellipsePath = `M ${item.width / 2}, ${item.width / 2} m -${item.width / 3}, 0 a ${item.width / 3},${item.width / 6} 0 1,1 ${2 * (item.width / 3)},0 a ${item.width / 3},${item.width / 6} 0 1,1 -${item.width / 3},0`;
                                                const wavePath = `M 10, ${item.width / 2} Q ${item.width / 5}, ${item.width / 5} ${2 * (item.width / 5)}, ${item.width / 2} T ${4 * (item.width / 5)}, ${item.width / 2} T ${item.width - 10}, ${item.width / 2}`;
                                                const straightPath = `M 0,${item.width / 2} L ${item.width},${item.width / 2}`;

                                                const shapePaths = {
                                                    circlePath,
                                                    ellipsePath,
                                                    wavePath,
                                                    straightPath,
                                                };

                                                const selectedPath = shapePaths[item.shape] || '';
                                                const uniqueId = `back-circlePath${index}`;

                                                return (
                                                    <React.Fragment key={index}>
                                                        <ContextMenuTrigger id={`context-menu-back-${index}`}>
                                                            <div
                                                                id={`back${index}`}
                                                                style={{
                                                                    position: 'absolute',
                                                                    width: `${item.width}px`,
                                                                    height: `${item.height}px`,
                                                                    top: item?.top?.toString().includes('px') ? item.top : `${item.top}px`,
                                                                    left: item?.left?.toString().includes('px') ? item.left : `${item.left}px`,
                                                                    transform: `rotate(${item.rotation}deg)`,
                                                                    zIndex: item.zIndexes
                                                                }}>
                                                                <svg style={{ overflow: 'visible' }} width={item.width + 3} height={item.height} xmlns="http://www.w3.org/2000/svg">

                                                                    <text
                                                                        y={item?.fontSize < 38 ? item?.fontSize - 10 : item?.fontSize}
                                                                        dominantBaseline={item?.fontSize < 38 ? 'middle' : 'auto'}
                                                                        fontSize={item?.fontSize}
                                                                        style={{
                                                                            ...textStyle,
                                                                        }}
                                                                    >

                                                                        {item.text}
                                                                    </text>


                                                                </svg>
                                                            </div>
                                                        </ContextMenuTrigger>
                                                    </React.Fragment>
                                                );
                                            })}

                                            {allData?.backPic.length > 0 && allData?.backPic.map((imageUrl, index) => (
                                                <div key={index}>
                                                    <Grid
                                                        component={'div'}
                                                        className={`Design2-${index}`}
                                                        style={{
                                                            clipPath: allData?.backPic[index].clipPath,
                                                            width: `${allData?.backPic[index].width}px`,
                                                            height: `${allData?.backPic[index].height}px`,
                                                            position: 'absolute',
                                                            backgroundImage: `url(${imageUrl?.imageUrl?.includes('amazonaws') ? 'https://corsproxy.io/?' + encodeURIComponent(imageUrl?.imageUrl) : imageUrl?.imageUrl})`, // Use the base64 image
                                                            backgroundSize: 'contain',
                                                            backgroundPosition: 'center',
                                                            backgroundRepeat: 'no-repeat',
                                                            top: `${allData?.backPic[index].top}px`,
                                                            left: `${allData?.backPic[index].left}px`,
                                                            borderRadius: ` ${allData?.backPic[index].borderRadius}px`,
                                                            transform: `${allData?.backPic[index].rotation}`,
                                                            zIndex: imageUrl?.zIndexes
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </PDFExport>
                            </div>
                        </div></Grid>}
                    </Grid>

                </Grid>
                <Grid item md={12} lg={12}>
                    <>
                        {console.log(quantities, 'quantities')}
                        <ThemeProvider theme={theme}>
                            <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '28px', mt: '5%', fontFamily: "Plus Jakarta Sans", fontWeight: 'bold' }}>Order Details:</Typography>
                            <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '28px', mt: '2%', mb: '1%', fontFamily: "Plus Jakarta Sans", }}>Pick Your Fit:</Typography>
                            {/* <Box sx={{display:'flex'}}>
                            <FormControl component="fieldset">

                                <RadioGroup value={selectedOption} onChange={handleOptionChange}>
                                    <FormControlLabel sx={{color:'white'}} value="Standard" control={<Radio />} label="Everyday Standard" />
                                    <FormControlLabel  sx={{color:'white'}} value="Premium" control={<Radio />} label="Premium Supreme" />
                         
                                </RadioGroup>
                               
                            </FormControl>
                            </Box> */}
                            <Typography component={'p'} onClick={() => setOpen(true)} sx={{ textAlign: 'center', color: 'white', fontSize: '18px', mb: '2%', fontFamily: "Plus Jakarta Sans", cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}><FaTshirt /> Size Guide</Typography>
                            <Grid container justifyContent={'center'}>
                                {sizes.map((size) => (
                                    <Grid item lg={1} md={3} sm={3} xs={3} display={'flex'} justifyContent={'center'}>
                                        <div key={size} className="size-quantity-box">
                                            <h6 style={{ color: 'white', textAlign: 'center', fontFamily: "Plus Jakarta Sans", fontWeight: 400, fontSize: '14px' }}>{size == 'S' ? 'Small' : size == "M" ? 'Medium' : size == "L" ? 'Large' : 'Extra Large'}</h6>

                                            <div className="quantity-controls" style={{ display: 'flex', justifyContent: 'center' }}>

                                                <input type="text" placeholder='0' style={{ backgroundColor: 'transparent', border: 'none', outline: "none", color: 'white', border: '1px solid white', textAlign: 'center', width: '40px', height: '40px', borderRadius: '7px', fontSize: '22px', fontFamily: "Plus Jakarta Sans", marginTop: '10px' }} value={isNaN(quantities[size]) ? '' : quantities[size] ? quantities[size] : ''} onChange={(event) => handleQuantityChange(size, event)} />

                                            </div>
                                        </div>
                                    </Grid>
                                ))}
                                <Grid item md={12} sm={12} xs={12} display={'flex'} justifyContent={'center'}>
                                    <Box sx={{ mt: "40px" }}><span style={{ color: 'white', textAlign: 'center', fontFamily: "Plus Jakarta Sans", fontSize: '25px' }}>Total Quantity :<span style={{ color: '#0bcfd2', fontWeight: 'bold' }}> {totalQuantity}</span> </span>&nbsp;</Box>

                                </Grid>
                                <Grid item md={12} sm={12} xs={12} display={'flex'} justifyContent={'center'}>

                                    <Box sx={{ mt: "40px", mb: '30px' }}><span style={{ color: 'white', textAlign: 'center', fontFamily: "Plus Jakarta Sans", fontSize: '25px' }}>Subtotal : </span>&nbsp; <span style={{ color: 'white', textAlign: 'center', fontFamily: "Plus Jakarta Sans", fontSize: '25px', color: '#0bcfd2', fontWeight: 'bold' }}>AED {totalQuantity * tshirtPrice}</span></Box>
                                </Grid>
                                {checkOut && <Grid item md={12} sm={12} xs={12} display={'flex'} justifyContent={'center'}>
                                    <Box sx={{ mt: "40px" }}><span style={{ color: 'white', textAlign: 'center', fontFamily: "Plus Jakarta Sans", fontSize: '20px', color: 'red' }}>Please Enter Quantity </span></Box>

                                </Grid>}
                                <SecondaryButton onClick={() => {


                                    console.log(totalQuantity > 0, 'totalQuantitytotalQuantity');
                                    if (totalQuantity > 0) {
                                        let currentUser = localStorage.getItem('user')
                                        currentUser = JSON.parse(currentUser)
                                        if (!currentUser) {

                                            handleGoogleLogin()
                                        }
                                        else {
                                            let obj = {
                                                quantities: quantities,
                                                totalQuantity: totalQuantity,
                                                totalAmount: totalQuantity * tshirtPrice,
                                                // frontScreenshot:frontSSUrl,
                                                // backScreenshot:backSSUrl
                                            }
                                            obj = JSON.stringify(obj)
                                            console.log(obj);

                                            localStorage.setItem('cartData', obj)
                                            setOpen1(true)
                                        }
                                    }
                                    else {
                                        setCheckOut(true)

                                    }
                                }} className={'checkout-btn'} iconPosition={'end'} m={'20px'} p={"15px 40px"} fontWeight={'bold'} color={'#0C1427'} icon={(frontSSUrl && backSSUrl) && <ArrowForwardIcon sx={{ color: '#0C1427' }} />} title={frontSSUrl && backSSUrl ? 'Proceed To Checkout' : <CircularProgress style={{ color: '#000' }} />} />
                            </Grid>

                            {/* {checkOut && <Container component="main" sx={{ mb: 4, width: '100%', backgroundColor: 'transparent' }}>
                                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, backgroundColor: 'transparent', color: 'white' }}>
                                    <Typography component="h1" variant="h4" align="center">
                                        Checkout
                                    </Typography>
                                    <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5, color: 'white' }}>
                                        {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel sx={{ color: 'white' }}>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                    {activeStep === steps.length ? (
                                        <Typography variant="h5" gutterBottom>
                                            Thank you for your order.
                                        </Typography>
                                    ) : (
                                        <>
                                            {getStepContent(activeStep)}

                                        </>
                                    )}
                                </Paper>
                            </Container>} */}
                        </ThemeProvider>
                    </>
                </Grid>
            </Grid>


        </Fragment>
    )
}
