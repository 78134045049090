import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Container,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Button,
  MenuItem,
  CardMedia,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useScrollTrigger,
  Slide,
  Badge,
  styled,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AdbIcon from '@mui/icons-material/Adb';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


import { PrimaryButton, SecondaryButton } from '../../components/Buttons';
import Colors from '../../assests/style';
import { Images } from '../../assests';
import { Link, useNavigate } from 'react-router-dom';
import { auth, provider, signInWithPopup } from '../../config/firebase.config'; // Adjust the import path according to your file structure
import { getAuth, GoogleAuthProvider, signOut } from 'firebase/auth';
import AuthServices from '../../services/AuthServices';
import { AuthContext } from '../../Context/AuthContext';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { CartContext } from '../../Context/CartContext';
import Swal from 'sweetalert2';

function HideOnScroll(props) {

  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
const SmallBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    minWidth: '10px',
    height: '10px',
    fontSize: '10px',
    padding: '0',
  },
}));
function Header() {

  const { cartVisible, toggleCartVisibility } = useContext(CartContext);
  let User = localStorage.getItem('user')
  User = JSON.parse(User)
  const [userData, setUserData] = useState()
  const auth = getAuth();
  const [open, setOpen] = useState(false);
  const [dropdown, setDropdown] = useState(false)
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  console.log(user, 'useruseruser');
  const [cartData, setCartData] = useState(null)
  const handleOpenDrawer = (event) => {
    setOpen(event.currentTarget);
  };

  const scrollToId = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

    setDropdown(true)
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDropdown(false);
  };

  const handleProfile = () => {
    // Handle profile click
    console.log('Profile clicked');
    setOpen(false)
    window.location.href = '/my-account'
    handleClose();
  };



  const pages = [{ name: 'Home', url: '/' }, { name: 'Pricing', url: '', id: 'pricing-section' }, { name: 'How it Works', url: '', id: 'how-section' }, { name: 'Contact Us', url: '/contact-us' }];

  const handleGoogleLogin = async () => {

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("User Info: ", user);


      authLogin(user?.reloadUserInfo, result)
      // Handle user info here (e.g., save to state, context, or redirect)
    } catch (error) {
      console.error("Error during Google login: ", error);
    }
  };

  const authLogin = async (sendData, result) => {
    console.log(sendData, 'data');
    try {
      let obj = {
        phone: '',
        google_id: sendData.localId,
        email: sendData.email,
        picture: sendData.photoUrl,
        name: sendData.displayName
      }
      const data = await AuthServices.authLogin(obj)
      console.log(data);
      if (data?.responseCode == 200) {
        console.log(data);
        localStorage.setItem('authToken', JSON.stringify(data?.data?.token))
        localStorage.setItem('user', JSON.stringify(sendData))
        let userInfo = localStorage.getItem('user')
        setUserData(JSON.parse(userInfo))
        setUser(result.user)

      }



    } catch (error) {
      console.log('asdaS');
      alert('New Registration Is Closed')

    } finally {

    }
  }
  const generateSession = async (sendData) => {
    console.log(sendData, 'data');
    try {

      const { data } = await AuthServices.generateSession()
      console.log(data);



    } catch (error) {
      console.log(error);
    } finally {

    }
  }
  const handleLogout = async () => {
    try {
      localStorage.clear()
      setOpen(false)
      navigate('/')
      let user = localStorage.getItem('user')
      setUserData(JSON.parse(user))
      await signOut(auth);
      console.log("User signed out");
    } catch (error) {
      console.error("Error during sign out: ", error);
    }
  };
  useEffect(() => {
    let user = localStorage.getItem('user')
    setUserData(JSON.parse(user))
    setUser('')
    generateSession()
    let cartdata = localStorage.getItem('orderData')
    console.log(cartdata);
    if(cartdata){
      console.log(cartdata,'cartdatacartdata');
      setCartData(cartdata)
      // toggleCartVisibility()
    }
    else{
      setCartData(null)
     
    }
  }, [])


  return (
    <HideOnScroll >
      <AppBar sx={{ backgroundColor: Colors.backgroundColor2, zIndex: '999 !important' }}>
        <Container  >
          <Toolbar sx={{ flexDirection: { lg: "row", md: "row", sm: 'row-reverse', xs: 'row-reverse' }, justifyContent: 'space-between' }} disableGutters>
            <CardMedia
              component={"img"}
              src={Images.mainlogo}
              sx={{
                width: "160px",
                objectFit: "contain",
                display: { xs: 'none', md: 'block' }
              }}
              onClick={() => navigate("/")}
            />

            <Box sx={{ display: { xs: 'flex', md: 'none' }, flexDirection: 'row-reverse', justifyContent: 'space-between' }}>

              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenDrawer}
                sx={{
                  color: Colors.primary
                }}
              >
                <MenuIcon />
              </IconButton>
              {!User ? <PrimaryButton
                title={"Login"}

                onClick={() => handleGoogleLogin()}
              />

                : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                  {(cartVisible ||  cartData)  ? <Box component={'div'} onClick={() => navigate('/order')}> <SmallBadge badgeContent={''} color="primary"> <ShoppingCartIcon sx={{ cursor: "pointer", color: 'white' }} /></SmallBadge></Box> : <ShoppingCartIcon sx={{ cursor: "pointer", color: 'white' }} />} &nbsp;&nbsp;
                  <IconButton
                    edge="end"
                    color="inherit"
                    aria-label="account of current user"
                    aria-controls="profile-menu"
                    // aria-haspopup="true"
                    onClick={(event) => handleClick(event)}
                  >
                    <AccountCircleIcon sx={{ color: 'white' }} />
                  </IconButton>
                  {dropdown && (
                    <Menu
                      id="profile-menu"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleProfile}>Profile</MenuItem>
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                  )}
                </div>}
              <Drawer
                anchor='right'
                open={open}
                onClose={handleCloseDrawer}
                PaperProps={{
                  sx: {
                    width: "60%",
                    background: Colors.backgroundColor1
                  }
                }}
              >
                <List>
                  {pages.map((item, ind) => (
                    <ListItem key={ind} button onClick={() => {
                      setOpen(false)
                      if (item?.url) {
                        navigate(item.url)
                      }
                      else {
                        const pathname = window.location.pathname;
                        console.log(pathname);
                        if (pathname != '/') {
                          navigate('/')

                          setTimeout(() => {
                            scrollToId(item?.id);
                          }, 1000);
                        }
                        scrollToId(item?.id)
                      }
                    }}>

                      <ListItemText sx={{ color: Colors.textColor1 }} primary={item.name} />
                    </ListItem>
                  ))}

                  {!User ? <ListItem> <PrimaryButton
                    title={"Login"}

                    onClick={() => handleGoogleLogin()}
                  />
                  </ListItem>
                    : <div style={{marginTop:'-5px'}}>
                      <List>
                        <ListItem>
                          <ListItemText button onClick={handleProfile} sx={{ color: Colors.textColor1, cursor: 'pointer' ,mb:'0px',mt:'0px'}} primary={'My Account'} />
                        </ListItem>
                      </List>
                      <List>
                        <ListItem>
                          <ListItemText button onClick={handleLogout} sx={{ color: Colors.textColor1, cursor: 'pointer',mt:'0px',mb:'0px' }} primary={'Logout'} />
                        </ListItem></List>
                    </div>}


                </List>
              </Drawer>
            </Box>
            <Link to="/">
              <CardMedia
                component={"img"}
                src={Images.mainlogo}
                sx={{
                  width: "160px",
                  objectFit: "contain",
                  display: { xs: 'flex', md: 'none' }, mr: 1
                }}
              />
            </Link>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                justifyContent: "center"
              }}
            >
              {pages.map((page) => (
                <Box button onClick={() => {
                  if (page?.url) {
                    navigate(page.url)
                  }
                  else {
                    const pathname = window.location.pathname;
                    console.log(pathname);
                    if (pathname != '/') {
                      navigate('/')

                      setTimeout(() => {
                        scrollToId(page?.id);
                      }, 1000);
                    }
                    scrollToId(page?.id)

                  }
                }}>
                  <Button
                    key={page}
                    onClick={handleCloseDrawer}
                    sx={{ my: 2, color: 'white', display: 'block', color: Colors.textColor1, textTransform: "capitalize" }}
                  >
                    {page.name}
                  </Button>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: "16px"
              }}
            >
              {!User ? <PrimaryButton
                title={"Login"}

                onClick={() => handleGoogleLogin()}
              />

                : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                  {cartVisible ? <Box component={'div'} onClick={() => navigate('/order')}> <SmallBadge badgeContent={''} color="primary"> <ShoppingCartIcon sx={{ cursor: "pointer", color: 'white' }} /></SmallBadge></Box> : <ShoppingCartIcon sx={{ cursor: "pointer", color: 'white' }} />} &nbsp;&nbsp;
                  <IconButton
                    edge="end"
                    color="inherit"
                    aria-label="account of current user"
                    aria-controls="profile-menu"
                    // aria-haspopup="true"
                    onClick={(event) => handleClick(event)}
                  >
                    <AccountCircleIcon sx={{ color: 'white' }} />
                  </IconButton>
                  {dropdown && (
                    <Menu
                      id="profile-menu"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleProfile}>Profile</MenuItem>
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                  )}
                </div>}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </HideOnScroll>
  )
}

export default Header