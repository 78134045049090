import { Avatar, Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, ThemeProvider, colors, createTheme, styled } from '@mui/material'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import background from '../../../assests/images/mayaccountbg.png'
import EditIcon from '@mui/icons-material/Edit';
import { Images } from '../../../assests';
import { CssBaseline, Container, Paper, Typography, Stepper, Step, StepLabel, Button, TextField, List, ListItem, ListItemText } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Moveable from 'react-moveable';
import { ContextMenuTrigger } from 'react-contextmenu';
import { PDFExport } from '@progress/kendo-react-pdf';
import domtoimage from 'dom-to-image';


import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,

    IconButton,
} from '@mui/material';
import Input from '@mui/material/Input';
import { Add, Remove } from '@mui/icons-material';
import { SecondaryButton } from '../../../components/Buttons';
import PropTypes from 'prop-types';
import InputPhone from '../../../components/PhoneInput';
import { useForm } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import axios from 'axios';
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import moment from 'moment/moment';
import OrderServices from '../../../services/OrderServices';
import Swal from 'sweetalert2';
import AuthServices from '../../../services/AuthServices';
import { auth, provider, signInWithPopup } from '../../../config/firebase.config'; // Adjust the import path according to your file structure
import { getAuth, GoogleAuthProvider, signOut } from 'firebase/auth';
import html2canvas from 'html2canvas';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { AuthContext } from '../../../Context/AuthContext';
import Colors from '../../../assests/style';
import { CartContext } from '../../../Context/CartContext';

const steps = ['Shipping address', 'Checkout'];
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="0000-0000-0000-0000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

TextMaskCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};





export default function Checkout() {

    const { register: register1, handleSubmit: handleSubmit1, formState: { errors: errors1 }, control: control1, watch, reset, setValue: setValue1,getValues:getValues1 } = useForm();
    const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, control: control2 } = useForm();
    const auth = getAuth();
    const { cartVisible, toggleCartVisibility } = useContext(CartContext);
    let User = localStorage.getItem('user')
    User = JSON.parse(User)
    let Order = localStorage.getItem('orderData')
    Order = JSON.parse(Order)
    console.log(Order, 'orderDatas');
    const [selectedTab, setSelectedTab] = useState(0)
    const Tabs = [{ id: 0, name: 'My Account', icon: <PersonIcon /> }, { id: 1, name: 'My Library', icon: <SaveAltIcon /> }, { id: 2, name: 'Addresses', icon: <LocationOnOutlinedIcon /> }, { id: 3, name: 'Account Settings', icon: <SettingsIcon /> }, { id: 4, name: 'Log out', icon: <LogoutIcon /> }]

    const { state } = useLocation();
    console.log(state);
    const navigate = useNavigate();
    const contentRef = useRef(null);
    const parentRef = useRef(null);
    const { user, setUser } = useContext(AuthContext);
    const [screenshotUrl, setScreenshotUrl] = useState()
    const pdfExportComponentFront = useRef(null);
    const pdfExportComponentBack = useRef(null);
    const elementFront = useRef(null)
    const elementBack = useRef(null)
    const [frontSSUrl, setFrontSSUrl] = useState()
    const [backSSUrl, setbackSSUrl] = useState()
    console.log(state);
    const [isLessThan1200, setIsLessThan1200] = useState(window.innerWidth < 1000);

    const [activeStep, setActiveStep] = useState(0);
    const [rotateDirection, setRotateDirection] = useState(false)
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [checkOut, setCheckOut] = useState(false)
    const [frontUrl, setFrontUrl] = useState()
    const [backUrl, setBackUrl] = useState()
    const [tshirtPrice, setTshirtPrice] = useState(0)
    const [maxOrder, setMaxOrder] = useState(0)
    const [deliveryCharges, setDeliveryCharges] = useState(0)
    // *For Stepper Forms Data
    const [step1FormData, setStep1FormData] = useState();
    const [step2FormData, setStep2FormData] = useState();
    const [orderSummary, setOrderSummary] = useState([])
    const [userData, setUserData] = useState()
    const [emirates, setEmirates] = useState([])
    const [subTotal, setSubTotal] = useState(0)
    const [Vat, setVat] = useState(0)
    const [amountWithVat, setAmountWithVat] = useState(0)
    const [userProfileData, setUserProfileData] = useState(null)


    const ExpiryDate = React.forwardRef(function ExpiryDate(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="00/00"
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    });
    const CVV = React.forwardRef(function CVV(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="0000"
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    });



    const [selectedOption, setSelectedOption] = useState('');


    const divRef = useRef(null);
    const divRefBack = useRef(null);


    const capture = async () => {
        const div = divRef.current;

        if (divRef.current) {
            const a4Width = 210; // mm
            const a4Height = 297; // mm
            const scale = 10; // adjust this value to change the image resolution

            // Set the div's styles to match the A4 size
            div.style.width = `${a4Width}mm`;
            div.style.height = `${a4Height}mm`;
            div.style.padding = '0';
            div.style.border = 'none';
            div.style.overflow = 'hidden';

            // Capture the screenshot
            domtoimage.toPng(div, {
                bgcolor: 'transparent',
                width: a4Width * scale,
                height: a4Height * scale,
                style: {
                    transform: `scale(${scale})`,
                    transformOrigin: 'top left',
                },
            }).then(dataUrl => {
                const link = document.createElement('a');
                link.download = 'yImage.png';
                link.href = dataUrl;
                sendBlobFront(dataUrl.split(";base64,")[1], moment().unix() + '_Front.png')


            });
        }
    };
    const captureBack = async () => {
        const div = divRefBack.current;

        if (divRefBack.current) {

            const a4Width = 210; // mm
            const a4Height = 297; // mm
            const scale = 10; // adjust this value to change the image resolution

            // Set the div's styles to match the A4 size
            div.style.width = `${a4Width}mm`;
            div.style.height = `${a4Height}mm`;
            div.style.padding = '0';
            div.style.border = 'none';
            div.style.overflow = 'hidden';

            // Capture the screenshot
            domtoimage.toPng(div, {
                bgcolor: 'transparent',
                width: a4Width * scale,
                height: a4Height * scale,
                style: {
                    transform: `scale(${scale})`,
                    transformOrigin: 'top left',
                },
            }).then(dataUrl => {
                const link = document.createElement('a');
                link.download = 'back.png';
                link.href = dataUrl;
                sendBlobBack(dataUrl.split(";base64,")[1], moment().unix() + '_Back.png')
            });
        }
    };
    const waitForImagesToLoad = () => {
        return new Promise((resolve) => {
            const imgElements = document.querySelectorAll('img'); // Update this selector as per your needs

            let loadedCount = 0;

            imgElements.forEach((img) => {
                if (img.complete) {
                    loadedCount++;
                } else {
                    img.onload = () => {
                        loadedCount++;
                        if (loadedCount === imgElements.length) {
                            resolve();
                        }
                    };
                }
            });

            if (loadedCount === imgElements.length) {
                resolve();
            }
        });
    };

    const captureScreenshot = async () => {
        await waitForImagesToLoad();
        await capture();
        await captureBack();
    };


    const handleGoogleLogin = async () => {

        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            console.log("User Info: ", user);


            authLogin(user?.reloadUserInfo, result)
            // Handle user info here (e.g., save to state, context, or redirect)
        } catch (error) {
            console.error("Error during Google login: ", error);
        }
    };

    const authLogin = async (sendData, result) => {
        console.log(sendData, 'data');
        try {
            let obj = {
                phone: '',
                google_id: sendData.localId,
                email: sendData.email,
                picture: sendData.photoUrl,
                name: sendData.displayName
            }
            const data = await AuthServices.authLogin(obj)
            console.log(data);
            if (data.responseCode == 200) {

                localStorage.setItem('authToken', JSON.stringify(data?.data?.token))
                localStorage.setItem('user', JSON.stringify(sendData))
                let userInfo = localStorage.getItem('user')
                setUserData(JSON.parse(userInfo))
                setUser(result.user)
            }


        } catch (error) {
            alert('New Registration is Closed')
        } finally {

        }
    }
    const handleChange = (event) => {

        setSelectedOption(event.target.value);
        console.log(event.target.value);

        let qty = Order?.quantities?.reduce((acc, item) => acc + item.qty, 0)
        console.log(Order, 'totalQuantity');
        console.log(maxOrder, 'maxOrder');
        const deliveryChargesresult = calculateDeliveryCharges(qty, event.target.value);
        setDeliveryCharges(deliveryChargesresult)
        console.log(`The delivery charges for a quantity of ${Order?.totalQuantity} is ${deliveryChargesresult}`);
    };

    const names = [
        'Abu Dhabi',
        'Dubai',
        'Sharjah',
        'Ajman',
        'Umm Al Quwain',
        'Ras Al Khaimah',
        'Fujairah',

    ];
    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };
    const sizes = ['Small', 'Medium', 'Large', 'Extra Large'];
    const [quantities, setQuantities] = useState({
        S: 0,
        M: 0,
        L: 0,
        XL: 0,
    });

    const placeOrder = async () => {
        console.log(step1FormData, 'data');
        console.log(quantities, 'data');
        const designs = {
            S: 'dotted',
            M: 'striped',
            L: 'plain',
            XL: 'checked'
        };

        let products = Order?.quantities
        // Adding unit_price to each object in the array
        const updatedProducts = products?.map(product => ({
            ...product,
            unit_price: tshirtPrice,
            design_front: frontUrl,
            design_back: backUrl
        }));
        console.log(updatedProducts, 'adasd');
        try {
            let obj = {
                ...step1FormData,
                customer: {
                    first_name: getValues1('firstName'),
                    last_name: getValues1('lastName'),
                    phone: getValues1('mobile'),
                }
                ,
                address: {
                    address_1: getValues1('address1'),
                    address_2: getValues1('address2'),
                    zip_code: getValues1('zip'),
                    emirate: getValues1('city')
                },
                details: updatedProducts,
                color: Order?.color,
                payment_amount: amountWithVat,
                screenshot_front: Order?.frontScreenshot,
                screenshot_back: Order?.backScreenshot,
                sub_total: Order?.totalAmount,
                delivery_charges: deliveryCharges,
                vat: Vat


            }
            console.log(obj, 'obj');
            const { data } = await OrderServices.placeOrder(obj)
            console.log(data);
            if (data) {
                // localStorage.setItem('designArray',[])
                // toggleCartVisibility()
                window.location.href=data?.url
              
            }

        } catch (error) {
            console.log(error);
        } finally {
            localStorage.removeItem('orderData')
        }
    }

    const submitForm1 = async (formData) => {
        console.log(formData);
        let obj = {
            customer: {
                first_name: formData.firstName,
                last_name: formData.lastName,
                phone: formData.mobile,
            }
            ,
            address: {
                address_1: formData.address1,
                address_2: formData.address2,
                zip_code: formData.zip,
                emirate: selectedOption?.emirate,
                emirate_id: selectedOption?._id
            },
        }
        setStep1FormData(obj)

      

    }
    const submitForm2 = async (formData) => {
        console.log(formData);
        let obj = {
            customer: {
                first_name: formData.firstName,
                last_name: formData.lastName,
                phone: formData.mobile,
            }
            ,
            address: {
                address_1: formData.address1,
                address_2: formData.address2,
                zip_code: formData.zip,
                emirate: formData.city
            },
        }
        setStep2FormData(obj)


    }
    const getPricing = async () => {
        try {
            let params = {

            }
            const { data } = await OrderServices.getPricing(params)
            console.log(data);
            setEmirates(data?.pricing?.delivery_charges)
            setTshirtPrice(data?.pricing?.standard_price)
            setMaxOrder(data?.pricing?.max_per_order)



        } catch (error) {
            console.log(error);
        } finally {

        }
    }
    const handleQuantityChange = (size, event) => {
        const value = event.target.value;
        console.log(value, 'asdasd');
        if (!isNaN(value) && value >= 0) {
            // Create a new variable to hold the updated quantities
            const updatedQuantities = {
                ...quantities,
                [size]: parseInt(value, 10),
            };

            // Set the state with the updated quantities
            setQuantities(updatedQuantities);

            // Prepare the order summary based on the updated quantities
            const details = Object.entries(updatedQuantities).map(([size, qty]) => ({
                size,
                qty,
                design: 'abc',
            }));

            console.log(details, 'detailsdetailsdetails');
            // Example usage:

            setOrderSummary(details);
        }
    };

    useEffect(() => {
        let Order2 = localStorage.getItem('orderData')
        Order2 = JSON.parse(Order2)
        console.log(Order2, 'orderDatas');
        if (!Order2) {
            navigate('/')
        }
    }, [])


    const theme = createTheme({
        typography: {
            fontFamily: 'Poppins, sans-serif'// You can change this to your preferred font
        },
        components: {
            MuiStepLabel: {
                styleOverrides: {
                    label: {
                        color: 'rgba(255, 255, 255, 0.7)',
                        fontFamily: 'Poppins, sans-serif',
                        '&.Mui-active': {
                            color: '#ffffff',
                        },
                        '&.Mui-completed': {
                            color: '#ffffff',
                        },
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& .MuiInputBase-root': {
                            color: 'white', // Text color,
                            fontFamily: 'Poppins, sans-serif'
                        },
                        '& .MuiInputLabel-root': {
                            color: 'white', // Label color
                        },
                        '& .MuiInput-underline:before': {
                            borderBottomColor: 'white', // Underline color when unfocused
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: 'white', // Underline color when focused
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'white', // Border color when unfocused
                            },
                            '&:hover fieldset': {
                                borderColor: 'white', // Border color on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'white', // Border color when focused
                            },
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        color: 'white', // Text color for input
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: 'white', // Border color for input
                    },
                    root: {
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                        },
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: 'white', // Text color for label
                        borderColor: 'white',
                        '&.Mui-focused': {
                            color: 'white',
                        },
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        color: 'black', // Text color for menu items
                        backgroundColor: 'white', // Background color for menu items
                        '&.Mui-selected': {
                            backgroundColor: 'rgba(255, 255, 255, 0.2)', // Background color for selected item
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)', // Background color for hover
                        },
                    },
                },
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        color: 'white', // Color for dropdown arrow
                    },
                },
            },
        },
        palette: {
            primary: {
                main: '#1976d2',
            },
            background: {
                default: '#333333',
            },
        },

    });

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <>
                        <Typography sx={{ fontFamily: 'Poppins, sans-serif', color: Colors.secondary, mb: '20px' }} variant="h6" gutterBottom>
                            Shipping address
                        </Typography>
                        <Box component="form" className="formData" onSubmit={handleSubmit1(placeOrder)}>
                            <Grid container spacing={3}>
                                {/* First Name Field */}
                                <Grid item xs={12} sm={6}>
                                    <InputLabel sx={{ color: 'white' }}>First Name</InputLabel>
                                    <TextField
                                        id="firstName"
                                        name="firstName"

                                        variant="standard"
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                color: 'white',
                                            },
                                            '& .MuiInput-underline:before': {
                                                borderBottomColor: 'white',
                                            },
                                            '&:hover .MuiInput-underline:before': {
                                                borderBottomColor: 'white',
                                            },
                                            '&.Mui-focused .MuiInput-underline:before': {
                                                borderBottomColor: 'white',
                                            },
                                        }}
                                        {...register1("firstName", {
                                            required: "Firstname is Required",
                                        })}
                                        helperText={errors1?.firstName?.message}
                                        error={!!errors1?.firstName}
                                        fullWidth
                                        autoComplete="given-name"
                                    />
                                </Grid>

                                {/* Last Name Field */}
                                <Grid item xs={12} sm={6}>
                                    <InputLabel sx={{ color: 'white' }}>Last Name</InputLabel>
                                    <TextField
                                        id="lastName"
                                        name="lastName"

                                        variant="standard"
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                color: 'white',
                                            },
                                            '& .MuiInput-underline:before': {
                                                borderBottomColor: 'white',
                                            },
                                            '&:hover .MuiInput-underline:before': {
                                                borderBottomColor: 'white',
                                            },
                                            '&.Mui-focused .MuiInput-underline:before': {
                                                borderBottomColor: 'white',
                                            },
                                        }}
                                        {...register1("lastName", {
                                            required: "Lastname is Required",
                                        })}
                                        helperText={errors1?.lastName?.message}
                                        error={!!errors1?.lastName}
                                        fullWidth
                                        autoComplete="family-name"
                                    />
                                </Grid>

                                {/* Mobile Number Field */}
                                <Grid item xs={12} sm={6}>
                                    <InputLabel sx={{ color: 'white' }}>Mobile Number</InputLabel>
                                    <TextField
                                        id="mobile"
                                        name="mobile"
                                        type='number'
                                        variant="standard"
                                        placeholder='971 XXX XXX XXX'
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                color: 'white',
                                            },
                                            '& .MuiInput-underline:before': {
                                                borderBottomColor: 'white',
                                            },
                                            '&:hover .MuiInput-underline:before': {
                                                borderBottomColor: 'white',
                                            },
                                            '&.Mui-focused .MuiInput-underline:before': {
                                                borderBottomColor: 'white',
                                            },
                                        }}
                                        {...register1("mobile", {
                                            required: "Please enter your mobile number.",
                                            pattern: {
                                                value: /^971[0-9]{9}$/,
                                                message: "Please enter a valid UAE phone number (starting with 971 and 9 digits)."
                                            }
                                        })}
                                        helperText={errors1?.mobile?.message}
                                        error={!!errors1?.mobile}
                                        fullWidth
                                        autoComplete="tel"
                                    />
                                </Grid>

                                {/* Address Line 1 Field */}
                                <Grid item xs={12}>
                                    <InputLabel sx={{ color: 'white' }}>Address Line 1</InputLabel>
                                    <TextField
                                        id="address1"
                                        name="address1"

                                        variant="standard"
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                color: 'white',
                                            },
                                            '& .MuiInput-underline:before': {
                                                borderBottomColor: 'white',
                                            },
                                            '&:hover .MuiInput-underline:before': {
                                                borderBottomColor: 'white',
                                            },
                                            '&.Mui-focused .MuiInput-underline:before': {
                                                borderBottomColor: 'white',
                                            },
                                        }}
                                        {...register1("address1", {
                                            required: "Address line 1 is Required",
                                        })}
                                        helperText={errors1?.address1?.message}
                                        error={!!errors1?.address1}
                                        fullWidth
                                        autoComplete="shipping address-line1"
                                    />
                                </Grid>

                                {/* Address Line 2 Field */}
                                <Grid item xs={12}>
                                    <InputLabel sx={{ color: 'white' }}>Address Line 2</InputLabel>
                                    <TextField
                                        id="address2"
                                        name="address2"

                                        variant="standard"
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                color: 'white',
                                            },
                                            '& .MuiInput-underline:before': {
                                                borderBottomColor: 'white',
                                            },
                                            '&:hover .MuiInput-underline:before': {
                                                borderBottomColor: 'white',
                                            },
                                            '&.Mui-focused .MuiInput-underline:before': {
                                                borderBottomColor: 'white',
                                            },
                                        }}
                                        {...register1("address2")}
                                        fullWidth
                                        autoComplete="shipping address-line2"
                                    />
                                </Grid>

                                {/* Zip/Postal Code Field */}
                                <Grid item xs={12} sm={6}>
                                    <InputLabel sx={{ color: 'white' }}>Zip/Postal Code</InputLabel>
                                    <TextField
                                        id="zip"
                                        name="zip"

                                        type="number"
                                        variant="standard"
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                color: 'white',
                                            },
                                            '& .MuiInput-underline:before': {
                                                borderBottomColor: 'white',
                                            },
                                            '&:hover .MuiInput-underline:before': {
                                                borderBottomColor: 'white',
                                            },
                                            '&.Mui-focused .MuiInput-underline:before': {
                                                borderBottomColor: 'white',
                                            },
                                        }}
                                        {...register1("zip", {
                                            required: "Zip is Required",
                                        })}
                                        helperText={errors1?.zip?.message}
                                        error={!!errors1?.zip}
                                        fullWidth
                                        autoComplete="shipping postal-code"
                                    />
                                </Grid>

                                {/* Emirates Field */}
                                <Grid item xs={12} sm={6}>
                                    <InputLabel sx={{ color: 'white' }}>Emirates</InputLabel>
                                    <FormControl fullWidth error={!!errors1?.emirates}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="emirates"
                                            {...register1("emirates", {
                                                required: selectedOption ? false : "Emirates is Required",
                                            })}
                                            sx={{
                                                color: 'white',
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'white',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'white',
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'white',
                                                },
                                                '.MuiSvgIcon-root': {
                                                    color: 'white',
                                                },
                                            }}
                                            value={selectedOption}
                                            onChange={handleChange}
                                        >
                                            {emirates?.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option?.emirate}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors1?.emirates && (
                                            <FormHelperText sx={{ color: 'red' }}>
                                                {errors1.emirates.message}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                               
                                <Button
                                    variant="contained"
                                    type='submit'
                                    sx={{ mt: 3, ml: 1 }}
                                    disabled={(frontUrl && backUrl) ? false : true}
                                  
                                >
                                    {'Place Order'}
                                </Button>
                            </Box>
                        </Box>




                    </>
                );
            case 1:
                return (
                    <>
                        <Typography variant="h6" sx={{ color: Colors.secondary }} gutterBottom>
                            Checkout
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit2(submitForm2)}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <InputLabel sx={{ color: "white" }} > Name on card</InputLabel>
                                    <Input


                                        fullWidth

                                        name="cardName"
                                        sx={{ color: 'white', border: '1px solid white', borderRadius: '5px', p: '10px' }}


                                        {...register2("cardName", {
                                            required: "Card number is required",

                                        })}
                                        helperText={errors2?.cardName?.message}
                                        error={errors2?.cardName && true}
                                    />


                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel sx={{ color: "white" }} >Card Number</InputLabel>
                                    <Input
                                        required

                                        fullWidth
                                        label="Card number"
                                        name="cardNumber"
                                        sx={{ color: 'white', border: '1px solid white', borderRadius: '5px', p: '10px' }}

                                        inputComponent={TextMaskCustom}
                                        {...register2("cardNumber", {
                                            required: "Card number is required",

                                        })}
                                        helperText={errors2?.cardNumber?.message}
                                        error={errors2?.cardNumber && true}
                                    />

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel sx={{ color: "white" }} > Expiry date</InputLabel>
                                    <Input


                                        fullWidth
                                        label="Card number"
                                        name="cardNumber"

                                        sx={{ color: 'white', border: '1px solid white', borderRadius: '5px', p: '10px' }}
                                        inputComponent={ExpiryDate}
                                        {...register2("expDate", {
                                            required: "expDate  is required",

                                        })}
                                        helperText={errors2?.expDate?.message}
                                        error={errors2?.expDate && true}
                                    />

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel sx={{ color: "white" }} > CVV</InputLabel>
                                    <Input


                                        fullWidth
                                        label="Card number"
                                        name="cvv"
                                        sx={{ color: 'white', border: '1px solid white', borderRadius: '5px', p: '10px' }}
                                        inputComponent={CVV}
                                        {...register2("cvv", {
                                            required: "cvv  is required",

                                        })}
                                        helperText="Last three digits on signature strip"

                                        error={errors2?.cvv && true}
                                    />

                                </Grid>
                            </Grid>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                {activeStep !== 0 && (
                                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                                        Back
                                    </Button>
                                )}
                                <Button
                                    variant="contained"
                                    type='submit'
                                    sx={{ mt: 3, ml: 1 }}
                                    disabled={(frontUrl && backUrl) ? false : true}
                                    onClick={() => {

                                        placeOrder()
                                    }}
                                >
                                    {'Place Order'}
                                </Button>
                            </Box>
                        </Box>
                    </>
                );
            case 2:
                const products = [
                    { name: 'Product 1', desc: 'A nice thing', price: '$9.99' },
                    { name: 'Product 2', desc: 'Another thing', price: '$3.45' },
                    { name: 'Product 3', desc: 'Something else', price: '$6.51' },
                    { name: 'Product 4', desc: 'Best thing of all', price: '$14.11' },
                ];
                const addresses = ['1 Main St', 'Springfield', 'IL', '62704', 'USA'];
                const payments = [
                    { name: 'Card type', detail: 'Visa' },
                    { name: 'Card holder', detail: 'Mr John Smith' },
                    { name: 'Card number', detail: 'xxxx-xxxx-xxxx-1234' },
                    { name: 'Expiry date', detail: '04/2024' },
                ];

                return (
                    <>
                        <Typography variant="h6" gutterBottom>
                            Order summarys
                        </Typography>
                        <List disablePadding>
                            {console.log(orderSummary, 'orderSummaryorderSummary')}
                            {orderSummary?.length > 0 && orderSummary?.map((product) => (
                                <ListItem key={product.name} sx={{ py: 1, px: 0 }}>
                                    <ListItemText primary={product.size} />
                                    <Typography variant="body2">AED &nbsp;&nbsp;{product.qty * tshirtPrice}</Typography>
                                </ListItem>
                            ))}
                            <ListItem sx={{ py: 1, px: 0 }}>
                                <ListItemText primary="Sub Total" />
                                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                    AED {totalQuantity * tshirtPrice}
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ py: 1, px: 0 }}>

                                <ListItemText primary="Delivery Charges" />
                                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                    AED {deliveryCharges}
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ py: 1, px: 0 }}>

                                <ListItemText primary="Total Amount" />
                                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                    AED {deliveryCharges + (totalQuantity * tshirtPrice)}
                                </Typography>
                            </ListItem>

                        </List>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                                    Shipping
                                </Typography>
                                <Typography gutterBottom>{step1FormData.address.address_1}</Typography>
                                <Typography gutterBottom>{step1FormData.address.address_2}</Typography>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={6}>
                                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                                    Payment details
                                </Typography>
                                <Grid container>
                                    {payments.map((payment) => (
                                        <React.Fragment key={payment.name}>
                                            <Grid item xs={6}>
                                                <Typography gutterBottom>{payment.name}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography gutterBottom>{payment.detail}</Typography>
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <SecondaryButton onClick={() => {

                                placeOrder()
                            }} className={'checkout-btn'} m={'20px'} p={"15px 30px"} fontWeight={'bold'} color={'white'} title={'Confirm Order'} />
                        </Box>
                    </>
                );
            default:
                throw new Error('Unknown step');
        }
    };

    const userProfile = async (sendData) => {
        console.log(sendData, 'data');
        try {
            let params = {

            }
            const { data } = await OrderServices.userProfile(params)

            setUserProfileData(data?.details)

        } catch (error) {
            console.log(error);
        } finally {

        }
    }


    const sendBlobBack = async (base64, name) => {
        let obj = {
            document: base64,
            filename: name

        }

        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + '/api/system/uploadDesign', obj);

            console.log('Upload successful:', response.data.data.path);
            setBackUrl(response.data.data.path)
        } catch (error) {
            console.error('Error uploading the file:', error);
        }
    };
    const sendBlobFront = async (base64, name) => {
        let obj = {
            document: base64,
            filename: name

        }

        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + '/api/system/uploadDesign', obj);

            console.log('Upload successful:', response.data.data.path);
            setFrontUrl(response.data.data.path)

        } catch (error) {
            console.error('Error uploading the file:', error);
        }
    };

    function calculateDeliveryCharges(quantity, selected) {


        // Calculate the number of full maxOrder (5 items) and the remaining items
        const fullOrders = Math.floor(quantity / maxOrder);
        const remainingItems = quantity % maxOrder;
        console.log(fullOrders);
        console.log(remainingItems);
        console.log(selected, 'selected');
        let value = selected ? parseInt(selected?.charge) : parseInt(selectedOption?.charge)
        // Calculate the total delivery charges
        let deliveryChargesresult = fullOrders * value;
        console.log(deliveryChargesresult, 'deliveryChargesresult');
        // If there are any remaining items, add another delivery charge
        if (remainingItems > 0) {
            deliveryChargesresult += value;
        }
        console.log(deliveryChargesresult, 'deliveryChargesresult2');
        const totalAmount = Order?.totalAmount + (isNaN(deliveryChargesresult) ? 0 : deliveryChargesresult);
        const vatAmount = totalAmount * 0.05;
        setVat(vatAmount)
        const totalWithVAT = totalAmount + vatAmount;
        setAmountWithVat(totalWithVAT)
        return deliveryChargesresult;
    }



    useEffect(() => {
        const handleResize = () => {
            setIsLessThan1200(window.innerWidth < 1000);
            console.log(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        const total = Object.values(quantities).reduce((acc, quantity) => acc + (quantity || 0), 0);
        setTotalQuantity(total);

        const deliveryChargesresult = calculateDeliveryCharges(Order?.totalQuantity, null);
        setDeliveryCharges(deliveryChargesresult)

    }, [quantities]);
    useEffect(() => {
        getPricing()
        if (state) {
            sendBlobFront(state?.frontSSUrl?.document, state?.frontSSUrl?.name)
            sendBlobBack(state?.backSSUrl?.document, state?.backSSUrl?.name)
        }
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0);


        // Assuming you have a fixed unit price, for example:
        const unitPrice = 15.00;
        let products = Order?.quantities
        // Adding unit_price to each object in the array
        const updatedProducts = products?.map(product => ({
            ...product,
            unit_price: unitPrice
        }));
        console.log(Order?.quantities);
        console.log(updatedProducts, 'updatedProducts');
        userProfile()
    }, []);
    useEffect(() => {
        if (userProfileData) {
            console.log(userProfileData);
            const words = userProfileData?.name?.split(' ');

            // Assume the first word is the first name and the rest is the last name
            const lastName = words[words.length - 1];
            words.pop();
            const firstName = words.join(" ");

            setValue1('firstName', firstName)
            setValue1('lastName', lastName)
            setValue1('mobile', userProfileData?.phone)
            setValue1('address1', userProfileData?.address_1)
            setValue1('address2', userProfileData?.address_2)
            setValue1('city', userProfileData?.emirate)
            let filterOption = emirates?.find((item => item?._id == userProfileData?.emirate_id))
            if (filterOption) {

                setSelectedOption(filterOption)
            }
            else {
                setSelectedOption(emirates[0])
            }
            let qty = Order?.quantities?.reduce((acc, item) => acc + item.qty, 0)
            console.log(Order, 'totalQuantity');
            console.log(maxOrder, 'maxOrder');
            const deliveryChargesresult = calculateDeliveryCharges(qty, filterOption);
            setDeliveryCharges(deliveryChargesresult)
            setValue1('zip', userProfileData?.zip_code)
            setValue1('emirates', userProfileData?.emirate)
        }
    }, [userProfileData])


    return (
        <Fragment  >


            <Box
                sx={{
                    backgroundImage: `url("${background}")`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: '300px', // Adjust height as needed
                    display: 'flex',
                    alignItems: 'center', justifyContent: 'center'
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <p className='banner-text' style={{ fontSize: '35px', textAlign: 'center', fontFamily: "Noto Sans, sans-serif", color: 'white !important' }}> Checkout </p>
                    <p style={{ fontSize: '25px', textAlign: 'center', color: 'white', fontFamily: "Noto Sans, sans-serif", }}>Order Details || <span style={{ color: '#0bcfd2' }}>Checkout</span></p>
                </Box>
            </Box>

            <Grid container sx={{ paddingTop: '2%', paddingBottom: '5%' }} justifyContent={'center'} >


                <Grid item md={12} lg={12}>
                    <>

                        <ThemeProvider theme={theme}>


                            <Box sx={{ width: '60%', margin: '0 auto', mb: '40px' }}>
                                <Stepper activeStep={1} alternativeLabel>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel sx={{ color: 'black' }}>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                            <Grid gap={2} container sx={{ width: '80%', justifyContent: 'center', margin: '0 auto' }}>

                                <Grid item sm={12} md={7.5}>
                                    {<Container component="main" sx={{ width: '100%', backgroundColor: '#0c1427', borderRadius: '12px' }}>
                                        <Paper sx={{ p: 2, backgroundColor: 'transparent', color: 'white', boxShadow: 'none' }}>


                                            {activeStep === steps.length ? (
                                                <Typography variant="h5" gutterBottom>
                                                    Thank you for your order.
                                                </Typography>
                                            ) : (
                                                <>
                                                    <Box sx={{ mt: 3 }}>
                                                        {getStepContent(activeStep)}

                                                    </Box>
                                                </>
                                            )}
                                        </Paper>
                                    </Container>}
                                </Grid>
                                <Grid item sm={12} md={4} sx={{ backgroundColor: '#0c1427', borderRadius: '12px' }}>
                                    <Box sx={{ backgroundColor: '#0c1427', p: 4 }}>
                                        <Typography sx={{ fontFamily: 'Poppins, sans-serif', color: Colors.secondary }} variant="h6" gutterBottom>
                                            Order Summary
                                        </Typography>
                                        <div style={{ color: '#fff' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', fontFamily: 'Poppins, sans-serif' }}>
                                                <span>Tshirt Fit :</span>
                                                <span>{state?.tshirtType}</span>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px', fontFamily: 'Poppins, sans-serif' }}>
                                                <span>Items ({Order?.quantities?.reduce((acc, item) => acc + item.qty, 0)})</span>
                                                <span>AED {Order?.quantities?.reduce((acc, item) => acc + item.qty, 0) * tshirtPrice}</span>
                                            </div>
                                           
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', fontFamily: 'Poppins, sans-serif' }}>
                                                <span>Shipping :</span>
                                                <span>AED {isNaN(deliveryCharges) ? 0 : deliveryCharges}</span>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', fontFamily: 'Poppins, sans-serif' }}>
                                                <span>Sub Total :</span>
                                                <span>AED {(Order?.totalAmount) + (isNaN(deliveryCharges) ? 0 : deliveryCharges)}</span>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', fontFamily: 'Poppins, sans-serif' }}>
                                                <span>VAT 5% :</span>
                                                <span>
                                                    AED {parseFloat(Vat).toFixed(2)}
                                                </span>
                                            </div>
                                            <hr style={{ borderColor: '#fff', margin: '20px 0' }} />
                                            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '20px', fontWeight: 'bold', fontFamily: 'Poppins, sans-serif', color: Colors.secondary }}>
                                                <span>Order Total:</span>
                                                <span>AED {parseFloat(amountWithVat).toFixed(2)}</span>
                                            </div>
                                            <hr style={{ borderColor: '#fff', margin: '20px 0' }} />
                                            <p style={{ textAlign: 'center', fontSize: '14px', fontFamily: 'Poppins, sans-serif' }}>
                                                By placing your order, you agree to our company{' '}
                                                <a href="#" style={{ color: '#00a8ff', fontFamily: 'Poppins, sans-serif' }}>
                                                    Privacy policy
                                                </a>{' '}
                                                and{' '}
                                                <a href="#" style={{ color: '#00a8ff', fontFamily: 'Poppins, sans-serif' }}>
                                                    Conditions of use
                                                </a>
                                                .
                                            </p>
                                        </div>
                                    </Box>
                                </Grid>
                            </Grid>
                        </ThemeProvider>
                    </>
                </Grid>
            </Grid>


        </Fragment>
    )
}
