import { get, post, patch } from '../../services/';
import routes from './routes';

const AuthServices = {
  
 
    authLogin: async (obj) => {
    const data = await post(routes.authLogin, obj);
    return data;
  },
  generateSession: async (obj) => {
    const data = await post(routes.generateSession, obj);
    return data;
  },
  
}

export default AuthServices