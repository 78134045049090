import React from 'react'
import TshirtDesignCanvas from '../../../components/DesignCanvas'
import { Box, Grid } from '@mui/material'
import Logo from '../../../assests/images/Logo.png'
import { useLocation } from 'react-router-dom'

const Product = () => {
  
  const { state } = useLocation();
  console.log(state);
  return (
    <div>
      
      <TshirtDesignCanvas imageUrl={state} />
    </div>
  )
}

export default Product
