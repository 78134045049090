import { Avatar, Box, Divider, Grid, Paper, Step, StepConnector, StepLabel, Stepper, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Colors from '../../../assests/style';
import moment from 'moment';
import OrderServices from '../../../services/OrderServices';
import { deepPurple } from '@mui/material/colors';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const steps = ['Pending', 'Processing', 'Confirmed', 'Delivered'];

const ColorConnector = styled(StepConnector)(({ theme }) => ({
    [`& .${StepConnector.line}`]: {
        borderColor: theme.palette.primary.main,
    },
}));

const ColorStepLabel = styled(StepLabel)(({ theme, active, completed }) => ({
    '& .MuiStepLabel-label': {
        color: active || completed ? ` ${Colors.secondary} !important  ` : '#a1acb1',
    },
    '& .MuiStepIcon-root': {
        color: active || completed ? theme.palette.primary.main : 'white',
    },
}));
const OrderDetail = () => {
    const { state } = useLocation()
    console.log(state);
    const [tshirtPrice, setTshirtPrice] = useState(0)
    console.log(state);

    const getPricing = async () => {
        try {
            let params = {

            }
            const { data } = await OrderServices.getPricing(params)
            console.log(data);
            setTshirtPrice(data?.pricing?.standard_price)


        } catch (error) {
            console.log(error);
        } finally {

        }
    }
    const [status, setStatus] = useState(1)
    useEffect(() => {
        if (state) {
            const index = steps.findIndex(step => step.toLowerCase() === state?.status?.toLowerCase());
            console.log(index);
            setStatus(index)
        }
        getPricing()
    }, [])

    return (
        <div style={{ marginTop: '20px', fontFamily: "Noto Sans, sans-serif", color: 'white', marginBottom: '10px' }}>
            <Box sx={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', width: '90%', margin: '0 auto', padding: '20px', borderRadius: '12px' }} >
                <Typography sx={{ fontFamily: "Noto Sans, sans-serif" }}>
                    Order details #{state?.order_no} &nbsp; <span style={{ width: '50px', height: '30px', backgroundColor: 'rgba(0, 0, 0, 0.2)', padding: '10px', color: Colors.secondary }}>{state?.status?.toUpperCase()}</span>
                </Typography>
                <Typography sx={{ fontFamily: "Noto Sans, sans-serif", fontSize: '13px', color: '#a1acb1' }}>
                    Date :  {moment(state?.created_at).format('DD/MM/YYYY')}
                </Typography>
                <Typography sx={{ fontFamily: "Noto Sans, sans-serif" }}>
                    Shipping Address : {state?.address?.address_1 + ", " + state?.address?.emirate}
                </Typography>
                <Stepper sx={{ mt: 5, mb: 5 }} activeStep={status} alternativeLabel connector={<ColorConnector />}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <ColorStepLabel
                                active={status >= index}
                                completed={status > index}
                                sx={{ color: 'black' }}
                            >
                                {label}
                            </ColorStepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Typography sx={{ fontFamily: "Noto Sans, sans-serif", mb: 2, fontSize: '25px' }}>
                    Design Preview
                </Typography>
                <Grid container>
                    
                    <Grid item xs={12} sm={12} md={6} sx={{ textAlign: 'center' }}>
                        <Box component={'img'} sx={{ width:'100%',  textAlign: 'center',scale:'0.8' }} src={ state?.screenshot_front}></Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} sx={{ textAlign: 'center' }}>
                        <Box component={'img'} sx={{width:'100%', textAlign: 'center' ,scale:'0.8'}} src={state?.screenshot_back}></Box>
                    </Grid>
                </Grid>

                <Grid container justifyContent={'center'} >
                    <Grid item xs={12} md={7} lg={7} >
                        <Typography sx={{ fontFamily: "Noto Sans, sans-serif", mb: 2, mt: 15, fontSize: '25px' }}>
                            Items Ordered
                        </Typography>
                    </Grid>
                    {state?.details?.map((item, index) => (
                        <>
                            <Grid item xs={12} md={7} lg={7} key={index}>
                                {item?.qty > 0 && <><Item sx={{ display: 'flex', justifyContent: 'space-between', boxShadow: 'none !important', backgroundColor: 'rgba(0, 0, 0, 0.2)', color: 'white' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar sx={{ bgcolor: deepPurple[500] }}>{item.size}</Avatar>&nbsp;
                                        <Box>
                                            <Typography variant="h6">{item?.size == "S" ? 'Small' : item?.size == 'M' ? 'Medium' : item?.size == 'L' ? 'Large' : 'Extra Large'}</Typography>
                                            {/* <Typography variant="body2" color="text.secondary">
                        {item.specs}asdasd
                      </Typography> */}
                                        </Box>
                                    </Box>
                                    <Box sx={{ mt: 2 }}>
                                        <Typography variant="h6" color="primary">
                                            Price : AED {item?.unit_price}
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }} color="white">
                                            Qty : {item.qty}
                                        </Typography>
                                        <Typography variant="h6" color="primary">
                                            Total :  AED {item.qty * item?.unit_price}
                                        </Typography>

                                    </Box>
                                </Item>
                                    <hr style={{ borderColor: '#a1acb1' }} />
                                </>}
                            </Grid>


                        </>
                    ))}
                    <Grid item xs={12} md={7} lg={7} >
                        <Item sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', boxShadow: 'none !important', backgroundColor: 'rgba(0, 0, 0, 0.2)', color: 'white', textAlign: 'right' }}>


                            <Typography variant="h6" color="white">
                                Sub Total : AED {state?.sub_total}
                            </Typography>
                            <Typography variant="h6" color="white">
                                Delivery Charges : AED {state?.delivery_charges}
                            </Typography>
                            <Typography variant="h6" color="white">
                                VAT 5% : AED {parseFloat(state?.vat).toFixed(2)}
                            </Typography>
                            <Typography variant="h6" color="primary">
                                Total Amount : AED {state?.payment_amount}
                            </Typography>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default OrderDetail
