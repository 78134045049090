import React, { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "jspdf-autotable";
import { Box, Typography } from "@mui/material";

const PDFGenerator = () => {
  const headerRef = useRef();

  const data = [
    { id: 1, name: "John Doe", age: 30 },
    { id: 2, name: "Jane Smith", age: 28 },
    { id: 3, name: "Alice Johnson", age: 32 },
    // Add more rows as needed
  ];

  const headers = ["ID", "Name", "Age"];

  const generatePDF = async () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Capture the header as an image
    const headerCanvas = await html2canvas(headerRef.current);
    const headerImageData = headerCanvas.toDataURL("image/png");

    const tableColumn = headers;
    const tableRows = data.map((row) => [row.id, row.name, row.age]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 80, // Adjust to start table below the header
      margin: { top: 40 },
      styles: { overflow: "linebreak", cellWidth: "auto" },
      pageBreak: "auto",
      showHead: "everyPage", // Repeat the header on every page
      didDrawPage: (data) => {
        // Add the image header to every page
        doc.addImage(headerImageData, "PNG", 15, 10, pageWidth - 30, 30); // Adjust width and height as needed
      },
    });

    doc.save("table_with_custom_jsx_header.pdf");
  };

  return (
    <div>
      {/* Header JSX */}
      <div ref={headerRef} style={{ display: "none" }}> {/* Hide in normal rendering */}
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box>
            <Typography
              sx={{
                color: "#155368",
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              مبد للتجارة (ش,ذ,م,م)
            </Typography>
            <Box sx={{ color: "#155368" }}>
              <Typography sx={{ textAlign: "center", fontWeight: "bold", fontSize: "16px" }}>
                MABDE TRADING L.L.C
              </Typography>
              <Typography sx={{ color: "#155368", fontWeight: "bold" }}>
                TEL: 04-3400000, FAX: 04-3488448 <br />
                P.O.BOX 81, DUBAI, UAE
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Bill To Section */}
        <Box mb={4}>
          <Typography
            sx={{
              border: "2px solid black",
              fontWeight: "bold",
              width: "100px",
              textAlign: "center",
            }}
          >
            Bill To:
          </Typography>
          <Typography sx={{ fontWeight: "bold", mt: 3 }}>
            Firstserv Facilities Management Services Co LLC
          </Typography>
        </Box>

        {/* Address and Invoice Details */}
        <Box
          mb={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography>Dubai UAE.</Typography>
            <Typography sx={{ mt: 2 }}>Vat number: 104225575000003</Typography>
          </Box>
          <Box>
            <Typography sx={{ fontWeight: "bold" }}>
              Tax Invoice Number: MT/FFMS/V24070-6-1
            </Typography>
            <Typography sx={{ mt: 2 }}>Vat Reg. No. 100511270900003</Typography>
            <Typography sx={{ fontWeight: "bold", mt: 2 }}>
              Date: 14<sup>th</sup> May 2024
            </Typography>
          </Box>
        </Box>
      </div>

      {/* Button to generate PDF */}
      <button onClick={generatePDF}>Generate PDF</button>
    </div>
  );
};

export default PDFGenerator;
